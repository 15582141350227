/**
 * Created by yatree on 11/09/16.
 */

// @ts-check
(function btShareScopeServiceClosure() {
  'use strict';

  var gDebug = false;
  var gModuleName = 'btShareScopeService';
  var gPrefix = 'btShareScopeService:';

  angular.module('ecapp').factory('btShareScopeService', btShareScopeService);

  btShareScopeService.$inject = [
    '$rootScope',
    '$q',
    '$interval',
    'Reviewer',
    'Instruments',
    'LogTradeOrder',
    'Settings',
    'btInstrumentsService',
    '$http',
    'btEventEmitterService',
    'btErrorService',
    'btDevService',
    'btSettings',
    'btDateService',
    'btToastrService',
    'btCodes',
    '$state',
    'btSettingsService',
    'btAppFeatures',
  ];

  /**
   *
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.IQService} $q - promise interface
   * @param {angular.IIntervalService} $interval
   * @param {ecapp.IGeneralLoopbackService} lbUser
   * @param {ecapp.IGeneralLoopbackService} lbInstruments
   * @param {ecapp.IGeneralLoopbackService} lbLogTradeOrder
   * @param {ecapp.IGeneralLoopbackService} lbSettings
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {angular.IHttpService} $http
   * @param {ecapp.IEventEmitterService} btEventEmitterService
   * @param {ecapp.IErrorService} $btError
   * @param {ecapp.IDevService} btDevService
   * @param {ecapp.ISettings} btSettings
   * @param {ecapp.IDateService} btDateService
   * @param {ecapp.IToastrService} btToastrService
   * @param {ecapp.ICodes} btCodes
   * @param {angular.ui.IStateService} $state
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IAppFeatures} btAppFeatures
   * @return {ecapp.IShareScopeService}
   */
  function btShareScopeService(
    $rootScope,
    $q,
    $interval,
    lbUser,
    lbInstruments,
    lbLogTradeOrder,
    lbSettings,
    btInstrumentsService,
    $http,
    btEventEmitterService,
    $btError,
    btDevService,
    btSettings,
    btDateService,
    btToastrService,
    btCodes,
    $state,
    btSettingsService,
    btAppFeatures
  ) {
    /**
     * @ngdoc service
     * @name btShareScopeService
     * @memberOf ecapp
     * @description
     *  This factory helps to share between scopes.
     */

    console.log('Running btShareScopeService');

    var gInitializationInProcess = false;
    var gInitializationPromises = [];
    var gIsInitialized = false;
    var gUsageMonitor = null;
    var gApplicationSettings = {};

    /* cspell:disable-next-line */
    var gDefaultUserImage = 'UserIcon_k5mfal';

    /**
     * @alias ecapp.btShareScopeService#accountInfo
     * @type {ecapp.IUserAccount}
     */
    var gUserAccount = _setDefaultInfo();
    var lastTimeUsed = new Date();

    /** @alias ecapp.btShareScopeService#userState */
    var gUserState = { isLogin: false };

    var gReactivationThreshold = 14;
    var gMaxInactive = 185; /* 6 months */

    var MAX_CLIENTS = 20;

    /**
     * @typedef {Object} btTraderOption
     * @property {String} label - trader type label
     * @property {String} value - trader type id
     */

    /** @type {btTraderOption[]} */
    var gTraderTypes = [
      { label: 'Day Trader', value: 'fullDay' },
      { label: 'Part Day Trader', value: 'halfDay' },
    ];

    var gLoginSuccess = false;

    /** @type {ecapp.ITimeZoneOption[]} */
    var gTimeZoneList = btDateService.getSupportedTimeZones();

    /** @type {ecapp.IBannerAnswer} */
    var ANSWER_LATER = { id: 'later', text: 'Remind me later' };
    /** @type {ecapp.IBannerAnswer} */
    var ANSWER_SHARE = { id: 'share', text: 'Share app' };

    /** Old watchlist key */
    var OLD_WATCH_KEY = 'default';

    /** New watchlist key */
    var NEW_WATCH_KEY = 'bettertrader';

    /** @type {ecapp.IGlobalBannerConfig} */
    var INVITE_FRIENDS = {
      id: btSettingsService.Banner.Invitation,
      style: 'flex success closeable',
      text: 'Do you know FX Traders? Help them by sharing this app.',
      answers: [ANSWER_LATER, ANSWER_SHARE],
      onAnswer: function (answer) {
        switch (answer.id) {
          case ANSWER_LATER.id:
            btSettingsService.setReminder('reminders.invite', 604800000 /* 7 days */);
            return $q.resolve(true);
          case ANSWER_SHARE.id:
            $state.go('ecapp.app.invite');
            return $q.resolve(false);
          default:
            btSettingsService.setReminder('reminders.invite', 1209600000 /* 14 days */);
            return $q.resolve(true);
        }
      },
      onClose: function () {
        btSettingsService.setReminder('reminders.invite', 1209600000 /* 14 days */);
        return $q.resolve(true);
      },
    };

    btEventEmitterService.addListener('login:success', onLoginSuccess);
    btEventEmitterService.addListener('logout:success', onLogoutSuccess);

    return {
      // logout: logout,
      isInitialized: checkIsInitialized,
      accountInfo: gUserAccount,
      userState: gUserState,

      setAccountInitials: setAccountInitials,
      setAccountCompany: setAccountCompany,
      setAccountImage: setAccountImage,
      setAccountDefaultImage: setAccountDefaultImage,
      setTutorialStatusToTrue: setTutorialStatusToTrue,
      setDeviceAlertToTrue: setDeviceAlertToTrue,
      setListSelectedTags: setListSelectedTags,
      getAccountSettings: getAccountSettings,
      getAccountSettingsPromise: getAccountSettingsPromise,
      getTraderTypes: getTraderTypes,
      getListOfTimeZones: getListOfTimeZones,
      checkHasRole: checkHasRole,

      getListFollowedEvents: getListFollowedEvents,
      getTutorialStatus: getTutorialStatus,
      getDeviceAlert: getDeviceAlert,

      setLoginSuccess: setLoginSuccess,
      saveLastOpenDate: saveLastOpenDate,
      isLoginSuccess: isLoginSuccess,

      addFollowedEvent: addFollowedEvent,
      removeFollowedEvent: removeFollowedEvent,
      toggleFollowedEvent: toggleFollowedEvent,

      wait: wait,
      init: loadAccountSettingsAndStockInstruments,
      initAccountSettingsAndStockInstrumentsWithData: initAccountSettingsAndStockInstrumentsWithData,
      // makeJSONFromFieldsList: makeJSONFromFieldsList,
      addNotificationToken: addNotificationToken,
      addNotificationDesktopToken: addNotificationDesktopToken,
      // removeNotificationToken: removeNotificationToken,
      removeNotificationToken: removeNotificationToken,
      removeNotificationDesktopToken: removeNotificationDesktopToken,
      setWatchedInstruments: setWatchedInstruments,
      getWatchedInstruments: getWatchedInstruments,

      getCharts: getCharts,
      setCharts: setCharts,

      userIsLogin: userIsLogin,

      setMailChimpField: setMailChimpField,
      setVisitPage: setVisitPage,
      getVisitPage: getVisitPage,
      increaseVisitPage: increaseVisitPage,

      getAccountInfoField: getAccountInfoField,
      setAccountInfoField: setAccountInfoField,
      getUserEmail: getUserEmail,

      // updateProfileSettings: updateProfileSettings,
      updateProfileSettingsFromFieldsList: updateProfileSettingsFromFieldsList,

      logTradeOrder: logTradeOrder,
      getFirstLastNames: getFirstLastNames,
      hasPracticeAccount: hasPracticeAccount,
      getDaysSince: getDaysSince,
      getUserSettings: getUserSettings,
      setUserSettings: setUserSettings,
      saveUserSettings: saveUserSettings,
      hasNotification: hasNotification,
      getNotificationSettings: getNotificationSettings,
      updateNotificationSettings: updateNotificationSettings,
      getTwitterAccounts: getTwitterAccounts,
      getNewsSources: getNewsSources,
      getAppSettings: getAppSettings,
      getLockedMailSubscription: getLockedMailSubscription,
      initializeMailSubscription: initializeMailSubscription,
      startMailSubscription: startMailSubscription,
      stopMailSubscription: stopMailSubscription,

      startMonitorUsage: startMonitorUsage,
      stopMonitorUsage: stopMonitorUsage,

      getIntercomAttributes: getIntercomAttributes,
      getChatLink: getChatLink,
      getInviteBannerConfig: getInviteBannerConfig,
      getLastTimeUsed: getLastTimeUsed,
    };

    /**
     * @alias ecapp.btShareScopeService#getAccountSettings
     */
    /**
     * @alias ecapp.btShareScopeService#getAccountSettingsPromise
     */
    /**
     * @alias ecapp.btShareScopeService#getTraderTypes
     */

    /**
     * @alias ecapp.btShareScopeService#getListFollowedEvents
     */
    /**
     * @alias ecapp.btShareScopeService#getTutorialStatus
     */
    /**
     * @alias ecapp.btShareScopeService#getDeviceAlert
     */
    /**
     * @alias ecapp.btShareScopeService#setLoginSuccess
     */

    /**
     * @alias ecapp.btShareScopeService#isLoginSuccess
     */

    /**
     * @alias ecapp.btShareScopeService#toggleFollowedEvent
     */
    /**
     * @alias ecapp.btShareScopeService#init
     */
    /**
     * @alias ecapp.btShareScopeService#initAccountSettingsAndStockInstrumentsWithData
     */
    /**
     * @alias ecapp.btShareScopeService#addNotificationToken
     */
    /**
     * @alias ecapp.btShareScopeService#addNotificationDesktopToken
     */
    /**
     * @alias ecapp.btShareScopeService#removeNotificationDesktopToken
     */
    /**
     * @alias ecapp.btShareScopeService#setWatchedInstruments
     */
    /**
     * @alias ecapp.btShareScopeService#userIsLogin
     */
    /**
     * @alias ecapp.btShareScopeService#setMailChimpField
     */
    /**
     * @alias ecapp.btShareScopeService#setVisitPage
     */
    /**
     * @alias ecapp.btShareScopeService#getVisitPage
     */
    /**
     * @alias ecapp.btShareScopeService#increaseVisitPage
     */
    /**
     * @alias ecapp.btShareScopeService#getAccountInfoField
     */
    /**
     * @alias ecapp.btShareScopeService#setAccountInfoField
     */
    /**
     * @alias ecapp.btShareScopeService#logTradeOrder
     */
    /**
     * @alias ecapp.btShareScopeService#getFirstLastNames
     */
    /**
     * @alias ecapp.btShareScopeService#hasPracticeAccount
     */
    /**
     * @alias ecapp.btShareScopeService#getLockedMailSubscription
     */
    /**
     * @alias ecapp.btShareScopeService#initializeMailSubscription
     */
    /**
     * @alias ecapp.btShareScopeService#startMailSubscription
     */
    /**
     * @alias ecapp.btShareScopeService#stopMailSubscription
     */
    /**
     * @alias ecapp.btShareScopeService#startMonitorUsage
     */

    /**
     * @alias ecapp.btShareScopeService#stopMonitorUsage
     */

    /**
     * @alias ecapp.btShareScopeService#getLastTimeUsed
     */

    /**
     *
     * @return {ecapp.IUserAccount}
     * @private
     */
    function _setDefaultInfo() {
      return {
        firstName: '',
        chatName: '',
        companyName: '',
        imageSrc: gDefaultUserImage,
        assets: {
          indices: true,
          equities: true,
          bonds: true,
          forexpairs: true,
          options: true,
          futures: true,
          commodities: true,
          other: '',
        },
        currency: [],
        priority: 1,
        notificationRules: {
          morningMail: false,
          eveningMail: false,
          weekStartMail: false,
          weekEndMail: false,
          beforeRelease: false,
          afterRelease: false,
          euMarketOpen: false,
          euMarketClose: false,
          usMarketOpen: false,
          usMarketClose: false,
          insights: false,
          tradingInsights: false,
          expectedTradingInsights: false,
          marketSense: false,
          marketWakeUp: false,
          marketTradeIdeas: false,
          newTradeIdeas: false,
        },
        tags: [],
        timeZone: '',
        traderType: '',
        userInitials: '',
        followingEvents: [],
        tutorialWasFinished: true,
        notificationTokens: [],
        notificationDesktop: [],
        pushNotificationMobile: [],
        pushNotificationWeb: [],
        watchedInstruments: [], // deprecated
        watchList: {},
        brokerData: { name: 'default', selectedTradeSize: 1, isPractice: true },
        devices: [],
        deviceAlert: false,
        visit: {},
        role: 'user', // deprecated
        roles: ['user'],
        settings: {
          voiceAssistant: {
            twitter: { enable: true, items: ['elonmusk'] },
            news: { enable: true, items: ['Bloomberg'] },
            calendar: {
              enable: true,
              items: ['upcoming', 'expected', 'release'],
            },
            ideas: { enable: true, items: ['potential'] },
            movements: { enable: false, items: [] },
            exchanges: { enable: false, items: [] },
          },
          notifications: [
            btCodes.Notification.Enabled,
            btCodes.Notification.System.Enabled,
            btCodes.Notification.System.General,
            btCodes.Notification.System.Upgrade,
            btCodes.Notification.EconomicCalendar.Enabled,
            btCodes.Notification.EconomicCalendar.Reminder,
            btCodes.Notification.EconomicCalendar.Release,
            btCodes.Notification.MarketMovements.Enabled,
            btCodes.Notification.MarketMovements.MarketSense,
            btCodes.Notification.MarketMovements.MarketWakeup,
          ],
        },
      };
    }

    /**
     * Set user initials
     *
     * @alias ecapp.btShareScopeService#setAccountInitials
     * @param {String} userInitials - user initials
     */
    function setAccountInitials(userInitials) {
      if (userInitials === '' || userInitials === 'First Name') {
        gUserAccount.userInitials = $rootScope.currentUser.email;
      } else {
        gUserAccount.userInitials = userInitials;
      }
    }

    /**
     * Set company name
     *
     * @alias ecapp.btShareScopeService#setAccountCompany
     * @param {String} companyName - company name
     */
    function setAccountCompany(companyName) {
      gUserAccount.companyName = companyName;
    }

    /**
     * Set account image
     *
     * @alias ecapp.btShareScopeService#setAccountImage
     * @param {String} imageSrc - image link
     */
    function setAccountImage(imageSrc) {
      gUserAccount.imageSrc = imageSrc;
    }

    /**
     * Set default account image
     * @alias ecapp.btShareScopeService#setAccountDefaultImage
     */
    function setAccountDefaultImage() {
      window.localStorage.removeItem('accountImage');
      gUserAccount.imageSrc = gDefaultUserImage;
    }

    /**
     * Set tutorial status to true
     *
     * @alias ecapp.btShareScopeService#setTutorialStatusToTrue
     * @return {Boolean}
     */
    function setTutorialStatusToTrue() {
      return (gUserAccount.tutorialWasFinished = true);
    }

    /**
     * Set device alert status to true
     *
     * @alias ecapp.btShareScopeService#setDeviceAlertToTrue
     * @return {Boolean}
     */
    function setDeviceAlertToTrue() {
      return (gUserAccount.deviceAlert = true);
    }

    /**
     * Set list of selected tags
     *
     * @alias ecapp.btShareScopeService#setListSelectedTags
     * @param {String[]} listOfSelectedTags - list of selected tags
     */
    function setListSelectedTags(listOfSelectedTags) {
      gUserAccount.tags = listOfSelectedTags;
    }

    /**
     * Set visit page counter for user
     *
     * @param {String} name - page name (10 letter due to MailChimp limitations)
     * @param {Number} count - number of times user visited this page
     * @return {angular.IPromise<*>}
     */
    function setVisitPage(name, count) {
      gUserAccount.visit[name] = count;
      return setMailChimpField(name, count).catch(function (reason) {
        console.error(reason);
      });
    }

    /**
     * This function updates mail chimp field.
     *
     * @param {string} name - field name (10 letter due to MailChimp limitations)
     * @param {string|number} value - field value
     * @return {angular.IPromise<*>}
     */
    function setMailChimpField(name, value) {
      if (btSettingsService.isBigBrainBank() || btSettingsService.isLinkDataService()) {
        return $q.resolve();
      }

      if (!$rootScope.currentUser || !$rootScope.currentUser.email) {
        return $q.reject(new Error('Current user is not defined.'));
      }

      var params = {
        params: {
          email: $rootScope.currentUser.email,
          name: name,
          value: value,
        },
      };
      return $http
        .get(btSettings.BT_BACKEND_URL + '/change-fields', params)
        .then(function (response) {
          if (gDebug) console.log(gPrefix, response);
        })
        .catch($btError.handleHTTPError)
        .catch(function (error) {
          console.error(error);
          return $q.reject(error);
        });
    }

    /**
     * Increase visit page counter for user
     *
     * @param {String} name - page name (10 letter due to MailChimp limitations)
     * @return {angular.IPromise<*>}
     */
    function increaseVisitPage(name) {
      var that = this;
      var count = that.getVisitPage(name);
      count = count ? count : 0;

      return that.setVisitPage(name, count + 1).then(function () {
        return that.updateProfileSettingsFromFieldsList(['visit']);
      });
    }

    /**
     * This function updates client information.
     *
     * @param {ecapp.IClientInformation} client - information about the client (device)
     */
    function saveClientInformation(client) {
      if (client && client.id) {
        if (!gUserAccount.clients) {
          gUserAccount.clients = {};
        }

        if (!gUserAccount.clients[client.id]) {
          gUserAccount.clients[client.id] = {
            id: client.id,
            launched: '',
            agent: '',
          };
        }

        gUserAccount.clients[client.id].launched = client.launched;
        gUserAccount.clients[client.id].agent = client.agent;
        fixClients(gUserAccount.clients);
      } else {
        alert('Client ID is not defined');
      }
    }

    /**
     * Fixes clients object
     *
     * @param {Record<string, any>} clients - list of user clients
     */
    function fixClients(clients) {
      const keys = Object.keys(clients);

      keys.forEach(function (key) {
        const client = clients[key];
        if (!client.id) client.id = key;
      });

      if (keys.length <= MAX_CLIENTS) return;

      keys.sort(function (a, b) {
        const aDate = new Date(clients[a].launched).getTime();
        const bDate = new Date(clients[b].launched).getTime();
        return bDate - aDate;
      });

      keys.slice(MAX_CLIENTS).forEach(function (key) {
        delete clients[key];
      });
    }

    /**
     * This function saves information about last application open.
     *
     * @alias ecapp.btShareScopeService#saveLastOpenDate
     * @param {ecapp.IClientInformation} client - information about the client (device)
     * @return {angular.IPromise<any>}
     */
    function saveLastOpenDate(client) {
      var updateFields = ['used', 'opened'];

      gUserAccount.used = new Date();
      gUserAccount.opened = new Date();
      setMailChimpField('LASTOPEN', gUserAccount.opened.toISOString().slice(0, 10));

      if (reactivateUser()) {
        updateFields.push('inactive');
        setMailChimpField('INACTIVE', 0);
      }

      if (updateVoiceUsage()) {
        updateFields.push('voice');
        updateFields.push('voiced');
        setMailChimpField('LASTVOICE', gUserAccount.voiced.toISOString().slice(0, 10));
      }

      saveClientInformation(client);
      updateFields.push('clients');

      return updateProfileSettingsFromFieldsList(updateFields);
    }

    /**
     * This function tries to reactivate user account if it's necessary.
     *
     * @return {boolean} - Returns true if account was reactivated
     */
    function reactivateUser() {
      if (gUserAccount.inactive && gUserAccount.inactive > gReactivationThreshold) {
        var period = gUserAccount.inactive >= gMaxInactive ? 'more than 6 months' : gUserAccount.inactive + ' days';
        gUserAccount.inactive = 0;
        $interval(
          function () {
            btToastrService.info("You didn't open application for " + period + '. Welcome back!', 'User settings');
          },
          500,
          1
        );
        return true;
      } else {
        return false;
      }
    }

    /**
     * This function updates voice assistant usage information
     *
     * @return {Boolean} - Returns true if information was updated
     */
    function updateVoiceUsage() {
      var lastVoiceUse = localStorage.getItem('btVoiceAssistantLastUse');
      if (lastVoiceUse) {
        var lastVoiceUseDate = new Date(lastVoiceUse);

        gUserAccount.voice = {
          enable: localStorage.getItem('btVoiceAssistantEnable'),
          uri: localStorage.getItem('btVoiceAssistantVoiceURI'),
        };

        gUserAccount.voiced = lastVoiceUseDate;
        return true;
      } else {
        return false;
      }
    }

    /**
     * This function turns on usage monitoring.
     */
    function startMonitorUsage() {
      try {
        if (gUsageMonitor === null) {
          gUsageMonitor = {
            start: new Date(),
            count: 0,
            interval: $interval(monitorUsage, 300000 /* 5 minutes */, 0, false),
          };
        }
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * This function updates information about app usage.
     */
    function monitorUsage() {
      try {
        gUserAccount.used = new Date();
        gUsageMonitor.count++;
        var errors = mergeAppErrors(gUserAccount.errors, $btError.getAppErrors());
        if (errors) {
          gUserAccount.errors = errors;
          updateProfileSettingsFromFieldsList(['used', 'errors']).catch($btError.skip);
        } else {
          updateProfileSettingsFromFieldsList(['used']).catch($btError.skip);
        }
      } catch (e) {
        $btError.reportAppError(e, gModuleName, $btError.ErrorCode.usageUpdateFailure, $btError.ErrorLevel.FATAL);
      }
    }

    /**
     * This function return list of recent application errors that is not saved in database.
     *
     * @param {ecapp.IAppErrorRecord[]} stored - app errors stored in database
     * @param {ecapp.IAppErrorRecord[]} recent - recent app errors
     * @return {?ecapp.IAppErrorRecord[]} - app errors that should be stored in database or null
     */
    function mergeAppErrors(stored, recent) {
      if (!stored || !stored.length) return recent.length ? recent : null;

      var wasUpdated = false;
      recent.forEach(function (error) {
        if (error.created > stored[stored.length - 1].created) {
          wasUpdated = true;
          stored.push(error);
          if (stored.length > 20) stored.shift();
        }
      });

      return wasUpdated ? stored : null;
    }

    /**
     * This function turns off usage monitoring.
     */
    function stopMonitorUsage() {
      try {
        if (gUsageMonitor !== null) {
          if (!$interval.cancel(gUsageMonitor.interval)) {
            console.error(new Error('Interval was not canceled'));
          }
          gUsageMonitor = null;
        }
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * Get account information
     *
     * @return {ecapp.IUserAccount}
     */
    function getAccountSettings() {
      if (gDebug) console.log(gPrefix, 'Get account');
      return gUserAccount;
    }

    /**
     * Get trader types
     *
     * @return {btTraderOption[]}
     */
    function getTraderTypes() {
      return gTraderTypes;
    }

    /**
     *
     * @alias ecapp.btShareScopeService#getListOfTimeZones
     * @return {ecapp.ITimeZoneOption[]}
     */
    function getListOfTimeZones() {
      return gTimeZoneList;
    }

    /**
     * This function return user role.
     *
     * Supported roles: user (default), community-manager, risk-monitor-tester, developer
     * @return {string[]}
     */
    function getUserRoles() {
      return gUserAccount.roles || ['user'];
    }

    /**
     * This function checks if user has specified role.
     *
     * @alias ecapp.btShareScopeService#checkHasRole
     * @param {string} role - requested role
     * @return {boolean}
     */
    function checkHasRole(role) {
      var roles = getUserRoles();
      return roles.indexOf('developer') !== -1 || roles.indexOf(role) !== -1;
    }

    /**
     *
     * @return {number[]}
     */
    function getListFollowedEvents() {
      return gUserAccount.followingEvents;
    }

    /**
     *
     * @return {boolean}
     */
    function getTutorialStatus() {
      return gUserAccount.tutorialWasFinished;
    }

    /**
     *
     * @return {boolean}
     */
    function getDeviceAlert() {
      return gUserAccount.deviceAlert;
    }

    /**
     *
     * @param {string} name - name
     * @return {number|undefined}
     */
    function getVisitPage(name) {
      return gUserAccount.visit[name];
    }

    /**
     *
     * @param {string} fieldName - field name
     * @return {any}
     */
    function getAccountInfoField(fieldName) {
      return gUserAccount[fieldName];
    }

    /**
     *
     * @param {string} fieldName - field name
     * @param {any} value
     */
    function setAccountInfoField(fieldName, value) {
      if (gDebug) console.log(gPrefix, 'Set field', fieldName, value);
      gUserAccount[fieldName] = value;
    }

    /**
     * Updates list of watched instruments.
     *
     * @param {string[]} watchedInstruments - list of watched instruments
     * @param {string} brokerName - broker name
     */
    function setWatchedInstruments(watchedInstruments, brokerName) {
      if (gDebug) console.log(gPrefix, 'Set', brokerName, gUserAccount.watchList[brokerName]);
      if (brokerName === 'default') {
        var oldSymbols = _removeDuplicates(_downgradeWatchlist(watchedInstruments));
        gUserAccount.watchList[OLD_WATCH_KEY] = oldSymbols;

        var newSymbols = _removeDuplicates(watchedInstruments);
        gUserAccount.watchList[NEW_WATCH_KEY] = newSymbols;
      } else {
        var symbols = _removeDuplicates(watchedInstruments);
        gUserAccount.watchList[brokerName] = symbols;
      }
    }

    /**
     * Returns list of watched instruments.
     *
     * @alias ecapp.btShareScopeService#getWatchedInstruments
     * @param {string} brokerName - broker name
     * @return {string[] | undefined} list of watched instruments
     */
    function getWatchedInstruments(brokerName) {
      var symbols = [];
      if (brokerName === 'default') {
        if (gUserAccount.watchList[NEW_WATCH_KEY]) {
          symbols = gUserAccount.watchList[NEW_WATCH_KEY];
        } else {
          symbols = _upgradeWatchlist(gUserAccount.watchList[OLD_WATCH_KEY]);
        }
      } else {
        symbols = gUserAccount.watchList[brokerName];
      }

      if (gDebug) console.log('>>> Test Watchlist', brokerName, symbols);
      window.btStore.watchedSymbols = symbols;
      return symbols;
    }

    /**
     * Upgrades watchlist to new format.
     *
     * @param {string[]} watchlist - user watchlist
     * @return {string[]} upgraded watchlist
     * @private
     */
    function _upgradeWatchlist(watchlist) {
      return watchlist ? watchlist.map(btInstrumentsService.upgradeSymbol) : undefined;
    }

    /**
     * Downgrades watchlist to old format.
     *
     * @param {string[]} watchlist - user watchlist
     * @return {string[]} downgraded watchlist
     * @private
     */
    function _downgradeWatchlist(watchlist) {
      return watchlist.map(btInstrumentsService.downgradeSymbol).filter(notEmpty);
    }

    /**
     *
     * @param {*} item
     * @return {boolean}
     */
    function notEmpty(item) {
      return !!item;
    }

    /**
     *
     * @param {string} broker - broker name
     * @return {string[]}
     */
    function getCharts(broker) {
      return (gUserAccount.charts || {})[broker];

      // gSavedCharts[0] = localStorage.getItem('btChartSymbol1') || btSettingsService.getAssets('chart-symbol1');
      // gSavedCharts[1] = localStorage.getItem('btChartSymbol2') || btSettingsService.getAssets('chart-symbol2');
      // gSavedCharts[2] = localStorage.getItem('btChartSymbol3') || btSettingsService.getAssets('chart-symbol3');
      // gSavedCharts[3] = localStorage.getItem('btChartSymbol4') || btSettingsService.getAssets('chart-symbol4');
    }

    /**
     *
     * @param {string} broker - broker name
     * @param {string[]} symbols - symbols
     */
    function setCharts(broker, symbols) {
      if (!gUserAccount.charts) {
        gUserAccount.charts = {};
      }
      gUserAccount.charts[broker] = symbols;
    }

    /**
     *
     * @return {angular.IPromise<string[] | void>}
     */
    function getBTInstruments() {
      var cache = btInstrumentsService.cache.get('names');
      /** @type {string[]} */
      var instrumentsList = [];

      if (cache === null || cache === undefined) {
        var instrumentsFields = { id: true, btName: true };
        var instrumentsOrder = ['_id ASC'];

        // noinspection JSUnresolvedVariable
        return lbInstruments
          .find({
            filter: { order: instrumentsOrder, fields: instrumentsFields },
          })
          .$promise.then(function (data) {
            angular.forEach(data, function (value) {
              instrumentsList.push(value['btName']);
            });
            btInstrumentsService.cache.put('names', instrumentsList);

            return instrumentsList;
          });
      } else {
        return $q.resolve();
      }
    }

    /**
     *
     * @alias ecapp.btShareScopeService#wait
     * @return {angular.IPromise<void>}
     */
    function wait() {
      if (gIsInitialized) {
        return $q.resolve(null);
      } else {
        return loadAccountSettingsAndStockInstruments();
      }
    }

    /**
     * Initialize account information
     * @return {angular.IPromise<void>}
     */
    function loadAccountSettingsAndStockInstruments() {
      // check is this function already run, if yes just waiting
      if (gInitializationInProcess) {
        if (gDebug) console.log(gPrefix, 'Try to initialize service again, wait for result');
        var deferred = $q.defer();
        gInitializationPromises.push(deferred);
        return deferred.promise;
      }

      // else load data from database
      if (gDebug) console.log(gPrefix, 'Initialize service...');
      gInitializationInProcess = true;
      var findByIdFilter = {
        fields: [
          'id',
          'email',
          'created',
          'firstName',
          'chatName',
          'secondName',
          'companyName',
          'notificationRules',
          'traderType',
          'timeZone',
          'followingEvents',
          'currency',
          'priority',
          'tags',
          'assets',
          'tutorialWasFinished',
          'watchList',
          'brokerData',
          'notificationTokens',
          'notificationDesktop',
          'devices',
          'settings',
          'deviceAlert',
          'demoAccount',
          'marketSenses',
          'visit',
          'opened',
          'used',
          'voice',
          'voiced',
          'pushNotificationMobile',
          'pushNotificationWeb',
          'telephone',
          'country',
          'location',
          'intercomHash',
          'inactive',
          'role',
          'roles',
          'gitter',
          'variant',
          'errors',
          'clients',
          'sessions',
          'usage',
          'domain',
          'charts',
        ],
      };

      if (window.localStorage.getItem('accountImage') !== null) {
        gUserAccount.imageSrc = window.localStorage.getItem('accountImage');
      }

      // noinspection EqualityComparisonWithCoercionJS
      if ($rootScope.currentUser == null) {
        gInitializationInProcess = false;
        return $q.reject(new Error('User is undefined!'));
      }

      return (
        lbUser
          .findById({ id: $rootScope.currentUser.id, filter: findByIdFilter })
          .$promise // .then(function () {
          //   var error = new Error('Unknown error');
          //   error.message = 'Unknown error';
          //   return $q.reject(error);
          // })
          .then(function (data) {
            initAccountSettingsAndStockInstrumentsWithData(data);
            gInitializationInProcess = false;
            gIsInitialized = true;

            if (gDebug) console.log(gPrefix, 'Service initialized');
            gInitializationPromises.forEach(function (deferred) {
              deferred.resolve({});
            });
            gInitializationPromises = [];

            btDateService.setUserTimeZone(gUserAccount.timeZone);
            $rootScope.tz = btDateService.getUserTimeZoneOffsetString();
            lastTimeUsed = new Date(data.used);
          })
          .catch($btError.handleHTTPError)
          .catch(function (reason) {
            gInitializationInProcess = false;
            gInitializationPromises.forEach(function (deferred) {
              deferred.reject(reason);
            });
            gInitializationPromises = [];
            console.error(reason);

            if (window.isDevelopment) btDevService.alert("Can't find user: " + JSON.stringify(reason));

            return $q.reject(reason);
          })
      );
    }

    /**
     *
     * @return {*}
     */
    function getAccountSettingsPromise() {
      if (gIsInitialized) {
        if (gDebug) console.log(gPrefix, 'Get account via promise');
        return $q.resolve(gUserAccount);
      } else {
        return loadAccountSettingsAndStockInstruments();
      }
    }

    /**
     * This function initialize user account.
     *
     * @param {object} data - user data
     */
    function initAccountSettingsAndStockInstrumentsWithData(data) {
      getBTInstruments();

      if (gDebug) console.log(gPrefix, 'Initialize account');

      gUserState.isLogin = true;

      // at first copy all user information
      // $promise
      // $resolved
      for (var val in data) {
        if (data.hasOwnProperty(val) && val !== '$promise' && val !== '$resolved') {
          gUserAccount[val] = data[val];
        }
      }

      if (data.firstName === '' || data.firstName === undefined) {
        gUserAccount.userInitials = $rootScope.currentUser.email;
      } else {
        gUserAccount.userInitials = data.firstName;
      }

      if (data.assets === undefined) {
        gUserAccount.assets = {
          indices: true,
          equities: true,
          bonds: true,
          forexpairs: true,
          options: true,
          futures: true,
          commodities: true,
          other: '',
        };
      }

      // time zone
      if (gDebug) console.log(gPrefix, 'Account time zone', data.timeZone);
      var index = gTimeZoneList
        .map(function (e) {
          return e.name;
        })
        .indexOf(data.timeZone);

      gUserAccount.timeZone = data.timeZone;
      gUserAccount.timeZoneOption = gTimeZoneList[index];

      // trader type
      if (data.traderType === 'fullDay') {
        gUserAccount.traderType = data.traderType;
        gUserAccount.traderTypeOption = gTraderTypes[0];
      } else {
        gUserAccount.traderType = data.traderType;
        gUserAccount.traderTypeOption = gTraderTypes[1];
      }

      // image
      if (data.imageSrc === undefined || data.imageSrc === '') {
        if (
          window.localStorage.getItem('accountImage') === null ||
          window.localStorage.getItem('accountImage') === ''
        ) {
          gUserAccount.imageSrc = gDefaultUserImage;
        }
      } else {
        gUserAccount.imageSrc = data.imageSrc;
      }

      window.btStore.userAccount = gUserAccount;

      gIsInitialized = true;
    }

    /**
     *
     * @param {string} newToken
     */
    function addNotificationToken(newToken) {
      newToken = _addDomainPrefix(newToken);

      if (gDebug) console.log(gPrefix, 'Desktop push token', newToken);

      // Multi token approach
      // if (accountInfoForScope.notificationTokens.indexOf(newToken) === -1) {
      //   accountInfoForScope.notificationTokens.push(newToken);
      // }

      // Single token approach
      // accountInfoForScope.notificationTokens = [newToken];
      gUserAccount.pushNotificationMobile = [newToken];
    }

    // function removeNotificationToken(newToken) {
    //   // Multi token approach
    //   // accountInfoForScope.notificationTokens.splice(accountInfoForScope.notificationTokens.indexOf(oldToken), 1);
    //
    //   // Single token approach
    //   accountInfoForScope.notificationTokens = [newToken];
    // }

    /**
     *
     * @param {string} newToken
     */
    function addNotificationDesktopToken(newToken) {
      newToken = _addDomainPrefix(newToken);

      if (gDebug) console.log(gPrefix, 'Desktop push token', newToken);
      // Multi token approach
      // if (accountInfoForScope.notificationDesktop.indexOf(newToken) === -1) {
      //   accountInfoForScope.notificationDesktop.push(newToken);
      // }

      // Single token approach
      // accountInfoForScope.notificationDesktop = [newToken];
      gUserAccount.pushNotificationWeb = [newToken];
    }

    /**
     *
     * @param {string} token
     * @return {string}
     */
    function _addDomainPrefix(token) {
      if (window.btSettings.BT_ONE_SIGNAL_DOMAIN === 'linkdataservice') {
        return '____lds0|' + token;
      }

      if (window.btSettings.BT_ONE_SIGNAL_DOMAIN === 'bigbrainbank-test') {
        return '____bbb1|' + token;
      }

      if (window.btSettings.BT_ONE_SIGNAL_DOMAIN === 'bigbrainbank') {
        return '____bbb0|' + token;
      }

      if (window.btSettings.BT_ONE_SIGNAL_DOMAIN === 'optimus-test') {
        return 'optimus1|' + token;
      }

      if (window.btSettings.BT_ONE_SIGNAL_DOMAIN === 'optimus') {
        return 'optimus0|' + token;
      }

      if (window.btSettings.BT_ONE_SIGNAL_DOMAIN === 'test') {
        return '___test0|' + token;
      }

      if (window.btSettings.BT_ONE_SIGNAL_DOMAIN === 'local') {
        return '__local0|' + token;
      }

      if (window.btSettings.BT_ONE_SIGNAL_DOMAIN === 'app') {
        return '____app0|' + token;
      }

      return token;
    }

    /**
     *
     * @param {string} oldToken
     */
    function removeNotificationToken(oldToken) {
      void oldToken;
      // Multi token approach
      // accountInfoForScope.notificationDesktop.splice(accountInfoForScope.notificationDesktop.indexOf(oldToken), 1);

      // Single token approach
      // accountInfoForScope.notificationDesktop = [];
      gUserAccount.pushNotificationMobile = [];
    }

    /**
     *
     * @param {string} oldToken
     */
    function removeNotificationDesktopToken(oldToken) {
      void oldToken;
      // Multi token approach
      // accountInfoForScope.notificationDesktop.splice(accountInfoForScope.notificationDesktop.indexOf(oldToken), 1);

      // Single token approach
      // accountInfoForScope.notificationDesktop = [];
      gUserAccount.pushNotificationWeb = [];
    }

    // /**
    //  * Read list of timezones from moment-timezones.
    //  * Creating list in scope for drop list element (items of the list have next structure: {name:"",offset:num,label:""})
    //  *
    //  * @ngdoc method
    //  * @name getListOfTimeZones
    //  * @memberOf ecapp.btShareScopeService
    //  * @return {*}
    //  */
    // function getTimeZoneListFromMomentJS() {
    //   var listTZ = moment.tz.names();
    //   var list = [];

    //   angular.forEach(listTZ, function (value) {
    //     list.push({
    //       name: value,
    //       offset: moment().tz(value).utcOffset(),
    //       label: value + ' (' + moment([2015, 0, 1]).tz(value).format('Z') + ')'
    //     });
    //   });

    //   return list;
    // }

    /**
     *
     * @param {string[]} fieldsList - fields
     * @return {any}
     */
    function makeJSONFromFieldsList(fieldsList) {
      var data = {};

      angular.forEach(fieldsList, function (value) {
        if (value === 'timeZone' && typeof gUserAccount[value] === 'object') {
          data[value] = gUserAccount[value]['name'];
          return;
        }

        if (value === 'traderType' && typeof gUserAccount[value] === 'object') {
          data[value] = gUserAccount[value]['value'];
          return;
        }

        data[value] = gUserAccount[value];
      });

      return data;
    }

    /**
     *
     * @param {number | string} event
     * @return {number}
     */
    function parseEventId(event) {
      return typeof event === 'string' ? parseInt(event) : event;
    }

    /**
     * @alias ecapp.btShareScopeService#addFollowedEvent
     * @param {number | string} event - event identifier
     */
    function addFollowedEvent(event) {
      var eventId = parseEventId(event);
      gUserAccount.followingEvents.push(eventId);
    }

    /**
     * @alias ecapp.btShareScopeService#removeFollowedEvent
     * @param {number | string} event - event identifier
     */
    function removeFollowedEvent(event) {
      var eventId = parseEventId(event);
      gUserAccount.followingEvents.splice(gUserAccount.followingEvents.indexOf(eventId), 1);
    }

    /**
     * Toggles followed event.
     *
     * @param {number | string} event - event identifier
     * @return {boolean}
     */
    function toggleFollowedEvent(event) {
      var eventId = parseEventId(event);
      if (gUserAccount.followingEvents.indexOf(eventId) !== -1) {
        gUserAccount.followingEvents.splice(gUserAccount.followingEvents.indexOf(eventId), 1);
        return false;
      } else {
        gUserAccount.followingEvents.push(eventId);
        return true;
      }
    }

    /**
     *
     * @return {boolean}
     */
    function userIsLogin() {
      if ($rootScope.currentUser !== null && $rootScope.currentUser !== undefined) return true;
      else return gUserState.isLogin;
    }

    /**
     * Update user profile partially (only fields from fields list)
     *
     * @alias ecapp.btShareScopeService#updateProfileSettingsFromFieldsList
     * @param {String[]} fieldsList - list of fields to update
     * @return {angular.IPromise<any>}
     */
    function updateProfileSettingsFromFieldsList(fieldsList) {
      if (!gIsInitialized) {
        console.error('Save user before initialization (updateProfileSettingsFromFieldsList)');
        return $q.reject(new Error('Service is not initialized.'));
      }

      var data = makeJSONFromFieldsList(fieldsList);
      data.updated = new Date();
      removeListDuplications(data);
      // noinspection JSUnresolvedVariable
      return lbUser
        .prototype$patchAttributes({ id: $rootScope.currentUser.id }, data)
        .$promise.catch($btError.handleHTTPError);
    }

    /**
     *
     * @param {ecapp.IUserAccount} data
     */
    function removeListDuplications(data) {
      if (data) {
        if (data.followingEvents && data.followingEvents.length > 0) {
          data.followingEvents = _removeDuplicates(data.followingEvents);
        }
      }
    }

    /**
     * This function removes duplicates from an array.
     *
     * @param {any[]} src - source array with duplicates
     * @return {any[]} - array without duplicates
     * @private
     */
    function _removeDuplicates(src) {
      return src.filter(function (item, pos, self) {
        return self.indexOf(item) === pos;
      });
    }

    /**
     * This function returns user settings.
     *
     * @alias ecapp.btShareScopeService#getUserSettings
     * @param {string} name - settings name
     * @param {object} [initial] - initial value
     * @return {*}
     */
    function getUserSettings(name, initial) {
      if (!gIsInitialized) {
        console.error('Load user settings before initialization (getUserSettings)');
        return undefined;
      }

      if (gUserAccount.settings && gUserAccount.settings[name]) {
        return gUserAccount.settings[name];
      } else if (initial) {
        gUserAccount.settings[name] = initial;
        return gUserAccount.settings[name];
      } else {
        return null;
      }
    }

    /**
     * This function sets user settings.
     *
     * @alias ecapp.btShareScopeService#setUserSettings
     * @param {string} name - settings name
     * @param {*} data - settings data
     * @return {boolean}
     */
    function setUserSettings(name, data) {
      if (gUserAccount.settings === undefined) gUserAccount.settings = {};
      gUserAccount.settings[name] = data;
      return true;
    }

    /**
     * This function saves user settings.
     *
     * @alias ecapp.btShareScopeService#saveUserSettings
     * @param {string} name - settings name
     * @param {*} data - settings data
     * @return {angular.IPromise<*>}
     */
    function saveUserSettings(name, data) {
      if (gUserAccount.settings === undefined) gUserAccount.settings = {};
      gUserAccount.settings[name] = data;
      return updateProfileSettingsFromFieldsList(['settings']);
    }

    /**
     * This function checks if the user is subscribed to the specified notification.
     *
     * @alias ecapp.btShareScopeService#hasNotification
     * @param {number} code - code of the specified notification
     * @return {boolean}
     */
    function hasNotification(code) {
      var notifications = getUserSettings('notifications', []);
      return notifications.indexOf(code) !== -1;
    }

    /**
     * This function updates notification settings if it is necessary.
     *
     * @alias ecapp.btShareScopeService#updateNotificationSettings
     * @param {number|string} id - notification identifier
     * @param {boolean} isSubscribed - whether the user is subscribed to this notification
     * @return {angular.IPromise<*>}
     */
    function updateNotificationSettings(id, isSubscribed) {
      if (typeof id === 'number') return updateNotificationByCode(id, isSubscribed);
      else if (typeof id === 'string') {
        var path = id.split('.');
        if (btCodes.Notification[path[0]] !== undefined && btCodes.Notification[path[0]][path[1]] !== undefined)
          return updateNotificationByCode(btCodes.Notification[path[0]][path[1]], isSubscribed);
        else return $q.reject(new Error('Invalid identifier - ' + JSON.stringify(id)));
      } else return $q.reject(new Error('Invalid identifier - ' + JSON.stringify(id)));
    }

    /**
     * This function updates notification settings if it is necessary.
     *
     * @private
     * @param {number} notificationCode - notification code
     * @param {boolean} isSubscribed - whether the user is subscribed to this notification
     * @return {angular.IPromise<*>}
     */
    function updateNotificationByCode(notificationCode, isSubscribed) {
      /** @type {number[]} */
      var notifications = getUserSettings('notifications', []);

      var categoryCode = Math.floor(notificationCode / 1000) * 1000;

      /** @type {boolean} */
      var wasUpdated;

      if (isSubscribed) {
        var f1 = toggleNotification(notifications, categoryCode, true);
        var f2 = toggleNotification(notifications, notificationCode, true);
        wasUpdated = f1 || f2;
      } else {
        wasUpdated = toggleNotification(notifications, notificationCode, false);
      }

      if (wasUpdated) {
        notifications = notifications.sort(function (a, b) {
          return a - b;
        });
        return saveUserSettings('notifications', notifications);
      } else {
        return $q.resolve();
      }
    }

    /**
     * This function toggles notification settings.
     *
     * @private
     * @param {number[]} notifications - list of notifications that the user is subscribed to
     * @param {number} notificationCode - notification code
     * @param {boolean} isSubscribed - whether the user is subscribed to this notification
     * @return {boolean}
     */
    function toggleNotification(notifications, notificationCode, isSubscribed) {
      var wasUpdated = false;
      var i = notifications.indexOf(notificationCode);
      if (isSubscribed) {
        if (i === -1) {
          notifications.push(notificationCode);
          wasUpdated = true;
        }
      } else {
        if (i !== -1) {
          notifications.splice(i, 1);
          wasUpdated = true;
        }
      }
      return wasUpdated;
    }

    /**
     * This function returns notification settings as an object.
     *
     * @alias ecapp.btShareScopeService#getNotificationSettings
     * @return {object} - notification settings
     */
    function getNotificationSettings() {
      var notifications = {};

      Object.keys(btCodes.Notification).forEach(function (key1) {
        if (typeof btCodes.Notification[key1] === 'object') {
          notifications[key1] = {};

          Object.keys(btCodes.Notification[key1]).forEach(function (key2) {
            notifications[key1][key2] = hasNotification(btCodes.Notification[key1][key2]);
          });
        } else {
          notifications[key1] = hasNotification(btCodes.Notification[key1]);
        }
      });

      return notifications;
    }

    /**
     * This function returns twitter settings.
     *
     * @alias ecapp.btShareScopeService#getTwitterAccounts
     * @return {angular.IPromise<any[]>}
     */
    function getTwitterAccounts() {
      return getAppSettings('twitter-accounts').then(function (value) {
        return value.accounts || [];
      });
    }

    /**
     * This function returns twitter settings.
     *
     * @alias ecapp.btShareScopeService#getNewsSources
     * @return {angular.IPromise<any[]>}
     */
    function getNewsSources() {
      return getAppSettings('news-crawler').then(function (value) {
        value.sources.forEach((crawler, index) => (crawler.enable = index == 0)); //TODO: Maybe it should be in mongoDB
        return value.sources || [];
      });
    }

    /**
     * This function returns specific application settings.
     *
     * @alias ecapp.btShareScopeService#getApplicationSettings
     * @param {string} name - settings name
     * @return {angular.IPromise<any>}
     */
    function getAppSettings(name) {
      if (gApplicationSettings[name]) {
        return $q.resolve(gApplicationSettings[name]);
      } else {
        return preloadAppSettings(name).then(function (docs) {
          if (docs.length === 1 && docs[0].data && docs[0].data) {
            gApplicationSettings[name] = docs[0].data;
          } else {
            // return $q.reject(new Error('Can\'t find settings ' + name + '.'));
            gApplicationSettings[name] = {};
          }
          return gApplicationSettings[name];
        });
      }
    }

    /**
     * Loads app settings.
     *
     * @param {string} name
     * @return {angular.IPromise<ecapp.IAppSettings[]>}
     */
    function preloadAppSettings(name) {
      if (window.isTesting) {
        return lbSettings.find({ filter: { where: { name: 'test-' + name } } }).$promise.then(function (docs) {
          if (docs.length === 0) {
            return lbSettings.find({ filter: { where: { name: name } } }).$promise;
          } else {
            return docs;
          }
        });
      } else {
        return lbSettings.find({ filter: { where: { name: name } } }).$promise;
      }
    }

    // /**
    //  * Update user profile
    //  *
    //  * @param {Object} data - user object
    //  * @return {angular.IPromise<null>}
    //  */
    // function updateProfileSettings(data) {
    //   if (!isInitialized) {
    //     console.error('updateProfileSettings - Try to save user before initialization!');
    //     return $q.reject(new Error('Service is not initialized'));
    //   }
    //
    //   data.updated = new Date();
    //   return lbUser.upsert(data).$promise;
    // }

    /**
     * This function checks if the service is initialized.
     *
     * @alias ecapp.btShareScopeService#isInitialized
     * @return {Boolean}
     */
    function checkIsInitialized() {
      return gIsInitialized;
    }

    /**
     * This function processes log out process.
     *
     * @private
     */
    function logout() {
      var defaultValues = _setDefaultInfo();

      for (var prop in gUserAccount) {
        if (gUserAccount.hasOwnProperty(prop)) {
          delete gUserAccount[prop];

          if (defaultValues.hasOwnProperty(prop)) {
            gUserAccount[prop] = defaultValues[prop];
          }
        }
      }

      setAccountDefaultImage();
      gUserState.isLogin = false;
      // console.log('Test logout:', gAccountInfoForScope);
    }

    /**
     *
     * @param {*} data
     */
    function logTradeOrder(data) {
      // console.log('logTradeOrder', data.user, data.request, data.response);
      lbLogTradeOrder.create(data);
    }

    /**
     *
     * @param {String} userName
     * @return {String[]}
     */
    function getFirstLastNames(userName) {
      var names = userName.split(' ');
      return [names[0], names.slice(1).join(' ')];
    }

    /**
     *
     * @return {boolean}
     */
    function hasPracticeAccount() {
      return gUserAccount.demoAccount !== undefined;
    }

    /**
     *
     * @param {boolean} state
     */
    function setLoginSuccess(state) {
      gLoginSuccess = state;
    }

    /**
     *
     * @return {boolean}
     */
    function isLoginSuccess() {
      return gLoginSuccess;
    }

    /**
     *
     * @param {any} data
     */
    function onLoginSuccess(data) {
      try {
        void data;
      } catch (e) {
        console.error(e);
      }
    }

    /**
     *
     * @param {any} data
     */
    function onLogoutSuccess(data) {
      try {
        void data;
        logout();
      } catch (e) {
        console.error(e);
      }
    }

    /**
     * Get MailChimp user record
     *
     * @return {angular.IPromise<*>}
     */
    function getMailChimpUser() {
      var params = { params: { email: $rootScope.currentUser.email } };
      return $http
        .get(btSettings.BT_BACKEND_URL + '/mail-subscriptions', params)
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return $q.reject(response);
          }
        })
        .catch($btError.handleHTTPError)
        .catch(function (error) {
          console.error(error);
          return $q.reject(error);
        });
    }

    /**
     * Changes MailChimp user record
     *
     * @param {string} status
     * @return {angular.IPromise<ecapp.IMailChimpUser>}
     */
    function changeMailChimpUser(status) {
      var params = {
        params: { email: $rootScope.currentUser.email, status: status },
      };
      return $http
        .patch(btSettings.BT_BACKEND_URL + '/mail-subscriptions', {}, params)
        .then(function (response) {
          if (response.status === 200) {
            return response.data;
          } else {
            return $q.reject(response);
          }
        })
        .catch($btError.handleHTTPError)
        .catch(function (error) {
          console.error(error);
          return $q.reject(error);
        });
    }

    /**
     * undefined - user don't have MailChimp record
     * pending - this address requested to be added with double-opt-in but hasn't confirmed their subscription yet.
     * cleaned - this address bounced and has been removed from the list.
     * subscribed - this address is on the list and ready to receive email. You can only send campaigns to 'subscribed' addresses.
     * unsubscribed - this address used to be on the list but isn’t anymore
     *
     * @typedef {Object} btMailSubscription
     * @property {?String} status - subscription status: subscribed, unsubscribed, cleaned, pending, transactional
     * @property {Boolean} subscribed - whether the user is subscribed
     * @property {Boolean} blocked - whether the user is blocked
     * @property {String} icon - icon name
     */

    /**
     *
     * @return {ecapp.IMailSubscription}
     */
    function getLockedMailSubscription() {
      return {
        status: null,
        subscribed: false,
        blocked: true,
        icon: 'ion-locked',
      };
    }

    /**
     * This function loads mail subscription object from MailChimp
     *
     * @return {angular.IPromise<ecapp.IMailSubscription>}
     */
    function initializeMailSubscription() {
      return getMailChimpUser()
        .then(function (value) {
          return {
            status: value.status,
            subscribed: value.status === 'subscribed',
            blocked: value.status !== 'subscribed' && value.status !== 'unsubscribed',
            icon: value.status !== 'subscribed' && value.status !== 'unsubscribed' ? 'ion-locked' : undefined,
          };
        })
        .catch(function (reason) {
          console.error(reason);
          return getLockedMailSubscription();
        });
    }

    /**
     * This function subscribes user to mailing
     *
     * @return {angular.IPromise<ecapp.IMailChimpUser>}
     */
    function startMailSubscription() {
      return changeMailChimpUser('subscribed');
    }

    /**
     * This function unsubscribes user from mailing
     *
     * @return {angular.IPromise<ecapp.IMailChimpUser>}
     */
    function stopMailSubscription() {
      return changeMailChimpUser('unsubscribed');
    }

    /**
     *
     * @return {string}
     */
    function getUserEmail() {
      return $rootScope.currentUser.email;
    }

    /**
     *
     * @param {string} name
     * @return {number | null}
     */
    function getDaysSince(name) {
      if (gUserAccount.hasOwnProperty(name)) {
        var reference = new Date(gUserAccount[name]);
        return (new Date().getTime() - reference.getTime()) / 24 / 60 / 60 / 1000;
      } else {
        return null;
      }
    }

    /**
     * This function loads intercom attributes of user.
     *
     * @alias ecapp.btShareScopeService#getIntercomAttributes
     * @return {angular.IPromise<ecapp.IIntercomAttributes|null>}
     */
    function getIntercomAttributes() {
      if (btSettingsService.isLinkDataService()) {
        $q.resolve({ country: '', sessions: 0, broker: null });
      } else {
        return lbUser
          .prototype$getIntercomAttributes({ id: $rootScope.currentUser.id }, {})
          .$promise.catch($btError.handleHTTPError)
          .catch(function (reason) {
            console.error(reason);
            return null;
          })
          .then(function (result) {
            // console.log(result);
            return result ? result.attributes : null;
          });
      }
    }

    /**
     * This function returns AI chat link.
     *
     * @return {angular.IPromise<string>}
     */
    function getChatLink() {
      return lbUser.getChatLink().$promise.then(function (data) {
        return data.result;
      });
    }

    /**
     * This function returns invitation banner if necessary.
     *
     * @alias ecapp.btShareScopeService#getInviteBannerConfig
     * @return {angular.IPromise<ecapp.IGlobalBannerConfig>}
     */
    function getInviteBannerConfig() {
      if (btSettingsService.hasFeature(btAppFeatures.InviteBanner)) {
        return getIntercomAttributes().then(function (attrs) {
          var time = btSettingsService.getReminder('reminders.invite');
          return attrs && attrs.sessions > 7 && (time === undefined || time <= new Date()) ? INVITE_FRIENDS : null;
        });
      } else {
        return $q.resolve(null);
      }
    }

    /**
     * Returns time of last usage.
     *
     * @return {Date}
     */
    function getLastTimeUsed() {
      return lastTimeUsed;
    }
  }
})();
