/* global Sentry, libphonenumber */

// @ts-check
(function btAuthServiceClosure() {
  'use strict';

  angular.module('ecapp').factory('btAuthService', btAuthService);

  btAuthService.$inject = [
    'Reviewer',
    '$q',
    '$window',
    '$rootScope',
    'btShareScopeService',
    '$analytics',
    'toastr',
    'btSettingsService',
    'btInstrumentsService',
    '$cordovaOauth',
    '$http',
    '$cookies',
    'LoopBackAuth',
    'btSubscriptionService',
    'btWindowsSizeService',
    'btEventEmitterService',
    'btEventsService',
    'btErrorService',
    'btDevService',
    'btRestrictionService',
    'btMarketing',
    'btTagService',
    'btCodes',
    'btPushNotificationService',
    'btToastrService',
  ];

  /**
   * @memberOf ecapp
   * @ngdoc service
   * @name btAuthService
   * @description
   *  This factory
   * @param {ecapp.IGeneralLoopbackService} lbUser - ?
   * @param {angular.IQService} $q - promise interface
   * @param {angular.IWindowService} $window - window object service
   * @param {ecapp.ICustomRootScope} $rootScope - ?
   * @param {ecapp.IShareScopeService} btShareScopeService - ?
   * @param {ext.IAnalyticsService} $analytics - ?
   * @param {ext.IToastrService} toastr - ?
   * @param {ecapp.ISettingsService} btSettingsService - ?
   * @param {ecapp.IInstrumentsService} btInstrumentsService - ?
   * @param {ext.ICordovaAuthService} $cordovaOauth - ?
   * @param {angular.IHttpService} $http - ?
   * @param {ext.ICookiesService} $cookies - ?
   * @param {ecapp.ILoopBackAuth} LoopBackAuth - ?
   * @param {ecapp.ISubscriptionService} btSubscriptionService - ?
   * @param {ecapp.IWindowsSizeService} btWindowsSizeService - ?
   * @param {ecapp.IEventEmitterService} btEventEmitterService - ?
   * @param {ecapp.IEventsService} btEventsService - ?
   * @param {ecapp.IErrorService} btErrorService - ?
   * @param {ecapp.IDevService} btDevService - ?
   * @param {ecapp.IRestrictionService} btRestrictionService - ?
   * @param {ecapp.IMarketing} btMarketing - ?
   * @param {ecapp.ITagService} btTagService - ?
   * @param {ecapp.ICodes} btCodes - ?
   * @param {ecapp.IPushNotificationService} btPushNotificationService - ?
   * @param {ecapp.IToastrService} btToastrService - ?
   * @return {ecapp.IAuthService}
   */
  function btAuthService(
    lbUser,
    $q,
    $window,
    $rootScope,
    btShareScopeService,
    $analytics,
    toastr,
    btSettingsService,
    btInstrumentsService,
    $cordovaOauth,
    $http,
    $cookies,
    LoopBackAuth,
    btSubscriptionService,
    btWindowsSizeService,
    btEventEmitterService,
    btEventsService,
    btErrorService,
    btDevService,
    btRestrictionService,
    btMarketing,
    btTagService,
    btCodes,
    btPushNotificationService,
    btToastrService
  ) {
    console.log('Running btAuthService');

    return {
      login: login,
      socialLogin: socialLogin,
      autoLogin: autoLogin,
      logout: logout,
      register: register,
      // postLogin: postLogin,
      postSocialLogin: postSocialLogin,
      resetPassword: resetPassword,
      deleteAccount: deleteAccount,
      getCurrInfo: getCurrInfo,
      getAccessFromCookies: getAccessFromCookies,
      isValidEmail: isValidEmail,
      isValidPassword: isValidPassword,
      validatePhoneNumber: validatePhoneNumber,
      startExternalLogin: startExternalLogin,
    };

    /**
     * This function send request to loopback server to sign in user and receive authorization token
     *
     * @param {String} email - user email
     * @param {String} password - user password
     * @return {angular.IPromise<null>}
     */
    function login(email, password) {
      return lbUser
        .login({ email: email.toLowerCase(), password: password })
        .$promise.then(verifyResponse)
        .then(function (response) {
          if (response.terminated) {
            btToastrService.info(
              'You aborted the sessions on another devices. Please note that you can run one mobile version and one web version at the same time.',
              'Authorization',
              { timeOut: 30000, closeButton: true, type: 'system' }
            );
          }
          $analytics.eventTrack('login', { category: 'account', label: 'email' });
          return postLogin(response.user, response.id, response.subscription, response.accessData);
        })
        .catch(btErrorService.handleHTTPError)
        .catch(function (reason) {
          console.error('Login error', reason);
          return $q.reject(reason);
        });
    }

    /**
     *
     * @param {*} response
     * @return {*}
     */
    function verifyResponse(response) {
      if (response && response.user && response.subscription && response.accessData) {
        return response;
      } else {
        return $q.reject(new Error('Bad response'));
      }
    }

    /**
     * Auto login function
     * @alias ecapp.btAuthService#autoLogin
     * @return {angular.IPromise<boolean>} return tutorial status
     */
    function autoLogin() {
      if ($rootScope.currentUser && $rootScope.currentUser.email) {
        // send data to mixpanel
        btTagService.setUserId($rootScope.currentUser.id);
        $analytics.setUsername($rootScope.currentUser.id);
        $analytics.setSuperProperties({ email: $rootScope.currentUser.email || 'Corrupted user' });
        $analytics.eventTrack('enter', { category: 'account', label: 'auto-login' });
      }

      return $q
        .all([btSubscriptionService.updateSubscription(), btShareScopeService.init(), lbUser.getAccessData().$promise])
        .then(function (results) {
          console.log('First init of btInstrumentsService!');

          // Save user locally
          window.localStorage.setItem('currentUser', JSON.stringify($rootScope.currentUser));

          // Update access data
          console.log('TEST: Access Data', results[2].result);
          btSettingsService.saveAccessData(results[2].result);
          btSettingsService.loadAccessData();
          btSettingsService.useAccessData();

          // var deferred = $q.defer();
          // return deferred.promise;

          // Run listeners
          btDevService.alert('btAuthService: start login:success');
          btEventEmitterService.emitEvent('login:success', [$rootScope.currentUser]);
          btDevService.alert('btAuthService: finish login:success');

          // Update user account
          btShareScopeService.setLoginSuccess(true);
          btShareScopeService.saveLastOpenDate(btSettingsService.getClientInformation());
          btShareScopeService.startMonitorUsage();

          btMarketing.login(btShareScopeService.accountInfo, btSubscriptionService.getCombinedSubscription());

          savePlatformInformation();

          $rootScope.isFree = btSubscriptionService.isFree();
          $rootScope.isLifetime = btSubscriptionService.isLifetime();
          $rootScope.isRecurring = btSubscriptionService.isRecurring();
        })
        .then(finishAuthentication)
        .then(function () {
          return $q.all([btInstrumentsService.init(), btEventsService.init()]);
        })
        .then(function () {
          return btShareScopeService.getTutorialStatus();
        });
    }

    /**
     * Social login
     *
     * @param {String} provider - social login provider
     * @return {angular.IPromise<*>}
     */
    function socialLogin(provider) {
      provider = provider.toLowerCase();

      btTagService.intendSocialLogin();

      // is provider supported
      var supportedProviders = ['facebook', 'linkedin', 'google', 'apple'];
      if (supportedProviders.indexOf(provider) === -1) return $q.reject(new Error('Unknown social login provider'));

      if (window.isApp) {
        switch (provider) {
          case 'facebook':
            return _facebookMobileLogin();
          case 'linkedin':
            return _linkedinMobileLogin();
          case 'google':
            return _googleMobileLogin();
          case 'apple':
            return _appleMobileLogin();
          default:
            return $q.reject(new Error('Unknown social login provider'));
        }
      } else {
        window.open('/auth/' + provider, '_top');

        // Waiting for redirect...
        var deferred = $q.defer();
        return deferred.promise;
      }
    }

    /**
     * Facebook social login via mobile application
     *
     * @return {angular.IPromise<*>}
     * @private
     */
    function _facebookMobileLogin() {
      var deferred = $q.defer();

      if ($window.facebookConnectPlugin) {
        $window.facebookConnectPlugin.login(['public_profile', 'email'], onSuccess, onFail);
        return deferred.promise;
      } else {
        return $q.reject(new Error("Can't find Cordova Facebook Connect Plugin"));
      }

      /**
       *
       * @param {*} userData
       */
      function onSuccess(userData) {
        // console.log('UserInfo: ', userData);
        if ($window.facebookConnectPlugin) {
          $window.facebookConnectPlugin.getAccessToken(function (token) {
            void token;
            // console.log('Token: ' + token);
            // console.log('Response Object -> ', userData);

            var host = window.isDevMode ? 'https://bt-client-server-test.herokuapp.com' : 'https://app.bettertrader.co';

            var localToken = encodeURIComponent(userData.authResponse.accessToken);
            $http
              .get(host + '/auth/facebook-token/callback?access_token=' + localToken)
              .then(function (response) {
                // console.log('Access Data:', response.data);
                deferred.resolve(response.data);
              })
              .catch(function (error) {
                console.error(error);
                deferred.reject(error);
              });
          });
        } else {
          deferred.reject(new Error("Can't find Cordova Facebook Connect Plugin"));
        }
      }

      /**
       *
       * @param {*} error
       */
      function onFail(error) {
        deferred.reject(error);
      }
    }

    /**
     * LinkedIn social login via in app browser
     *
     * @return {angular.IPromise<*>}
     * @private
     */
    function _linkedinMobileLogin() {
      /* cspell:disable-next-line */
      var id = '77z7yorofnv2jr';
      /* cspell:disable-next-line */
      var secret = 'nmClmw1zr88lyh6V';
      var scope = ['r_liteprofile', 'r_emailaddress'];
      var state = 'test';
      var redirectUrl = 'http://localhost:3000/auth/linkedin/callback';

      return $cordovaOauth
        .linkedin(id, secret, scope, state, { redirect_uri: redirectUrl })
        .then(function (result) {
          // console.log('Response Object -> ', JSON.stringify(result));
          var host = window.isDevMode ? 'https://bt-client-server-test.herokuapp.com' : 'https://app.bettertrader.co';
          var token = encodeURIComponent(result.access_token);

          return $http.get(host + '/auth/linkedin-token/callback?access_token=' + token + '&state=' + state);
        })
        .then(function (response) {
          // console.log('Access Data:', response.data);
          return response.data;
        })
        .catch(function (error) {
          return $q.reject(error);
        });
    }

    /**
     * Google social login via mobile application
     *
     * @return {angular.IPromise<*>}
     * @private
     */
    function _googleMobileLogin() {
      var deferred = $q.defer();

      if ($window.plugins && $window.plugins.googleplus) {
        /* cspell:disable-next-line */
        var webClientId = '694086889160-8itdcab4nrn0n8g16nngeuuc6qf4kvlo.apps.googleusercontent.com';

        var params = {
          scopes: 'profile email',
          webClientId: webClientId,
          offline: true,
        };
        $window.plugins.googleplus.login(params, onSuccess, onFail);
      } else {
        return $q.reject(new Error('GooglePlus cordova plugin is undefined!'));
      }

      return deferred.promise;

      /**
       *
       * @param {*} obj
       */
      function onSuccess(obj) {
        console.log('Google SignIn:', JSON.stringify(obj)); // do something useful instead of alerting
        // console.log(obj.idToken); // do something useful instead of alerting
        // console.log(obj.accessToken); // do something useful instead of alerting
        // console.log(obj.serverAuthCode); // do something useful instead of alerting
        // console.log('Social login data:', obj);
        var host = window.isDevMode ? 'https://bt-client-server-test.herokuapp.com' : 'https://app.bettertrader.co';

        // $http.get(host + '/auth/google-token/callback?access_token=' + encodeURIComponent(obj.idToken))
        $http
          .get(host + '/auth/google-token/callback?code=' + encodeURIComponent(obj.serverAuthCode))
          .then(function (response) {
            console.log('Google Token Response:', JSON.stringify(response));
            deferred.resolve(response.data);
          })
          .catch(function (error) {
            console.log('Google Token Response:', JSON.stringify(error));
            deferred.reject(error);
          });
      }

      /**
       *
       * @param {*} msg
       */
      function onFail(msg) {
        deferred.reject(msg);
      }
    }

    /**
     * This function implements "Sign in with Apple".
     *
     * @return {angular.IPromise<object>}
     * @private
     */
    function _appleMobileLogin() {
      if (window.cordova && window.cordova.plugins && window.cordova.plugins.SignInWithApple) {
        if (window.isAndroid) {
          alert('Temporarily unavailable. Please contact our support team.');
          return $q.reject('Temporarily unavailable. Please contact our support team.');
        }

        var deferred = $q.defer();

        window.cordova.plugins.SignInWithApple.signin(
          { requestedScopes: [0, 1] },
          function (success) {
            localStorage.setItem('btAppleSignIn', success);
            // alert(JSON.stringify(success));

            var host = window.isDevMode ? 'https://bt-client-server-test.herokuapp.com' : 'https://app.bettertrader.co';

            var code = encodeURIComponent(success.authorizationCode);
            var state = encodeURIComponent(success.state);
            $http
              .post(host + '/auth/apple-token/callback', { code: code, state: state })
              .then(function (response) {
                // alert(JSON.stringify(response.data));
                deferred.resolve(response.data);
              })
              .catch(function (error) {
                // alert(JSON.stringify(error));
                deferred.reject(error);
              });
          },
          function (error) {
            // alert(JSON.stringify(error));
            deferred.reject(error);
          }
        );

        return deferred.promise;
      } else {
        alert('Cordova plugin is not detected.');
        return $q.reject(new Error('Plugin is not detected.'));
      }
    }

    /**
     * Social logout. Remove access dat to have ability to run social login next time.
     * @private
     */
    function _socialLogout() {
      if (window.isApp) {
        // try to logout from facebook
        if ($window.facebookConnectPlugin) {
          $window.facebookConnectPlugin.logout(
            function () {
              console.log('Facebook logout - success');
            },
            function () {
              console.log('Facebook logout - failure');
            }
          );
        }

        // try to logout from googleplus
        if ($window.plugins && $window.plugins.googleplus) {
          $window.plugins.googleplus.logout(function (msg) {
            console.log('Google logout', msg); // do something useful instead of alerting
          });
        }
      }
    }

    /**
     * Post social login function
     *
     * @param {*} accessToken - ?
     * @return {angular.IPromise<*>}
     */
    function postSocialLogin(accessToken) {
      // console.log('Access Token', JSON.stringify(accessToken));

      var token = accessToken.id || accessToken.access_token;

      LoopBackAuth.currentUserId = accessToken.userId;
      LoopBackAuth.accessTokenId = token;
      LoopBackAuth.rememberMe = true;
      LoopBackAuth.save();
      // console.log('LoopBackAuth after', LoopBackAuth);

      // Save partial user data
      $rootScope.currentUser = {
        id: /**@type {string}*/ accessToken.userId,
        tokenId: /**@type {string}*/ token,
        email: null,
        subscription: null,
      };

      var userCache = null;
      // Get full user data
      return getCurrInfo()
        .then(function (user) {
          // get subscription data
          userCache = user;
          $rootScope.currentUser.email = user.email;

          // In three hours after account creation count social login as a registration.
          if (new Date() - new Date(user.created) < 3 * 60 * 60 * 1000) {
            $analytics.eventTrack('registration', { category: 'account' });
            btTagService.completeRegistration();
          }
          return btSubscriptionService.updateSubscription();
        })
        .then(function () {
          // get access data
          return lbUser.getAccessData().$promise;
        })
        .then(function (data) {
          // run general post login actions
          $analytics.eventTrack('login', { category: 'account', label: 'social' });
          return postLogin(
            userCache,
            token,
            /**@type {btSubscriptionObject}*/ $rootScope.currentUser.subscription,
            data.result
          );
        })
        .then(function () {
          btSubscriptionService.updateStatus();
          var isTutorialPassed = btShareScopeService.getTutorialStatus();
          // console.log('btAuthLoginController check is tutorial passed ', isTutorialPassed);

          // btPushNotificationService.subscribeServiceWorker();
          btWindowsSizeService.setSize();
          return isTutorialPassed;
        });
    }

    /**
     * Post login routines
     *
     * @param {btUser} user - user data
     * @param {String} accessToken - access token
     * @param {btSubscriptionObject} subscription - subscription data
     * @param {btAccessData} accessData - access data
     * @return {angular.IPromise<null>}
     */
    function postLogin(user, accessToken, subscription, accessData) {
      if (window.Sentry) Sentry.setUser({ id: user.id });

      // Save user data to root scope
      $rootScope.currentUser = {
        id: user.id,
        tokenId: accessToken,
        email: user.email,
        subscription: /**@type {ISubscription}*/ subscription,
      };

      // Save sensitive data
      btSettingsService.saveAccessData(accessData);
      btSettingsService.loadAccessData();
      btSettingsService.useAccessData();

      // Add user to mix panel and google analytics
      btTagService.setUserId(user.id);
      $analytics.setUsername(user.id);
      $analytics.setUserProperties({ $email: user.email });
      $analytics.setSuperProperties({ email: user.email });
      $analytics.eventTrack('enter', { category: 'account', label: 'login' });

      // Prepare some settings
      btShareScopeService.initAccountSettingsAndStockInstrumentsWithData(user);

      $rootScope.$broadcast('subscription:loaded', subscription);

      btDevService.alert('btAuthService: start login:success');
      btEventEmitterService.emitEvent('login:success', [$rootScope.currentUser]);
      btDevService.alert('btAuthService: finish login:success');

      $rootScope.$broadcast('subscription:updated', subscription);

      btMarketing.login(user, subscription);

      btShareScopeService.setLoginSuccess(true);
      btShareScopeService.saveLastOpenDate(btSettingsService.getClientInformation());

      // save platform information
      savePlatformInformation();

      // Save user to local cache to autologin
      window.localStorage.setItem('currentUser', JSON.stringify($rootScope.currentUser));

      // Save user image to local cache
      if (user.imageSrc) {
        window.localStorage.setItem('accountImage', user.imageSrc);
      }

      $rootScope.isFree = btSubscriptionService.isFree();
      $rootScope.isLifetime = btSubscriptionService.isLifetime();
      $rootScope.isRecurring = btSubscriptionService.isRecurring();

      // Load instruments
      return finishAuthentication()
        .then(btSubscriptionService.updateDefaultSettings)
        .then(btInstrumentsService.init)
        .then(btEventsService.init)
        .then(function () {
          console.log('First init of btInstrumentsService!');

          // var deferred = $q.defer();
          // return deferred.promise;
        });
    }

    /**
     * This function saves information about user devices
     */
    function savePlatformInformation() {
      var devices = btShareScopeService.getAccountInfoField('devices');
      console.log('devices', devices);

      var platform = window.btPlatform;

      if (devices.indexOf(platform) === -1) {
        devices.push(platform);

        if (btRestrictionService.hasFeature('personalization')) {
          if (platform === 'ios' || platform === 'android') {
            btShareScopeService.setMailChimpField('HASAPP', 1);
          }

          if (platform === 'web') {
            btShareScopeService.setMailChimpField('HASWEB', 1);
          }
        }

        btShareScopeService.setAccountInfoField('devices', devices);
        btShareScopeService.updateProfileSettingsFromFieldsList(['devices']);
      }
    }

    /**
     * Get current user profile (according to credentials)
     *
     * @return {angular.IPromise<null>}
     */
    function getCurrInfo() {
      return lbUser.getCurrent().$promise.then(function (response) {
        return response;
      });
    }

    /**
     * This function logs out user
     *
     * @return {angular.IPromise<null>|angular.IPromise<null>}
     */
    function logout() {
      $rootScope.$broadcast('logout:started');

      return btPushNotificationService
        .tryToLogout()
        .then(tryToLogout)
        .then(function () {
          btShareScopeService.stopMonitorUsage();

          toastr.clear();

          $analytics.eventTrack('logout', { category: 'account' });

          btSettingsService.logout();

          // Is it necessary? Check lbUser.logout()
          window.sessionStorage.removeItem('$LoopBack$accessTokenId');
          window.sessionStorage.removeItem('$LoopBack$currentUserId');
          window.localStorage.removeItem('$LoopBack$accessTokenId');
          window.localStorage.removeItem('$LoopBack$currentUserId');

          btMarketing.logout();
        })
        .then(_socialLogout)
        .then(function () {
          btTagService.unsetUserId();
          btEventEmitterService.emitEvent('logout:success', []);
          $rootScope.$broadcast('logout:finished');
        })
        .catch(function (error) {
          console.error('Logout failed', error);
        });
    }

    /**
     *
     * @return {any}
     */
    function tryToLogout() {
      if ($rootScope.currentUser && $rootScope.currentUser.tokenId) {
        return lbUser.logout().$promise.catch(function (reason) {
          console.error(reason);
          return $q.resolve();
        });
      } else {
        return $q.resolve();
      }
    }

    /**
     * Prepare user data
     *
     * @param {String} email - user mail
     * @param {String} password - user password
     * @param {String} name - user name
     * @param {String} phone - user phone number
     * @param {String} country - user country of residence
     * @param {String} timeZone - user tine zone
     * @return {btUser} - user object
     * @private
     */
    function _prepareUserData(email, password, name, phone, country, timeZone) {
      /**
       * Default notification rules
       * @type {btNotificationRules}
       */
      var defaultNotificationRules = {
        /* Mails */
        morningMail: false,
        eveningMail: false,
        weekStartMail: true,
        weekEndMail: false,
        /* Push Notifications */
        /* EconomicCalendar.Reminder     */ beforeRelease: true,
        /* EconomicCalendar.Release      */ afterRelease: true,
        /* EconomicCalendar.Insights     */ insights: false,
        /* TradeIdeas.NewDriven          */ tradingInsights: false,
        /* TradeIdeas.PotentialNewDriven */ expectedTradingInsights: false,
        /* TradeIdeas.PriceDriven        */ marketTradeIdeas: false,
        newTradeIdeas: false,

        /* MarketMovements.MarketSense   */ marketSense: true,
        /* MarketMovements.MarketWakeup  */ marketWakeUp: true,

        euMarketOpen: false,
        euMarketClose: false,
        usMarketOpen: false,
        usMarketClose: false,
      };

      var defaultNotifications = [
        btCodes.Notification.Enabled,
        btCodes.Notification.System.Enabled,
        btCodes.Notification.System.General,
        btCodes.Notification.System.Upgrade,
        btCodes.Notification.EconomicCalendar.Enabled,
        btCodes.Notification.EconomicCalendar.Reminder,
        btCodes.Notification.EconomicCalendar.Release,
        btCodes.Notification.MarketMovements.Enabled,
        btCodes.Notification.MarketMovements.MarketSense,
        btCodes.Notification.MarketMovements.MarketWakeup,
      ];

      var defaultAssets = {
        indices: true,
        equities: true,
        bonds: true,
        forexpairs: true,
        options: true,
        futures: true,
        commodities: true,
        other: '',
      };

      var defaultFollowingEvents = [
        1, 24, 44, 57, 65, 68, 75, 95, 99, 119, 121, 123, 128, 132, 136, 140, 150, 159, 160, 165, 166, 168, 170, 189,
        201, 204, 205, 210, 222, 258, 261, 272, 274, 294, 297, 300, 301, 320, 332, 344, 359, 370, 375, 386, 565, 1649,
        1062, 1614, 1652,
      ];

      var defaultWatchedInstruments = $rootScope.isLDS
        ? ['LLS', 'MARS', 'MIDSWT', 'WTS', 'MEH']
        : ['SPX500_USD', 'EUR_USD', 'USD_CAD', 'GBP_USD', 'BCO_USD', 'XAU_USD'];

      return {
        email: email.toLowerCase(),
        password: password,
        tutorialWasFinished: false,
        notificationRules: defaultNotificationRules,
        assets: defaultAssets,
        traderType: 'fullDay', // TODO: Fix a bug. It's object in database.
        tags: [],
        firstName: name || '',
        companyName: '',
        imageSrc: '',
        timeZone: timeZone, // TODO: Fix a bug. It's object in database.
        followingEvents: defaultFollowingEvents,
        watchedInstruments: defaultWatchedInstruments, // deprecated
        watchList: { default: defaultWatchedInstruments },
        settings: {
          notifications: defaultNotifications,
        },
        created: new Date(),
        devices: [],
        deviceAlert: false,
        telephone: phone || '',
        country: country || '',
        role: 'user',
      };
    }

    /**
     * Sign up new user
     *
     * @param {String} email - user email
     * @param {String} password - user password
     * @param {String} [name] - user name
     * @param {String} [phone] - user phone number
     * @param {String} [country] - user country of residence
     * @return {angular.IPromise<null>}
     */
    function register(email, password, name, phone, country) {
      if (name) name = name || '';
      if (phone) phone = phone || '';
      if (country) country = country || '';

      var data = _prepareUserData(email, password, name, phone, country, moment.tz.guess());

      return lbUser.create(data).$promise.then(function (data) {
        btTagService.completeRegistration();

        $analytics.setAlias(data.id);
        $analytics.eventTrack('registration', { category: 'account' });
        $analytics.setUserProperties({
          $created: new Date(),
          $email: data.email,
        });
      });
    }

    /**
     * Reset user password
     *
     * @param {String} email - user email
     * @return {angular.IPromise<null>}
     */
    function resetPassword(email) {
      return lbUser
        .resetPassword({ email: email.toLowerCase() })
        .$promise.then(function (data) {
          console.log('password was changed good', data);
        })
        .catch(function (error) {
          console.log('password change was failed', error);
        });
    }

    /**
     * Get access data from cookies
     * @return {{id, userId}}
     */
    function getAccessFromCookies() {
      var cookies = $cookies.getAll();
      var currentUserId = cookies['userId'];
      var accessTokenId = cookies['access_token'];

      currentUserId = currentUserId.split('.')[0];
      currentUserId = currentUserId.split(':')[1];

      accessTokenId = accessTokenId.split('.')[0];
      accessTokenId = accessTokenId.split(':')[1];

      return { id: accessTokenId, userId: currentUserId };
    }

    /**
     * Validate email
     * @param {string} email - user email
     * @return {boolean}
     */
    function isValidEmail(email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
      return re.test(email);
    }

    /**
     * Validate password
     * @param {string} password - user password
     * @return {boolean}
     */
    function isValidPassword(password) {
      return typeof password === 'string' && password.length > 2;
    }

    /**
     * Validate phone number
     * @param {string} text - user phone number
     * @return {string}
     */
    function validatePhoneNumber(text) {
      var phoneNumber = libphonenumber.parsePhoneNumber(text);
      if (!phoneNumber.isValid()) {
        throw new Error('INVALID_FORMAT');
      }
      return phoneNumber.number;
    }

    /**
     * This function starts external login process.
     */
    function startExternalLogin() {
      if (location.href.indexOf('bt-external-login') !== -1) {
        sessionStorage.setItem('btExternalLogin', 'bettertrader.co');
      }
    }

    /**
     * This function finishes external login process.
     * @return {angular.IPromise<*>}
     */
    function finishAuthentication() {
      if (sessionStorage.getItem('btExternalLogin')) {
        sessionStorage.removeItem('btExternalLogin');
        console.log($rootScope.currentUser);

        var base;
        if (window.isDevelopment) base = 'http://localhost:8080';
        else base = 'https://bettertrader.co';

        window.open(
          base +
            '/auth/callback.html?bt-external-login=bettertrader.co&user=' +
            $rootScope.currentUser.id +
            '&token=' +
            $rootScope.currentUser.tokenId,
          '_self'
        );

        return $q.defer().promise;
      } else {
        if (location.href.indexOf('/auth/bettertrader') !== -1) {
          window.open(location.origin, '_self');
          return $q.defer().promise;
        } else {
          return $q.resolve();
        }
      }
    }

    /**
     * Deletes user account.
     *
     * @return {angular.IPromise<*>}
     */
    function deleteAccount() {
      return lbUser.delete().$promise.then(function () {
        btToastrService.success('Your request to delete account was accepted', 'Delete Account');
      });
    }
  }
})();
