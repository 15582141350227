/**
 * Created by Sergey Panpurin on 3/29/2017.
 */

/* global Stripe, Sentry */

// @ts-check
(function btSubscriptionServiceClosure() {
  'use strict';

  var gDebug = false;
  var gSkipCheckout = false;
  var gPrefix = 'btSubscriptionService';

  angular.module('ecapp').factory('btSubscriptionService', btSubscriptionService);

  btSubscriptionService.$inject = [
    '$q',
    '$rootScope',
    '$ionicLoading',
    '$ionicPopup',
    '$analytics',
    'Whitelist',
    'btShareScopeService',
    'btSettings',
    'btBranchService',
    'btErrorService',
    '$window',
    'btInAppPurchaseService',
    'btDateService',
    'btSettingsService',
    'btTagService',
    '$state',
  ];

  /**
   * This service manipulates the subscription.
   * (btShareScopeService, btAuthService) change notification settings
   *
   * @ngdoc service
   * @name btSubscriptionService
   * @memberOf ecapp
   * @param {angular.IQService} $q - angular q service
   * @param {ecapp.ICustomRootScope} $rootScope - angular root scope
   * @param {ionic.ILoadingService} $ionicLoading - ionic loading service
   * @param {ionic.IPopupService} $ionicPopup - ionic popup service
   * @param {ext.IAnalyticsService} $analytics - analytics service
   * @param {ecapp.IGeneralLoopbackService} lbSubscription - loopback subscription
   * @param {ecapp.IShareScopeService} btShareScopeService - bt share scope service
   * @param {ecapp.ISettings} btSettings - bt settings
   * @param {ecapp.IBranchService} btBranchService - bt branch service
   * @param {ecapp.IErrorService} btErrorService - bt error service
   * @param {angular.IWindowService} $window - window object service
   * @param {ecapp.IInAppPurchaseService} btInAppPurchaseService - bt in-app purchase service
   * @param {ecapp.IDateService} btDateService - bt date services
   * @param {ecapp.ISettingsService} btSettingsService - bt settings service
   * @param {ecapp.ITagService} btTagService - bt tag service
   * @param {angular.ui.IStateService} $state - angular state service
   * @return {ecapp.ISubscriptionService} service
   */
  function btSubscriptionService(
    $q,
    $rootScope,
    $ionicLoading,
    $ionicPopup,
    $analytics,
    lbSubscription,
    btShareScopeService,
    btSettings,
    btBranchService,
    btErrorService,
    $window,
    btInAppPurchaseService,
    btDateService,
    btSettingsService,
    btTagService,
    $state
  ) {
    console.log('Running btSubscriptionService');

    if (gDebug) console.log(gPrefix, 'Provider:', $window.isIOS && $window.isApp, $window.isIOS, $window.isApp);

    // Stripe client
    var gStripe;

    // var gVersion = 5;

    // Demo trail duration in days
    var gDemoTrialPeriod = 7;

    // Payment providers
    var Provider = {
      APPLE: 'apple',
      STRIPE: 'stripe',
    };

    // Subscription categories
    var Category = {
      GENERAL: 'general',
      PLUGIN: 'plugin',
    };

    /**
     * Values for `sub` property.
     */
    var SubValue = {
      DEFAULT: 0,
      TRIAL: 1,
      SUBSCRIPTION: 2,
      SPECIAL: 3,
    };

    /**
     * Status of subscription
     *
     *  `provider` - payment provider: `stripe` or `apple` (it could be different to user subscription provider)
     *
     *  `sub` - type of subscription that used to display payments page
     *
     *  `isFree` - ???
     *
     *  `hasRealTrading` - whether the user has connected real trading
     *
     * @name ecapp.btSubscriptionService#status
     * @type {{provider: string, sub: number, isFree: boolean, hasRealTrading: boolean}}
     */
    var gStatus = {
      provider: $window.isIOS && $window.isApp ? Provider.APPLE : Provider.STRIPE,
      sub: SubValue.DEFAULT,
      isFree: false,
      hasRealTrading: false,
    };

    // Legacy Stripe handler
    // FIXME: It is deprecated
    // var gStripeHandler;

    // Cached plans
    var gPlansResponse;

    // Cached discount offers
    var gCachedOffers;

    // Cached purchases
    var gCachedPurchases;

    // Cached user purchases
    var gCachedUserPurchases;

    // Previous subscription settings. It used to implement trading benefits.
    var gPreviousSettings;

    // Activated coupon identifier
    var gCouponId = '';

    /**
     * Purchases types
     */
    var Type = {
      SUBSCRIPTIONS: 'subscriptions',
      CONSUMABLES: 'consumables',
      PLUGINS: 'plugins',
    };

    /**
     * Subscription levels.
     */
    var Level = {
      STARTER: 'starter',
      SMART: 'smart',
      PRO: 'pro',
    };

    /**
     * List of supported coupons.
     */
    var Coupon = {
      /* cspell:disable-next-line */
      INSTANT_UPGRADE: 'Ljz5pQa6',
      /* cspell:disable-next-line */
      RETURN_CLIENT: 'jpYoMybc',
    };

    /**
     * Access settings for user with trading benefits.
     *
     * @type {btSubscriptionSettingsObject}
     */
    var gTradingSettings = {
      version: 2,

      numCustomTwitter: 3,
      numTotalTwitter: 10,
      numMarketSense: 10,
      numMarketWakeup: 10,
      numWatchedSymbols: 25,
      numFollowEvents: 100,
      numMonthlyCredits: 0,

      hasApproval: true,
      hasPersonalization: true,
      hasPurchases: true,
      hasTradeIdeas: true,
      hasCustomCharts: true,
      hasVoiceAssistant: true,
      hasReleaseMagnitude: true,
      hasPerspectiveInsights: true,
      hasWeeklyMails: true,
      hasPremiumNotifications: true,
      hasBacktesting: true,
      hasCommunity: true,
      hasTradingPlatform: true,
      hasTrading: false,
      hasLevels: false,
      hasRiskMonitor: false,
      hasLiveNews: false,
      hasMarketCharacteristics: false,

      plugins: [],
    };

    // /**
    //  * Default access settings.
    //  *
    //  * @type {btSubscriptionSettingsObject}
    //  */
    // var gDefaultSettings = {
    //   version: 2,

    //   numCustomTwitter: 0,
    //   numTotalTwitter: 10,
    //   numMarketSense: 1,
    //   numMarketWakeup: 1,
    //   numWatchedSymbols: 4,
    //   numFollowEvents: 35,
    //   numMonthlyCredits: 0,

    //   hasApproval: true,
    //   hasPersonalization: true,
    //   hasPurchases: true,
    //   hasTradeIdeas: false,
    //   hasCustomCharts: false,
    //   hasVoiceAssistant: false,
    //   hasReleaseMagnitude: false,
    //   hasPerspectiveInsights: false,
    //   hasBacktesting: false,
    //   hasWeeklyMails: false,
    //   hasPremiumNotifications: false,
    //   hasCommunity: false,
    //   hasTradingPlatform: false,
    //   hasTrading: false,
    //   hasLevels: false,
    //   hasRiskMonitor: false,
    //   hasLiveNews: false,
    //   hasMarketCharacteristics: false,

    //   plugins: []
    // };

    /**
     * List of Apple monthly plans.
     */
    var gMonthlyProductIds = [
      'lite.monthly',
      'macronews.monthly',
      'tradeideas.monthly',
      'starter.monthly',
      'smart.monthly',
      'backtester.monthly',
    ];

    /**
     * List of Apple yearly plans.
     */
    var gYearlyProductIds = [
      'lite.yearly',
      'macronews.yearly',
      'tradeideas.yearly',
      'starter.yearly',
      'smart.yearly',
      'backtester.yearly',
    ];

    /**
     * Map between Stripe and Apple plans.
     */
    var gProductsConversion = {};

    if (window.isTesting) {
      /* spell-checker: disable */
      gProductsConversion = {
        plan_FEZt7cWyqrovWE: 'backtester.monthly',
        price_1NNzCNIpFN3xe6rea5Es9Nas: 'smart.monthly',
        plan_GFs7SqvhaY6WBk: 'starter.monthly',
        plan_FEZtuj03FUCVgd: 'tradeideas.monthly',
        plan_D5YyHNSmJjbVJV: 'macronews.monthly',
        plan_EbFwcuFqHYlYTl: 'lite.monthly',
        plan_FEf8cBsWEsmvqj: 'backtester.yearly', // annually
        plan_FEf81szgLw5WzO: 'smart.yearly', // annually
        plan_GFs7KcOOOR4wMr: 'starter.yearly', // annually
        plan_FEf82JVZ0wxdFq: 'tradeideas.yearly', // annually
        plan_DNEltDxjRJQgQ1: 'macronews.yearly', // annually
        plan_DNEjBqcc0ufYpo: 'lite.yearly', // annually
      };
    } else {
      gProductsConversion = {
        plan_FEZO5B2E4IvqgR: 'backtester.monthly',
        price_1NNya0IpFN3xe6re4elLKHD3: 'smart.monthly',
        plan_FEZtuj03FUCVgd: 'starter.monthly',
        plan_FEZQBOakWPs4Rm: 'tradeideas.monthly',
        plan_D7oh1KNBOIp5JY: 'macronews.monthly',
        plan_EdUWNo81QK0VTc: 'lite.monthly',
        plan_FF2f9g47CR9w5c: 'backtester.yearly', // annually
        plan_FF2fABlm2wCpQ5: 'smart.yearly', // annually
        plan_FEf82JVZ0wxdFq: 'starter.yearly', // annually
        plan_FF2ffaxmR9RKz4: 'tradeideas.yearly', // annually
        plan_DP6uGDiQiFhiTl: 'macronews.yearly', // annually
        plan_DP6s7RFLv71Ve5: 'lite.yearly', // annually
      };
      /* spell-checker: enable */
    }

    /**
     * Global payment settings.
     *
     * `provider` - payment provider
     *
     * `isAnnual` - whether annual payment is selected
     *
     * `annualDiscount` - annual discount to show
     *
     * `interval` - selected payment interval
     *
     */
    var gPaymentSettings = {
      provider: gStatus.provider,
      isAnnual: true,
      annualDiscount: 45,
      interval: 'month',
    };

    /**
     * Number of days between upgrade reminders.
     */
    var UPGRADE_REMINDER_DAYS = [0, 3, 7, 14];

    /**
     * Errors code for restoring Apple subscription.
     *
     * @name ecapp.btSubscriptionService#RestoreMessage
     * @type {{NO_PURCHASES: string, CONFLICT: string, SUCCESS: string, TOO_MANY_PURCHASES: string}}
     */
    var RestoreMessage = {
      SUCCESS: 'SUCCESS',
      NO_PURCHASES: 'NO_PURCHASES',
      TOO_MANY_PURCHASES: 'TOO_MANY_PURCHASES',
      CONFLICT: 'CONFLICT',
    };

    /**
     * Settings to convert subscription interval to text.
     */
    var IntervalText = {
      day: { single: 'daily', multiple: 'days' },
      week: { single: 'weekly', multiple: 'weeks' },
      month: { single: 'monthly', multiple: 'months' },
      year: { single: 'yearly', multiple: 'years' },
    };

    // Add listener for logout
    $rootScope.$on('logout:started', onLogoutStarted);
    $rootScope.$on('logout:finished', onLogoutFinished);

    // Run self test
    if (gDebug) {
      // getIntervalText
      console.assert(getIntervalText(undefined, undefined) === '');
      console.assert(getIntervalText(undefined, 1) === '');
      console.assert(getIntervalText('year', undefined) === '');

      console.assert(getIntervalText('day', 1) === 'daily');
      console.assert(getIntervalText('day', 3) === '(3 days)');

      console.assert(getIntervalText('week', 1) === 'weekly');
      console.assert(getIntervalText('week', 3) === '(3 weeks)');

      console.assert(getIntervalText('month', 1) === 'monthly');
      console.assert(getIntervalText('month', 3) === '(3 months)');

      console.assert(getIntervalText('year', 1) === 'yearly');
      console.assert(getIntervalText('year', 3) === '(3 years)');

      // getDecimalPrice
      console.assert(getDecimalPrice('1.10') === 1.1);
      console.assert(getDecimalPrice('11,000.00') === 11000);
    }

    return {
      status: gStatus,
      RestoreMessage: RestoreMessage,

      isFree: isFree,
      isLifetime: isLifetime,
      isRecurring: isRecurring,
      buyLifetimePro: buyLifetimePro,
      updateDefaultSettings: updateDefaultSettings,
      getCombinedSubscription: getCombinedSubscription,
      loadCombinedSubscription: loadCombinedSubscription,
      updateSubscription: updateCombinedSubscription,
      updateStatus: updateStatus,
      subscribe: deprecatedWarning(makeSubscribeRequest), // deprecated
      unsubscribe: deprecatedWarning(makeUnsubscribeRequest), // deprecated
      upgrade: deprecatedWarning(makeUpgradeRequest), // deprecated
      verifyCoupon: verifyCoupon,
      getPlans: deprecatedWarning(getPlans), // deprecated
      getPurchases: getPurchases,
      getSpecificPurchase: getSpecificPurchase,
      getUserPurchases: getUserPurchases,
      getSpecificPlans: deprecatedWarning(getSpecificPlans), // deprecated
      prepareApplePlans: deprecatedWarning(prepareApplePlans), // deprecated
      filterUpgradePlans: deprecatedWarning(filterUpgradePlans), // deprecated
      filterInAppPlans: deprecatedWarning(filterInAppPlans), // deprecated
      sortPlans: deprecatedWarning(sortPlans), // deprecated
      stripePay: deprecatedWarning(stripePay), // deprecated
      applePay: deprecatedWarning(applePay), // deprecated
      setRealTradingBenefits: setRealTradingBenefits,
      hasExpiration: hasExpiration,
      getDaysLeftObject: getDaysLeftObject,
      isUpgradeSuggested: isUpgradeSuggested,
      getName: getSubscriptionName,
      buyStripeSubscription: deprecatedWarning(buyStripeSubscription), // deprecated
      checkCanSubscribe: checkCanSubscribe,
      checkCanUnsubscribe: checkCanUnsubscribe,
      buyPurchase: buyPurchase,
      buySubscription: buySubscription,
      manageBilling: manageBilling,
      cancelSubscription: cancelSubscription,
      fixAppleSubscription: fixAppleSubscription,
      checkHasPaidSubscription: checkHasPaidSubscription,
      checkIsSpecial: checkIsSpecial,
      checkIsTrial: checkIsTrial,
      checkIsBigBrainBankFunded: checkIsBigBrainBankFunded,
      checkIsOptimusFunded: checkIsOptimusFunded,
      getPaymentSettings: getPaymentSettings,
      togglePaymentInterval: togglePaymentInterval,
      getOffers: getOffers,
      extendTrial: extendTrial,
      hasUpgradeReminder: hasUpgradeReminder,
      getHoursRemain: getHoursRemain,
      showUpgradeReminder: showUpgradeReminder,
      showBrainUpgradeReminder: showBrainUpgradeReminder,
    };

    /**
     * Returns Stripe SDK client.
     *
     * @return {*} Stripe SDK client
     */
    function getStripeClient() {
      if (!gStripe) {
        gStripe = Stripe(btSettings.BT_STRIPE_KEY);
      }

      return gStripe;
    }

    /**
     * Checks for `live` Stripe environment.
     *
     * @return {boolean} - whether used `live` Stripe environment
     */
    function isLiveStripe() {
      return btSettings.BT_STRIPE_KEY.indexOf('pk_live') === 0;
    }

    /**
     * Handles user logout start.
     */
    function onLogoutStarted() {
      gCachedOffers = undefined;
      // console.log('TEST: Logout started', gCachedOffers);
    }

    /**
     * Handles user logout finish.
     */
    function onLogoutFinished() {
      gCachedOffers = undefined;
      // console.log('TEST: Logout finished', gCachedOffers);
    }

    /**
     * Checks if the user has free plan. It means that user did not pay for access. Special
     *
     * @return {boolean} user has free plan
     */
    function isFree() {
      return !isLifetime() && !isRecurring();
    }

    /**
     * Checks if the user has a lifetime plan.
     *
     * @return {boolean} user has lifetime plan
     */
    function isLifetime() {
      var sub = getCombinedSubscription();
      return sub && sub.id.indexOf('lifetime') !== -1;
    }

    /**
     * Checks if the user has a subscription.
     *
     * @return {boolean} user has a subscription
     */
    function isRecurring() {
      return checkHasPaidSubscription() && !isLifetime();
    }

    /**
     * Returns identifier for **Lifetime Pro** pricing.
     *
     * @return {angular.IPromise<string>} price identifier.
     */
    function getLifetimeProPriceId() {
      /* cspell:disable-next-line */
      return $q.resolve(isLiveStripe() ? 'price_1IcVoFIpFN3xe6reO6mX139H' : 'price_1IcSpCIpFN3xe6renkQuyJQK');
    }

    /**
     * Redirects user to Stripe Checkout to buy lifetime pro plan.
     *
     * @return {angular.IPromise} promise that handle redirect issue
     */
    function buyLifetimePro() {
      if ($rootScope.isApp) {
        // FIXME It should works on Android
        openIntercomWindow('I want to buy Lifetime Pro');
      } else {
        var stripe = getStripeClient();

        return getLifetimeProPriceId()
          .then(function (plan) {
            return stripe.redirectToCheckout({
              lineItems: [{ price: plan, quantity: 1 }],
              mode: 'payment',
              successUrl: window.location.origin + '/#/app/payments',
              cancelUrl: window.location.origin + '/#/app/payments',
              customerEmail: btShareScopeService.getUserEmail(),
              billingAddressCollection: 'required',
            });
          })
          .then(function (result) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, you should display the localized error message to your
            // customer using `error.message`.
            if (result.error) {
              alert(result.error.message);
            }
          });
      }
    }

    /**
     * Redirects user to Stripe Checkout to buy subscription.
     *
     * @param {string} email - user email
     * @param {string} priceId - price identifier
     * @param {string} interval - price interval
     * @return {angular.IPromise} promise that handle redirect issue
     */
    function buyStripePlan(email, priceId, interval) {
      if ($rootScope.isApp) {
        // FIXME It should works on Android
        openIntercomWindow('I want to buy subscription');
      } else {
        var stripe = getStripeClient();

        return stripe
          .redirectToCheckout({
            lineItems: [{ price: priceId, quantity: 1 }],
            mode: interval === 'lifetime' ? 'payment' : 'subscription',
            successUrl: window.location.origin + '/#/app/payments',
            cancelUrl: window.location.origin + '/#/app/payments',
            customerEmail: email,
            billingAddressCollection: 'required',
          })
          .then(function (result) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, you should display the localized error message to your
            // customer using `error.message`.
            if (result.error) {
              alert(result.error.message);
            }
          });
      }
    }

    /**
     * Opens Intercom chat with specified text.
     *
     * @param {string} text - initial text
     */
    function openIntercomWindow(text) {
      if (window.intercom) {
        if (text) {
          window.intercom.displayMessageComposerWithInitialMessage(text);
        } else {
          window.intercom.displayMessageComposer();
        }
      } else if (window.Intercom) {
        if (text) {
          window.Intercom('showNewMessage', text);
        } else {
          window.Intercom('show');
        }
      } else {
        // UNEXPECTED ERROR
        console.error('Intercom is not defined');
      }
    }

    /**
     * This function tries to load Apple subscription on device.
     *
     * @alias ecapp.btSubscriptionService#fixAppleSubscription
     * @return {angular.IPromise<{code: string, message: string}>} promise
     */
    function fixAppleSubscription() {
      if (gStatus.provider === Provider.APPLE) {
        console.log('Try to fix Apple subscription');

        return btInAppPurchaseService
          .getInstance()
          .getReceipt()
          .then(function (receipt) {
            var query = {
              email: btShareScopeService.getUserEmail(),
              platform: btSettingsService.platform,
              provider: getProvider(),
              receipt: receipt,
            };

            return lbSubscription.v2UserRestore(query).$promise.catch(btErrorService.handleHTTPError);
          })
          .then(function (result) {
            return result.status;
          })
          .catch(function (reason) {
            console.error(reason);
            if (window.Sentry) Sentry.captureException(reason);
            return $q.reject(reason);
          });
      } else {
        return $q.resolve({ code: 'UNSUPPORTED', message: 'Purchases restoring in not supported for Stripe.' });
      }
    }

    /**
     * Prints deprecation message in console.
     *
     * @param {Function} wrapped - function to wrap
     * @return {Function} modified function
     */
    function deprecatedWarning(wrapped) {
      return function () {
        console.warn('[Deprecation] Function ' + wrapped.name + ' should be removed in close future.');
        return wrapped.apply(this, arguments);
      };
    }

    /**
     * @typedef {object} btAppleProductDescription
     * @property {string} id - Apple product id
     * @property {string} stripe - Stripe id of equal plan
     */

    /**
     * @typedef {object} btSubscriptionSettingsResponse
     * @property {Record<string, btSubscriptionSettingsObject>} settings - settings
     */

    /**
     * This function returns combined subscription of user.
     * It returns a locally stored subscription. If subscription was not stored undefined will be returned.
     *
     * @alias ecapp.btSubscriptionService#getCombinedSubscription
     * @return {btSubscriptionObject|undefined} - user subscription
     */
    function getCombinedSubscription() {
      if ($rootScope.currentUser && $rootScope.currentUser.subscription) {
        return $rootScope.currentUser.subscription;
      } else {
        if (window.isDevelopment) {
          console.warn(gPrefix, 'User or subscription is not defined.', $rootScope.currentUser);
        }
        return undefined;
      }
    }

    /**
     * This function returns combined subscription of user.
     * It returns a locally stored subscription. If subscription was not stored it will try to load it from server.
     *
     * @return {angular.IPromise<btSubscription>} promise
     */
    function loadCombinedSubscription() {
      if ($rootScope.currentUser && $rootScope.currentUser.subscription) {
        return $q.resolve($rootScope.currentUser.subscription);
      } else {
        return updateCombinedSubscription();
      }
    }

    /**
     * Checks subscription to update free status
     */
    function checkFree() {
      // FIXME Improve logic
      // if ($rootScope.currentUser.subscription && $rootScope.currentUser.subscription.status) {
      //   if ($rootScope.currentUser.subscription.plan === 'testing') {
      //     gStatus.isFree = false;
      //   } else {
      //     gStatus.isFree = isBadSubscription($rootScope.currentUser.subscription);
      //   }
      // } else {
      //   gStatus.isFree = true;
      // }
      //
      // if (gStatus.isFree) {
      //   $rootScope.currentUser.subscription.settings = gFreeSettings;
      // }
    }

    /**
     * fixme: improve logic
     */
    function checkSub() {
      var gSubscription = $rootScope.currentUser.subscription;

      if (gSubscription.status === 'trialing') {
        gStatus.sub = SubValue.TRIAL;
      }

      if ((gSubscription.status === 'active' || gSubscription.status === 'past_due') && gSubscription.amount) {
        gStatus.sub = SubValue.SUBSCRIPTION;
      }

      if (gSubscription.plan === 'testing') {
        gStatus.sub = SubValue.SPECIAL;
      }
    }

    /**
     * Update subscription status (local)
     * fixme: improve logic
     */
    function updateStatus() {
      checkFree();

      setRealTradingBenefits(gStatus.hasRealTrading);

      checkSub();

      if (gStatus.isFree) {
        disablePremiumNotification(btShareScopeService.getAccountSettings());
      }
    }

    /**
     * This function disable premium notifications for free users.
     *
     * @param {btUser} account - user account data
     * @return {angular.IPromise} promise
     */
    function disablePremiumNotification(account) {
      if (
        account.notificationRules.insights ||
        account.notificationRules.tradingInsights ||
        account.notificationRules.expectedTradingInsights
      ) {
        account.notificationRules.insights = false;
        account.notificationRules.tradingInsights = false;
        account.notificationRules.expectedTradingInsights = false;

        return btShareScopeService
          .updateProfileSettingsFromFieldsList(['notificationRules'])
          .then(function () {
            if (gDebug) console.log(gPrefix, 'Premium notifications turned off.');
          })
          .catch(function (reason) {
            // TODO Find solution
            console.error(gPrefix, reason);
          });
      }
    }

    // /**
    //  * This function adds price text to plans
    //  *
    //  * @param {btPlanObject[]} plans - plan objects
    //  */
    // function addPrice(plans) {
    //   plans.forEach(function (plan) {
    //     if (plan.interval === 'year') {
    //       plan.priceText = '$' + (plan.amount / 1200).toFixed(2);
    //       plan.priceTextAnnually = '$' + plan.amount / 100;
    //     } else {
    //       plan.priceText = '$' + plan.amount / 100;
    //       plan.priceTextAnnually = null;
    //     }
    //   });
    // }

    /**
     * This function prepares response to be used on Apple device.
     *
     * @alias ecapp.btSubscriptionService#prepareApplePlans
     * @param {{result: btPlanObject[], result2: btPlanObject[]}} response - original response
     * @return {angular.IPromise<{result: btPlanObject[], result2: btPlanObject[]}>} - modified response
     */
    function prepareApplePlans(response) {
      if (gStatus.provider !== Provider.APPLE) {
        return $q.resolve(response);
      }

      $ionicLoading.show();

      var promise1 = btInAppPurchaseService
        .getInstance()
        .getProducts(gYearlyProductIds)
        .catch(function (reason) {
          console.error(gPrefix, reason);
          return [];
        });

      var promise2 = btInAppPurchaseService.getInstance().getProducts(gMonthlyProductIds);

      return $q
        .all([promise1, promise2])
        .then(function (products) {
          if (gDebug) console.log(gPrefix, 'BetterTrader Products', JSON.stringify(response));
          if (gDebug) console.log(gPrefix, 'Apple Products', JSON.stringify(products));
          return modifyResponseApple(products[0].concat(products[1]), response);
        })
        .catch(function (err) {
          console.error(gPrefix, err);
          return $q.reject(err);
        })
        .finally(function () {
          $ionicLoading.hide();
        });
    }

    /**
     * This function modify plans response according to Apple products
     *
     * @param {object[]} products - Apple products
     * @param {object} response - original response
     * @return {{result: Array, result2: Array}} result
     */
    function modifyResponseApple(products, response) {
      var newResponse = { result: [], result2: [] };
      var plans = response.result.concat(response.result2);
      if (gDebug) console.log(gPrefix, 'Apple products:', products.length);
      if (gDebug) console.log(gPrefix, 'Plans:', plans.length);

      plans.forEach(modifyPlanApple.bind(null, newResponse, products));

      if (gDebug) console.log(gPrefix, 'Modified plans:', newResponse.result.length, newResponse.result2.length);
      return newResponse;
    }

    /**
     *
     * @param {*} response - ?
     * @param {*} products - ?
     * @param {*} plan - ?
     */
    function modifyPlanApple(response, products, plan) {
      var product = products.filter(function (item) {
        return getEqualAppleProduct(plan.id) === item.productId || plan.id === item.productId;
      })[0];

      if (product) {
        extendApplePurchase(plan, product);
        response.result.push(plan);
      } else {
        response.result2.push(plan);
      }
    }

    /**
     *
     * @param {*} purchase - ?
     * @param {*} product - ?
     */
    function extendApplePurchase(purchase, product) {
      purchase.productId = product.productId;
      purchase.currency = product.currency || 'XXX';
      product.priceAsDecimal = product.priceAsDecimal || getDecimalPrice(product.price);
      purchase.amount = Math.floor(product.priceAsDecimal * 100);
      if (purchase.interval === 'year') {
        var monthlyPriceAsDecimal = (product.priceAsDecimal / 12).toFixed(2);
        purchase.price = product.price;
        purchase.price_month = product.price.replace(/[\d]*[\d\s,.]+[\d]+/, monthlyPriceAsDecimal);
      } else {
        purchase.price = product.price;
        purchase.price_month = product.price;
      }
    }

    /**
     * This function returns name of Apple product equal to selected Stripe plan.
     *
     * @param {string} planId - Stripe plan id
     * @return {string} Apple product name
     */
    function getEqualAppleProduct(planId) {
      return gProductsConversion[planId];
    }

    /**
     *
     * @alias ecapp.btSubscriptionService#filterInAppPlans
     * @param {*} response
     * @return {*}
     */
    function filterInAppPlans(response) {
      return response.result;
    }

    // function filterExternalPlans(response) {
    //   return response.result2;
    // }

    /**
     *
     * @alias ecapp.btSubscriptionService#filterUpgradePlans
     * @param {btSubscriptionObject} subscription
     * @param {number} sub
     * @param {btPlanObject[]} rawPlans
     * @return {Array}
     */
    function filterUpgradePlans(subscription, sub, rawPlans) {
      var plans = [];

      rawPlans.forEach(function (plan) {
        // Skip invisible plans
        if (plan.metadata.visible !== 'true') return;

        plan.available = !(plan.interval === 'month' && plan.name.indexOf('Starter') !== -1);

        // Skip corrupted plans
        if (plan.metadata.level === undefined || plan.metadata.html === undefined) return;
        var planLevel = 0;
        var planVersion = 1;

        try {
          planLevel = parseInt(plan.metadata.level);
        } catch (e) {
          console.error(e);
        }

        try {
          planVersion = parseInt(plan.metadata.version || '1');
        } catch (e) {
          console.error(e);
        }

        if (!isBadSubscription(subscription)) {
          // skip plans lower than subscription
          if (subscription.interval === 'year') {
            if (sub !== 0 && subscription && subscription.level && subscription.level >= planLevel) return;
          } else {
            if (sub !== 0 && subscription && subscription.level && subscription.level > planLevel) return;
          }

          // skip current plan
          if (subscription.origin === Provider.STRIPE && subscription.plan === plan.id) return;
          if (subscription.origin === Provider.APPLE && subscription.plan === plan.productId) return;

          if (subscription.settings.version === 1) {
            if (planVersion > 1) return;
          } else {
            if (planVersion === 1) return;
          }
        } else {
          if (planVersion === 1) return;
        }

        plans.push(plan);
      });

      if (gDebug) console.log(gPrefix, 'Filtered products', JSON.stringify(plans));
      return plans;
    }

    /**
     *
     * @alias ecapp.btSubscriptionService#sortPlans
     * @param {Array} plans
     * @return {*}
     */
    function sortPlans(plans) {
      plans.sort(_sortByLevel);

      return plans;

      /**
       *
       * @param {*} a
       * @param {*} b
       * @return {number}
       * @private
       */
      function _sortByLevel(a, b) {
        var aLevel = parseInt(a.metadata.level);
        var bLevel = parseInt(b.metadata.level);
        if (aLevel === bLevel) {
          if (a.name === b.name) return 0;

          if (a.name > b.name) return 1;
          else return -1;
        } else {
          return aLevel - bLevel;
        }
      }
    }

    /**
     * This function checks subscription response and save
     *
     * @param {{subscription: btSubscriptionObject}} response - server response with new combined subscription
     * @return {angular.IPromise<btSubscriptionObject>}
     */
    function processSubscriptionAction(response) {
      if (response && response.subscription) {
        saveCombinedSubscription(response.subscription);

        return getUserPurchases().then(function () {
          return $q.resolve(response.subscription);
        });
      } else {
        console.error(gPrefix, 'Subscription error:', response);
        return $q.reject(response);
      }
    }

    /**
     * This function save combined subscription to local storage and send update event.
     *
     * @param {btSubscriptionObject} subscription
     */
    function saveCombinedSubscription(subscription) {
      gCachedUserPurchases = null;

      // TODO Check to prevent error. Why $rootScope.currentUser can be undefined?
      if ($rootScope.currentUser !== undefined && $rootScope.currentUser !== null) {
        $rootScope.currentUser.subscription = subscription;
        window.localStorage.setItem('currentUser', JSON.stringify($rootScope.currentUser));

        updateStatus();
        $rootScope.$broadcast('subscription:loaded', getCombinedSubscription());
        $rootScope.$broadcast('subscription:updated', getCombinedSubscription());
      } else {
        console.error(gPrefix, 'User is not defined');
      }
    }

    /**
     * This function turns on and turns off real trading benefits.
     *
     * @param {boolean} state - user true value to turn on and false to turn off
     */
    function setRealTradingBenefits(state) {
      if (state) {
        if (gStatus.hasRealTrading === false) {
          gStatus.hasRealTrading = true;
          // gStatus.isFree = false;
          gPreviousSettings = $rootScope.currentUser.subscription.settings;
          $rootScope.currentUser.subscription.settings = gTradingSettings;
        }
      } else {
        gStatus.hasRealTrading = false;
        if (gPreviousSettings) {
          $rootScope.currentUser.subscription.settings = gPreviousSettings;
        }
      }
    }

    /**
     * This function return specific plans.
     *
     * @param {string} name - bettertrader plan name
     * @param {string} interval - interval: year or month
     * @param {string} type - internal or external
     * @return {angular.IPromise<any>}
     */
    function getSpecificPlans(name, interval, type) {
      return getPlans().then(function (data) {
        var internal = data.result.filter(function (plan) {
          // fixme: plan.metadata.name (check plan.name)
          return plan.metadata.name === name && plan.interval === interval;
        });

        var external = data.result2.filter(function (plan) {
          // fixme: plan.metadata.name (check plan.name)
          return plan.metadata.name === name && plan.interval === interval;
        });

        if (type === 'internal') return internal;
        if (type === 'external') return external;
        return [];
      });
    }

    /**
     * Support function onFail
     *
     * @param {*} reason - rejection reason
     * @return {angular.IPromise<*>}
     */
    function printError(reason) {
      console.error(gPrefix, reason);
      return $q.reject(reason);
    }

    // /**
    //  * Buy a new subscription via Stripe. If user already have a subscription use upgrade function.
    //  *
    //  * @deprecated
    //  * @param {object} plan - name of subscription plan
    //  * @param {string} email - user email
    //  * @param {string} couponId - coupon id
    //  * @param {Function} onStart - call this callback before opening Stripe popup
    //  * @param {Function} onEnd - call this callback during closing of Stripe popup
    //  * @param {Function} onToken - call on token received
    //  * @param {Function} onConfirm - call if user already have subscription and we need just to on confirm callback
    //  */
    // function stripePayLegacy(plan, email, couponId, onStart, onEnd, onToken, onConfirm) {
    //   void (couponId);

    //   if (checkHasPaymentInformation()) {
    //     onConfirm();
    //     return;
    //   }

    //   if (!onStart()) return;

    //   $ionicLoading.show();

    //   btTagService.intendPurchase(plan.name);
    //   $analytics.eventTrack('intend', { category: 'purchases', label: plan.name.toLowerCase() });

    //   if ($window.StripeCheckout) {
    //     openStripePopup();
    //   } else {
    //     var script = document.createElement('script');
    //     script.src = 'https://checkout.stripe.com/checkout.js';
    //     document.body.appendChild(script);
    //     script.onload = openStripePopup;
    //   }

    //   /**
    //    *
    //    */
    //   function openStripePopup() {
    //     if (gDebug) console.log(gPrefix, 'StripeCheckout loaded');

    //     if (gStripeHandler === undefined) {
    //       var imgUrl = 'https://bettertrader.co/images/fab.png';
    //       if ($rootScope.isBigBrainBank) {
    //         imgUrl = 'https://thebrain.bigbrainbank.org/images/logo/bigbrainbank/app-logo-256x256.png';
    //       }

    //       gStripeHandler = $window.StripeCheckout.configure({
    //         key: btSettings.BT_STRIPE_KEY,
    //         image: imgUrl
    //       });
    //     }

    //     var config = {
    //       name: plan.name + ' ' + getPlanInterval(plan, true),
    //       amount: calculateTotalAmount(plan),
    //       email: email,
    //       panelLabel: 'Subscribe for {{amount}}',
    //       opened: onOpen,
    //       closed: onEndWrapper,
    //       token: onToken
    //     };

    //     // Add billing address information
    //     if (btSettings.BT_STRIPE_ADDRESS !== undefined && btSettings.BT_STRIPE_ADDRESS === '1') {
    //       config.zipCode = true;
    //       config.billingAddress = true;
    //     }

    //     var res = gStripeHandler.open(config);
    //     if (gDebug) console.log(gPrefix, res);

    //     /**
    //      *
    //      * @param {*} data
    //      */
    //     function onOpen(data) {
    //       if (gDebug) console.log(gPrefix, data);
    //       $ionicLoading.hide();
    //     }

    //     /**
    //      *
    //      * @param {*} data
    //      */
    //     function onEndWrapper(data) {
    //       if (gDebug) console.log(gPrefix, data);
    //       $ionicLoading.hide();
    //       onEnd();
    //     }
    //   }
    // }

    /**
     * Buy a new subscription via Stripe. If user already have a subscription use upgrade function.
     *
     * @param {object} plan - name of subscription plan
     * @param {string} email - user email
     * @param {string} couponId - coupon id
     * @param {Function} onStart - call this callback before opening Stripe popup
     * @param {Function} onEnd - (deprecated) call this callback during closing of Stripe popup
     * @param {Function} onToken - (deprecated) call on token received
     * @param {Function} onConfirm - call if user already have subscription and we need just to on confirm callback
     */
    function stripePay(plan, email, couponId, onStart, onEnd, onToken, onConfirm) {
      void couponId;

      if (checkHasPaymentInformation()) {
        onConfirm();
        return;
      }

      if (!onStart()) return;

      $ionicLoading.show('Open Stripe Checkout');

      btTagService.intendPurchase(plan.name);
      $analytics.eventTrack('intend', { category: 'purchases', label: plan.name.toLowerCase() });

      buyStripePlan(email, plan.id, plan.interval);
    }

    // /**
    //  * Calculates total price to pay.
    //  *
    //  * @param {btPlanObject} plan - plan object
    //  * @return {number} - total price
    //  */
    // function calculateTotalAmount(plan) {
    //   if (plan.tax) {
    //     if (plan.tax.inclusive) {
    //       return plan.amount;
    //     } else {
    //       return plan.amount * (plan.tax.percent / 100 + 1);
    //     }
    //   } else {
    //     return plan.amount;
    //   }
    // }

    /**
     * Buy Apple subscription.
     *
     * @alias ecapp.btSubscriptionService#applePay
     * @param {btPlanObject} plan - plan object
     * @param {string} coupon - coupon identifier
     * @return {angular.IPromise<string>}
     */
    function applePay(plan, coupon) {
      void coupon;

      if (plan.productId === undefined) {
        return $q.reject(new Error('Something went wrong: Apple subscription is not defined'));
      }

      $ionicLoading.show();
      try {
        btTagService.intendPurchase(plan.name);
        $analytics.eventTrack('intend', { category: 'purchases', label: plan.name.toLowerCase() });

        return btInAppPurchaseService
          .getInstance()
          .subscribe(plan.productId)
          .then(subscribeWrapper)
          .then(function () {
            $rootScope.dataChanged = true;
            return 'success';
          })
          .catch(function (err) {
            if (err.message === 'Something went wrong: Can not subscribe user.') {
              return $q.reject(err);
            } else {
              console.error(gPrefix, JSON.stringify(err));
              var message = err.errorMessage ? ' (' + err.errorMessage + ')' : '';
              return $q.reject(new Error('Something went wrong: Purchase validation problem' + message));
            }
          })
          .finally(function () {
            $ionicLoading.hide();
          });
      } catch (e) {
        $ionicLoading.hide();
        return $q.reject(e);
      }

      /**
       *
       * @param {*} data
       * @return {angular.IPromise<any>}
       */
      function subscribeWrapper(data) {
        data.amount = plan.amount;
        data.currency = plan.currency;
        return makeSubscribeRequest(plan.productId, JSON.stringify(data), null).catch(function (reason) {
          console.error(gPrefix, reason);
          return $q.reject(new Error('Something went wrong: Can not subscribe user.'));
        });
      }
    }

    /**
     * This function buys or upgrades Stripe subscription.
     *
     * @param {btPlanObject} plan - plan object
     * @param {string} [coupon] - coupon identifier
     * @return {angular.IPromise<string>} execution status 'closed' or 'success'
     */
    function buyStripeSubscription(plan, coupon) {
      coupon = coupon || '';

      var deferred = $q.defer();
      var isPaymentProcessing = false;

      stripePay(plan, btShareScopeService.getUserEmail(), coupon, onStart, onEnd, onToken, onConfirm);

      return deferred.promise;

      /**
       * This function launched on start.
       *
       * @return {*}
       */
      function onStart() {
        if (gDebug) console.log(gPrefix, 'payment was started!');

        if (gSkipCheckout) {
          simulatePurchaseCheckout(plan, coupon)
            .then(function (value) {
              deferred.resolve(value);
            })
            .catch(function (reason) {
              deferred.reject(reason);
            });
          return false;
        } else {
          return true;
        }
      }

      /**
       * This function called if user already has a payment information.
       */
      function onConfirm() {
        if (gDebug) console.log(gPrefix, 'payment was confirmed!');
        var previous = getPreviousSubscription(plan);
        var promise = previous ? tryUpgrade(previous, plan, coupon) : trySubscribe(plan, coupon);

        promise
          .then(function (value) {
            deferred.resolve(value);
          })
          .catch(function (reason) {
            deferred.reject(reason);
          });
      }

      /**
       * This function called on payment finish.
       */
      function onEnd() {
        if (gDebug) console.log(gPrefix, 'payment was ended!');
        if (!isPaymentProcessing) {
          deferred.resolve('closed');
          deferred = null;
        }
      }

      /**
       * This function called after receiving payment token.
       * @param {Stripe.tokens.IToken} token
       */
      function onToken(token) {
        if (gDebug) console.log(gPrefix, 'payment was completed!');
        isPaymentProcessing = true;
        makeSubscribeRequest(plan.id, token.id, coupon)
          .then(processNewSubscriptionSuccess)
          .catch(processNewSubscriptionFail);
      }

      /**
       * This function.
       *
       * @param {*} result
       */
      function processNewSubscriptionSuccess(result) {
        if (gDebug) console.log(gPrefix, 'subscription updated!', result);
        isPaymentProcessing = false;
        if (result && result.subscription) {
          console.error(gPrefix, 'Error during subscription update:', result);
          deferred.reject(result);
          deferred = null;
        } else {
          updateCombinedSubscription();

          deferred.resolve('success');
          deferred = null;
        }
      }

      /**
       *
       * @param {*} error
       */
      function processNewSubscriptionFail(error) {
        if (gDebug) console.log(gPrefix, 'subscription update failed!');
        isPaymentProcessing = false;
        console.error(gPrefix, 'Error during subscription update:', error);
        deferred.reject(error);
        deferred = null;
      }
    }

    /**
     * This buys or upgrades Apple subscription.
     *
     * @param {btPlanObject} plan - plan object
     * @param {string} [coupon] - coupon id
     * @return {angular.IPromise<string>} execution status 'closed' or 'success'
     */
    function buyAppleSubscription(plan, coupon) {
      if (gSkipCheckout) return simulatePurchaseCheckout(plan, coupon);

      return applePay(plan, coupon);
    }

    /**
     *
     * @param {btPlanObject} purchase
     */
    function buyPurchase(purchase) {
      alert('Coming soon');
      if (gDebug) console.log(gPrefix, 'Buy Purchase:', purchase);
    }

    /**
     * This function buys or upgrades subscription.
     *
     * @alias ecapp.btSubscriptionService#buySubscription
     * @param {btPlanObject} plan -
     * @param {boolean} [skipSuggestion] -
     * @return {angular.IPromise<string>} execution status 'closed' or 'success'
     */
    function buySubscription(plan, skipSuggestion) {
      skipSuggestion = skipSuggestion === undefined ? false : !!skipSuggestion;
      return buySubscriptionWrapper(plan)
        .then(function (status) {
          if (skipSuggestion || btSettingsService.getDomain() === 'bigbrainbank') {
            return status;
          } else {
            return suggestInstantUpgrade(plan, status);
          }
        })
        .catch(function (reason) {
          if (window.Sentry) Sentry.captureException(reason);
          return $q.reject(reason);
        });
    }

    /**
     * This function buys or upgrades subscription.
     *
     * @alias ecapp.btSubscriptionService#buySubscription
     * @param {btPlanObject} plan
     * @return {angular.IPromise<string>} execution status 'closed' or 'success'
     */
    function buySubscriptionWrapper(plan) {
      if (checkCanSubscribe(plan)) {
        if (getProvider() === Provider.STRIPE) return buyStripeSubscription(plan, getCouponId());
        if (getProvider() === Provider.APPLE) return buyAppleSubscription(plan, getCouponId());
        return $q.reject(new Error('Unknown provider.'));
      } else {
        return $q.reject(new Error("You can't subscribe to this plan due to you already have similar subscription."));
      }
    }

    /**
     *
     * @param {btPlanObject} plan
     * @param {string} status - closed or success
     * @return {*}
     */
    function suggestInstantUpgrade(plan, status) {
      if (status === 'success' && getProvider() === Provider.STRIPE && !getCouponId()) {
        $analytics.eventTrack('shown', { category: 'offer', label: 'instant' });

        return showUpgradeSuggestion(plan, true).then(function (upgrade) {
          if (!!upgrade) {
            $analytics.eventTrack('confirmed', { category: 'offer', label: 'instant' });
            setCouponId(Coupon.INSTANT_UPGRADE);
            return buySubscription(upgrade, true).then(function (value) {
              if (value === 'success') $analytics.eventTrack('applied', { category: 'offer', label: 'instant' });
              return value;
            });
          } else {
            return status;
          }
        });
      } else {
        return $q.resolve(status);
      }
    }

    /**
     * @alias ecapp.btSubscriptionService#hasUpgradeReminder
     * @return {*}
     */
    function hasUpgradeReminder() {
      if (btSettingsService.getDomain() === 'bigbrainbank') {
        return checkIsBrainLimited();
      } else {
        return (
          getProvider() === Provider.STRIPE &&
          checkHasPaidSubscription() &&
          checkIsProvider(Provider.STRIPE) &&
          !(checkIsPro() && checkIsYearly()) &&
          (gSkipCheckout || UPGRADE_REMINDER_DAYS.indexOf(Math.floor(getDaysSinceCreation())) !== -1)
        );
      }
    }

    /**
     * @alias ecapp.btSubscriptionService#getHoursRemain
     * @return {Date}
     */
    function getHoursRemain() {
      var subscription = getCombinedSubscription();
      return !!subscription && subscription.created
        ? new Date(subscription.created * 1000 + (Math.floor(getDaysSinceCreation()) + 1) * btDateService.DAY)
        : new Date();
    }

    /**
     * @alias ecapp.btSubscriptionService#showUpgradeReminder
     * @return {angular.IPromise<string>}
     */
    function showUpgradeReminder() {
      var subscription = getCombinedSubscription();
      $analytics.eventTrack('shown', { category: 'offer', label: 'reminder' });

      return showUpgradeSuggestion(subscription, false).then(function (upgrade) {
        if (!!upgrade) {
          $analytics.eventTrack('confirmed', { category: 'offer', label: 'reminder' });
          setCouponId(Coupon.INSTANT_UPGRADE);
          return buySubscription(upgrade, true).then(function (value) {
            if (value === 'success') $analytics.eventTrack('applied', { category: 'offer', label: 'reminder' });
            return value;
          });
        } else {
          return 'closed';
        }
      });
    }

    /**
     * @alias ecapp.btSubscriptionService#showBrainUpgradeReminder
     * @return {angular.IPromise<boolean>}
     */
    function showBrainUpgradeReminder() {
      return $ionicPopup
        .show({
          // title: (isInstant ? 'Confirmation & Offer' : 'Limited Time Offer'),
          cssClass: 'bt-offer-popup',
          subTitle: '',
          template:
            '<div>' + '    <div class="header">Enjoy Pro Access at $50 per Month ($300 Annually)</div>' + '</div>',
          scope: null,
          buttons: [
            {
              text: 'Upgrade',
              type: 'button-positive',
              onTap: function (e) {
                void e;
                return true;
              },
            },
            {
              text: 'Not now',
              type: 'button-default',
              onTap: function (e) {
                void e;
                return false;
              },
            },
          ],
        })
        .then(function (upgrade) {
          if (!!upgrade) {
            $state.go('ecapp.app.payments');
            return 'success';
          } else {
            return 'closed';
          }
        });
    }

    /**
     *
     * @param {btPlanObject|btSubscriptionObject} obj -
     * @param {boolean} isInstant -
     * @return {angular.IPromise<btPlanObject>}
     */
    function showUpgradeSuggestion(obj, isInstant) {
      if (isMonthlyPlan(obj)) {
        return getSpecificPurchase(Type.SUBSCRIPTIONS, obj.name, 365).then(function (upgrade) {
          if (!!upgrade) return showYearlyUpgradeSuggestion(obj, upgrade, isInstant);
          else return $q.reject(new Error('(Yearly) Upgrade plan was not found. Please contact support team.'));
        });
      } else if (!isProPlan(obj)) {
        return getSpecificPurchase(Type.SUBSCRIPTIONS, Level.PRO, 365).then(function (upgrade) {
          if (!!upgrade) return showProUpgradeSuggestion(obj, upgrade, isInstant);
          else return $q.reject(new Error('(Pro Yearly) Upgrade plan was not found. Please contact support team.'));
        });
      } else {
        return $q.resolve(undefined);
      }
    }

    /**
     *
     * @param {btPlanObject|btSubscriptionObject} plan -
     * @param {btPlanObject} upgrade -
     * @param {boolean} isInstant -
     * @return {*}
     */
    function showYearlyUpgradeSuggestion(plan, upgrade, isInstant) {
      // var charge = ((upgrade.amount - plan.amount) / 100).toFixed(2);
      var current = plan.name + ' ' + plan.interval_text;
      // var next = upgrade.name + ' ' + upgrade.interval_text;
      var discount = 50;
      var current_monthly = (plan.amount_month / 100).toFixed(2);
      var next_monthly = (upgrade.amount_month / 100).toFixed(2);
      var saving = (((plan.amount_month - upgrade.amount_month) * 12) / 100).toFixed(2);
      var total = (((plan.amount_month - upgrade.amount_month) * 12) / 100 + 50).toFixed(2);

      return $ionicPopup.show({
        title: isInstant ? 'Confirmation & Offer' : 'Limited Time Offer',
        cssClass: 'bt-offer-popup',
        subTitle: 'An offer to pay less',
        template:
          '<div>' +
          (isInstant ? '<div>Your "' + current + '" plan has just been activated, congratulations.</div><br>' : '') +
          '<div class="header">Save $' +
          total +
          '!</div>' +
          '<br>' +
          '<div>By upgrading to the yearly plan you will be reducing your average monthly cost from ' +
          '$' +
          current_monthly +
          ' to $' +
          next_monthly +
          ' ($' +
          saving +
          ' saving for a year) plus an ' +
          'additional $' +
          discount +
          ' OFF - total savings of $' +
          total +
          ' with 1 decision.</div>' +
          '<br>' +
          '<div>This is a Limited time offer *:<br>' +
          'By upgrading now you will get a $' +
          discount +
          ' discount.</div>' +
          '<br>' +
          '<div class="small">* This offer will not be available after closing this message box.</div>' +
          '</div>',
        scope: null,
        buttons: [
          {
            text: 'No',
            type: 'button-default',
            onTap: function (e) {
              void e;
              return null;
            },
          },
          {
            text: 'Upgrade',
            type: 'button-positive',
            onTap: function (e) {
              void e;
              return upgrade;
            },
          },
        ],
      });
    }

    /**
     *
     * @param {btPlanObject|btSubscriptionObject} plan -
     * @param {btPlanObject} upgrade -
     * @param {boolean} isInstant -
     * @return {*}
     */
    function showProUpgradeSuggestion(plan, upgrade, isInstant) {
      var charge = ((upgrade.amount - plan.amount) / 100).toFixed(2);
      var current = plan.name + ' ' + plan.interval_text;
      // var next = upgrade.name + ' ' + upgrade.interval_text;
      var discount = 50;

      return $ionicPopup.show({
        title: isInstant ? 'Confirmation & Offer' : 'Limited Time Offer',
        cssClass: 'bt-offer-popup',
        subTitle: '',
        template:
          '<div>' +
          (isInstant ? '<div>Your "' + current + '" plan has just been activated, congratulations.</div><br>' : '') +
          '<div class="header">Upgrade now to the pro plan with $' +
          discount +
          ' OFF</div>' +
          '<br>' +
          '<div>Unlock the full power of BetterTrader and gain superior analysis with the Risk-On/Risk-Off monitor ' +
          'and the BackTester - All this for an additional $' +
          charge +
          ' ($' +
          discount +
          ' discount ' +
          'automatically applied when using this pop-up)</div>' +
          '<br>' +
          '<div>This is a Limited time offer *:<br>' +
          'By upgrading now you will get a $' +
          discount +
          ' discount.</div>' +
          '<br>' +
          '<div class="small">* This offer will not be available after closing this message box.</div>' +
          '</div>',
        scope: null,
        buttons: [
          {
            text: 'No',
            type: 'button-default',
            onTap: function (e) {
              void e;
              return null;
            },
          },
          {
            text: 'Upgrade',
            type: 'button-positive',
            onTap: function (e) {
              void e;
              return upgrade;
            },
          },
        ],
      });
    }

    /**
     * Simulates purchase during testing.
     *
     * @param {btPlanObject} plan - subscription
     * @param {string} coupon - coupon
     * @return {angular.IPromise} promise
     */
    function simulatePurchaseCheckout(plan, coupon) {
      return $ionicPopup.show({
        title: 'Purchase Simulation',
        cssClass: '',
        template:
          '<div>' +
          '<div>Plan: ' +
          plan.name +
          ' ' +
          plan.interval_text +
          '</div>' +
          '<div>Coupon: ' +
          (coupon || 'N/A') +
          '</div>' +
          '</div>',
        scope: null,
        buttons: [
          {
            text: 'Cancel',
            type: 'button-default',
            onTap: function () {
              return 'closed';
            },
          },
          {
            text: 'Pay',
            type: 'button-positive',
            onTap: function () {
              return 'success';
            },
          },
        ],
      });
    }

    /**
     *
     * @param {btSubscriptionObject} newSubscription
     * @param {btSubscriptionObject} oldSubscription
     * @return {angular.IPromise<{btPlanObject}>}
     */
    function suggestReturnUpgrade(newSubscription, oldSubscription) {
      if (getProvider() === Provider.STRIPE && btSettingsService.getDomain() !== 'bigbrainbank') {
        return getSpecificPurchase(Type.SUBSCRIPTIONS, oldSubscription.name, oldSubscription.interval_days)
          .then(function (upgrade) {
            if (!!upgrade) {
              $analytics.eventTrack('shown', { category: 'offer', label: 'return' });
              return showReturnUpgrade(upgrade);
            } else {
              return null;
            }
          })
          .then(function (upgrade) {
            if (!!upgrade) {
              $analytics.eventTrack('confirmed', { category: 'offer', label: 'return' });
              setCouponId(Coupon.RETURN_CLIENT);
              return buySubscription(upgrade, true).then(function (value) {
                if (value === 'success') $analytics.eventTrack('applied', { category: 'offer', label: 'return' });
                return value;
              });
            } else {
              return newSubscription;
            }
          });
      } else {
        return $q.resolve(newSubscription);
      }
    }

    /**
     *
     * @param {btPlanObject} upgrade -
     * @return {*}
     */
    function showReturnUpgrade(upgrade) {
      var discount = 50;
      var period = '3 months';

      return $ionicPopup.show({
        title: 'Unsubscribe Confirmation & Offer',
        cssClass: 'bt-offer-popup',
        subTitle: 'An offer to pay less',
        template:
          '<div>' +
          '<div>Your unsubscribe request has been confirmed but <b>hold&nbsp;on</b>! We would like you to get ' +
          'some more experience with our app because we believe it could really help you.</div>' +
          '<br>' +
          '<div class="header">Get ' +
          period +
          ' of your current plan with a ' +
          discount +
          '% discount.</div>' +
          '<br>' +
          '<div>Just press upgrade and the discount will be applied automatically.</div>' +
          '<br>' +
          '<div>This is a Limited time offer and will not be available after closing this message box.</div>',
        scope: null,
        buttons: [
          {
            text: 'Skip',
            type: 'button-default',
            onTap: function (e) {
              void e;
              return null;
            },
          },
          {
            text: 'Feedback',
            type: 'button-balanced',
            onTap: function (e) {
              if (window.Intercom) window.Intercom('showNewMessage', 'I canceled me BetterTrader subscription due to ');
              e.preventDefault();
            },
          },
          {
            text: 'Upgrade',
            type: 'button-positive',
            onTap: function (e) {
              void e;
              return upgrade;
            },
          },
        ],
      });
    }

    /**
     *
     * @param {btPlanObject|btSubscriptionObject} plan
     * @return {boolean}
     */
    function isMonthlyPlan(plan) {
      return plan.interval === 'month';
    }

    // /**
    //  *
    //  * @param {btPlanObject} plan
    //  * @return {boolean}
    //  */
    // function isYearlyPlan(plan) {
    //   return plan.interval === 'year';
    // }

    // /**
    //  *
    //  * @param {btPlanObject} plan
    //  * @return {boolean}
    //  */
    // function isStarterPlan(plan) {
    //   return plan.alias === 'starter';
    // }
    //
    // /**
    //  *
    //  * @param {btPlanObject} plan
    //  * @return {boolean}
    //  */
    // function isSmartPlan(plan) {
    //   return plan.alias === 'smart';
    // }

    /**
     *
     * @param {btPlanObject|btSubscriptionObject} obj
     * @return {boolean}
     */
    function isProPlan(obj) {
      console.log('TEST')
      return obj.alias === 'pro';
    }

    /**
     * @alias ecapp.btSubscriptionService#manageBilling
     * @return {angular.IPromise<{url: string}>}
     */
    function manageBilling() {
      return makeManageBillingRequest();
    }

    /**
     * @alias ecapp.btSubscriptionService#cancelSubscription
     * @param {btPlanObject} subscription
     * @return {*}
     */
    function cancelSubscription(subscription) {
      return makeUnsubscribeRequest(subscription.id);
    }

    /**
     * This function show upgrade confirmation popup.
     *
     * @param {btPlanObject} plan - subscription plan object
     * @return {angular.IPromise<boolean>}
     */
    function showUpgradeConfirmation(plan) {
      var scope = $rootScope.$new(true);

      var s = getPreviousSubscription(plan);
      scope.current = getSubscriptionName(s) + ' ($' + (s.amount / 100).toFixed(2) + '/' + s.interval + ')';
      scope.upgrade =
        plan.name + ' ' + plan.interval_text + ' ($' + (plan.amount / 100).toFixed(2) + '/' + plan.interval + ')';

      var taxInfo = '';
      if (plan.tax) {
        if (plan.tax.inclusive) {
          taxInfo = '<br>' + plan.tax.name + ' ' + plan.tax.percent + '% included';
        } else {
          taxInfo = '<br>+ ' + plan.tax.name + ' ' + plan.tax.percent + '%';
        }
      }

      var data = {
        title: plan.name,
        okText: 'Upgrade',
        scope: scope,
        template: 'Do you want to upgrade your subscription from <b>{{current}}</b> to <b>{{upgrade}}</b>?' + taxInfo,
      };

      return $q.all([$ionicPopup.confirm(data)]).then(function (result) {
        if (result[0]) {
          if (gDebug) console.log(gPrefix, result[0]);
          return true;
        } else {
          return false;
        }
      });
    }

    /**
     * This function shows subscription confirmation popup.
     *
     * @param {btPlanObject} plan - subscription plan object
     * @return {angular.IPromise<boolean>}
     */
    function showSubscribeConfirmation(plan) {
      var scope = $rootScope.$new(true);

      scope.subscription = plan.name + ' ($' + (plan.amount / 100).toFixed(2) + '/' + plan.interval + ')';

      var data = {
        title: plan.name,
        okText: 'Subscribe',
        scope: scope,
        template: 'Do you want to subscribe to <b>{{subscription}}</b>?',
      };

      return $q.all([$ionicPopup.confirm(data)]).then(function (result) {
        if (result[0]) {
          if (gDebug) console.log(gPrefix, result[0]);
          return true;
        } else {
          return false;
        }
      });
    }

    /**
     * This function saves coupon identifier in memory.
     *
     * @param {string} coupon - coupon identifier
     */
    function setCouponId(coupon) {
      gCouponId = coupon;
    }

    /**
     * This function returns saved coupon identifier.
     *
     * @return {string} - saved coupon identifier
     */
    function getCouponId() {
      return gCouponId;
    }

    // ---- ----

    /**
     * Asks user to confirm subscription upgrade.
     *
     * @param {btSubscriptionObject} current - current user subscription
     * @param {btPlanObject} plan - next user subscription
     * @param {string} coupon - coupon identifier
     * @return {angular.IPromise<any>} promise
     */
    function tryUpgrade(current, plan, coupon) {
      void current;

      return showUpgradeConfirmation(plan)
        .then(function (isConfirmed) {
          if (isConfirmed) return upgradeSubscription(plan, coupon);
          else return $q.resolve('closed');
        })
        .catch(function (reason) {
          console.error(gPrefix, reason);
          return $q.reject(reason);
        });
    }

    /**
     *
     * @param {btPlanObject} plan
     * @param {string} coupon
     * @return {angular.IPromise<any>}
     */
    function trySubscribe(plan, coupon) {
      return showSubscribeConfirmation(plan)
        .then(function (isConfirmed) {
          if (isConfirmed) return addSubscription(plan, coupon);
          else return $q.resolve('closed');
        })
        .catch(function (reason) {
          console.error(gPrefix, reason);
          return $q.reject(reason);
        });
    }

    /**
     *
     * fixme: ?
     * @param {btPlanObject} plan
     * @param {string} coupon
     * @return {angular.IPromise<any>}
     */
    function upgradeSubscription(plan, coupon) {
      if (gSkipCheckout) return simulatePurchaseCheckout(plan, coupon);

      var subscription = getPreviousSubscription(plan);
      return makeUpgradeRequest(subscription.id, plan.id, coupon)
        .then(function (subscription) {
          if (subscription) return $q.resolve('success');
          else return $q.reject(new Error('No subscription'));
        })
        .catch(function (err) {
          console.error(gPrefix, 'Subscription update error:', err);
          return $q.reject(err);
        });
    }

    /**
     *
     * fixme: ?
     * @param {btPlanObject} plan
     * @param {string} coupon
     * @return {angular.IPromise<any>}
     */
    function addSubscription(plan, coupon) {
      if (gSkipCheckout) return simulatePurchaseCheckout(plan, coupon);

      return makeSubscribeRequest(plan.id, null, coupon)
        .then(function (subscription) {
          if (subscription) {
            return $q.resolve('success');
          } else {
            return $q.reject(new Error('No subscription'));
          }
        })
        .catch(function (err) {
          console.error(gPrefix, 'Subscription update error:', err);
          return $q.reject(err);
        });
    }

    // ---- Backend Methods ----

    /**
     * This function updates default settings of subscription service.
     * @return {angular.IPromise<*>}
     */
    function updateDefaultSettings() {
      var query = {
        platform: btSettingsService.platform,
        provider: getProvider(),
      };

      return lbSubscription
        .v2Settings(query)
        .$promise.catch(btErrorService.handleHTTPError)
        .then(parseDefaultSettings)
        .catch(function (reason) {
          console.error(reason);
        });

      /**
       *
       * @param {btSubscriptionSettingsResponse} res
       */
      function parseDefaultSettings(res) {
        if (res.settings) {
          // if (res.settings.default) gDefaultSettings = res.settings.default;
          if (res.settings.trading) gTradingSettings = res.settings.trading;
        }

        // if (res.iosProducts) {
        //   gMonthlyProductIds = res.iosProducts.filter(function (product) {
        //     return product.id.indexOf('monthly') !== -1;
        //   }).map(function (product) {
        //     return product.id;
        //   });
        //   gYearlyProductIds = res.iosProducts.filter(function (product) {
        //     return product.id.indexOf('yearly') !== -1;
        //   }).map(function (product) {
        //     return product.id;
        //   });
        //   gProductsConversion = {};
        //   res.iosProducts.forEach(function (product) {
        //     gProductsConversion[product.stripe] = product.id;
        //   });
        // }
      }
    }

    /**
     * Load subscription information from server.
     *
     * @alias ecapp.btSubscriptionService#updateSubscription
     * @return {angular.IPromise<btSubscriptionObject>}
     */
    function updateCombinedSubscription() {
      $analytics.setSuperProperties({ email: btShareScopeService.getUserEmail() });

      return makeUserRequest()
        .then(function (response) {
          return getUserPurchases().then(function () {
            return response.subscription;
          });
        })
        .catch(function (reason) {
          console.error(gPrefix, "Couldn't get subscription", reason);
          return $q.reject(reason);
        });
    }

    /**
     * This function returns personal and general offers.
     *
     * @alias ecapp.btSubscriptionService#getOffers
     * @return {angular.IPromise<Array>}
     */
    function getOffers() {
      if (gCachedOffers) {
        return $q.resolve(gCachedOffers);
      } else {
        var query = {
          email: btShareScopeService.getUserEmail(),
          platform: btSettingsService.platform,
          provider: getProvider(),
        };

        return lbSubscription
          .v2Offers(query)
          .$promise.catch(btErrorService.handleHTTPError)
          .then(function (response) {
            if (response && response.offers) {
              if (gDebug) console.log(gPrefix, 'Got offers ', response.offers);
              gCachedOffers = response.offers;
              return gCachedOffers;
            } else {
              console.error(gPrefix, "Couldn't get offers", response);
              return $q.reject(
                new btErrorService.ServerError(
                  'HTTP_ERROR',
                  response.status,
                  'Server error',
                  "Can't load offers from server."
                )
              );
            }
          });
      }
    }

    /**
     * This function tries to extend user trial.
     *
     * @alias ecapp.btSubscriptionService#extendTrial
     * @return {angular.IPromise<boolean>}
     */
    function extendTrial() {
      var query = {
        email: btShareScopeService.getUserEmail(),
        platform: btSettingsService.platform,
        provider: getProvider(),
        modification: 'trial-extension',
        params: {},
      };

      return lbSubscription
        .v2Modify(query)
        .$promise.catch(btErrorService.handleHTTPError)
        .then(function (response) {
          if (response && response.modified) {
            if (gDebug) console.log(gPrefix, 'Trial was extended');
            $rootScope.scheduleAppRestart();
            return response.modified;
          } else {
            console.error(gPrefix, 'Trail cannot be extended', response);
            return $q.reject(
              new btErrorService.ServerError('HTTP_ERROR', response.status, 'Server error', 'Trail cannot be extended.')
            );
          }
        });
    }

    /**
     *
     * @return {angular.IPromise<{subscription: btSubscriptionObject}>}
     */
    function makeUserRequest() {
      var query = {
        email: btShareScopeService.getUserEmail(),
        platform: btSettingsService.platform,
        provider: getProvider(),
      };

      return lbSubscription
        .v2User(query)
        .$promise.catch(btErrorService.handleHTTPError)
        .then(function (response) {
          if (response && response.subscription) {
            if (gDebug) console.log(gPrefix, 'Got user subscription ', response.subscription);
            saveCombinedSubscription(response.subscription);
            return { subscription: response.subscription };
          } else {
            console.error(gPrefix, "Couldn't get subscription", response);
            return $q.reject(
              new btErrorService.ServerError(
                'HTTP_ERROR',
                response.status,
                'Server error',
                "Can't load user subscription data from server."
              )
            );
          }
        });
    }

    /**
     * This function subscribes user to new pricing plan.
     *
     * @param {string} planId - plan identifier
     * @param {?string} token - payment token data
     * @param {?string} coupon - coupon identifier
     * @return {angular.IPromise<btSubscriptionObject>}
     */
    function makeSubscribeRequest(planId, token, coupon) {
      var query = {
        email: btShareScopeService.getUserEmail(),
        plan: planId,
        token: token,
        coupon: coupon,
        platform: btSettingsService.platform,
        provider: getProvider(),
      };

      return lbSubscription
        .v2Subscribe(query)
        .$promise.catch(btErrorService.handleHTTPError)
        .then(processSubscriptionAction)
        .then(function (result) {
          var name = getSubscriptionName(result).toLowerCase();
          btTagService.completePurchase(name);
          $analytics.eventTrack('complete', { category: 'purchases', label: name });
          btBranchService.userCompletedAction('payment', {}).catch(function (reason) {
            console.error(reason);
          });

          // set notification flags
          var accountInfo = btShareScopeService.getAccountSettings();

          // TODO Notify user about premium notifications
          accountInfo.notificationRules.insights = true;
          accountInfo.notificationRules.tradingInsights = true;
          accountInfo.notificationRules.expectedTradingInsights = true;

          btShareScopeService
            .updateProfileSettingsFromFieldsList(['notificationRules'])
            .then(function () {
              if (gDebug) console.log(gPrefix, 'Premium notifications turned on.');
            })
            .catch(function (reason) {
              console.error(gPrefix, reason);
            });

          $rootScope.scheduleAppRestart();
          return result;
        })
        .catch(function (reason) {
          console.error(gPrefix, "Couldn't get subscription", reason);
          return $q.reject(reason);
        });
    }

    /**
     * Unsubscribe
     *
     * @return {angular.IPromise<{url: string}>}
     */
    function makeManageBillingRequest() {
      var data = {
        email: btShareScopeService.getUserEmail(),
        platform: btSettingsService.platform,
        provider: getProvider(),
      };

      return lbSubscription.v2ManageBilling(data).$promise.catch(btErrorService.handleHTTPError).catch(printError);
    }

    /**
     * Unsubscribe
     *
     * @param {string} subscription
     * @return {angular.IPromise<btSubscriptionObject>}
     */
    function makeUnsubscribeRequest(subscription) {
      var data = {
        email: btShareScopeService.getUserEmail(),
        subscription: subscription,
        platform: btSettingsService.platform,
        provider: getProvider(),
      };

      var oldSubscription = JSON.parse(JSON.stringify($rootScope.currentUser.subscription));
      var name = getSubscriptionName($rootScope.currentUser.subscription).toLowerCase();

      if (gSkipCheckout) {
        return suggestReturnUpgrade(oldSubscription, oldSubscription);
      } else {
        return lbSubscription
          .v2Unsubscribe(data)
          .$promise.catch(btErrorService.handleHTTPError)
          .then(processSubscriptionAction)
          .then(function (result) {
            btTagService.cancelPurchase();
            $analytics.eventTrack('cancel', { category: 'purchases', label: name });
            return result;
          })
          .then(function (newSubscription) {
            return suggestReturnUpgrade(newSubscription, oldSubscription);
          })
          .then(function (data) {
            $rootScope.scheduleAppRestart();
            return data;
          })
          .catch(printError);
      }
    }

    /**
     * Upgrade subscription
     *
     * @param {string} subscription - identifier of current subscription
     * @param {string} planId - identifier of new plan
     * @param {string} couponId - coupon identifier
     * @return {angular.IPromise<btSubscriptionObject>}
     */
    function makeUpgradeRequest(subscription, planId, couponId) {
      var data = {
        email: btShareScopeService.getUserEmail(),
        plan: planId,
        coupon: couponId || '',
        subscription: subscription,
        platform: btSettingsService.platform,
        provider: getProvider(),
      };

      return lbSubscription
        .v2Upgrade(data)
        .$promise.catch(btErrorService.handleHTTPError)
        .then(processSubscriptionAction)
        .then(function (result) {
          var name = getSubscriptionName(result).toLowerCase();
          btTagService.completePurchase(name);
          $analytics.eventTrack('complete', { category: 'purchases', label: name });
          $rootScope.scheduleAppRestart();
          return result;
        })
        .catch(printError);
    }

    /**
     * Verify coupon
     *
     * @param {string} coupon - coupon identifier
     * @return {angular.IPromise<object>}
     */
    function makeCouponRequest(coupon) {
      var data = { coupon: coupon, provider: getProvider(), platform: btSettingsService.platform };
      return lbSubscription
        .v2Coupon(data)
        .$promise.catch(btErrorService.handleHTTPError)
        .then(function (response) {
          if (response && response.coupon) {
            $analytics.eventTrack('discount', { category: 'purchases', label: response.coupon.name.toLowerCase() });
            return response.coupon;
          } else {
            console.error(response);
            return $q.reject(new Error('Bad response.'));
          }
        });
    }

    /**
     *
     * @alias ecapp.btSubscriptionService#verifyCoupon
     * @param {string} couponId - identifier for coupon
     * @return {angular.IPromise<StripeCoupon>}
     */
    function verifyCoupon(couponId) {
      return makeCouponRequest(couponId).then(function (coupon) {
        if (coupon.valid) {
          setCouponId(coupon.id);
        }
        return /** @type {StripeCoupon} */ coupon;
      });
    }

    /**
     * This function loads stripe plans and prepares them for display in the application
     *
     * Note: this function use permanent memory caching
     *
     * @alias ecapp.btSubscriptionService#getPlans
     * @return {angular.IPromise<{result: btPlanObject[], result2: btPlanObject[]}>}
     */
    function getPlans() {
      if (gPlansResponse) {
        return $q.resolve(gPlansResponse);
      } else {
        var query = {
          platform: btSettingsService.platform,
          provider: getProvider(),
        };

        return lbSubscription
          .v2Purchases(query)
          .$promise.catch(btErrorService.handleHTTPError)
          .then(convertPurchasesToPlans)
          .then(preparePlansResponse);
      }

      /**
       *
       * @param {btUserPurchases} result -
       * @return {{result: btPlanObject[], result2: btPlanObject[]}}
       */
      function convertPurchasesToPlans(result) {
        var internal = result.subscriptions.filter(function (subscription) {
          return subscription.metadata.visible;
        });

        var external = result.subscriptions.filter(function (subscription) {
          return !subscription.metadata.visible;
        });

        return { result: internal, result2: external };
      }

      /**
       * This function prepares information about plans
       *
       * Property "result" contains internal plans (will be used in application).
       * Property "result2" contains other plans.
       *
       * @param {{result: btPlanObject[], result2: btPlanObject[]}} response - original response
       * @return {{result: btPlanObject[], result2: btPlanObject[]}} - modified response
       */
      function preparePlansResponse(response) {
        if (gDebug) if (gDebug) console.log(gPrefix, response);

        // In case of Optimus Futures application override in-app plans
        // if (btSettingsService.domain === 'optimusfutures') {
        //   response.result = response.result2.filter(function (plan) {
        //     return plan.metadata.domain === 'optimusfutures';
        //   });
        // }

        // Add price text
        // if (response.result) addPrice(response.result);
        // if (response.result2) addPrice(response.result2);

        gPlansResponse = response;
        return response;
      }
    }

    /**
     * This function loads stripe plans and prepares them for display in the application
     *
     * Note: this function use permanent memory caching
     *
     * @alias ecapp.btSubscriptionService#getPurchases
     * @return {angular.IPromise<btUserPurchases>}
     */
    function getPurchases() {
      if (gCachedPurchases) {
        return $q.resolve(gCachedPurchases);
      } else {
        var query = {
          platform: btSettingsService.platform,
          provider: getProvider(),
        };

        return lbSubscription
          .v2Purchases(query)
          .$promise.catch(btErrorService.handleHTTPError)
          .then(customizeForApple)
          .then(function (purchases) {
            gCachedPurchases = purchases;
            return purchases;
          });
      }
    }

    /**
     * This function customizes purchases for Apple device.
     *
     * @param {btUserPurchases} purchases - purchase available for user in application
     * @return {angular.IPromise<btUserPurchases>}
     */
    function customizeForApple(purchases) {
      if (gStatus.provider === Provider.APPLE) {
        var names = [].concat(purchases.consumables, purchases.plugins, purchases.subscriptions).map(function (value) {
          return value.id;
        });

        return btInAppPurchaseService
          .getInstance()
          .getProducts(names)
          .then(function (products) {
            if (gDebug) console.log(gPrefix, 'BetterTrader Products', JSON.stringify(purchases));
            if (gDebug) console.log(gPrefix, 'Apple Products', JSON.stringify(products));

            ['consumables', 'plugins', 'subscriptions'].forEach(function (type) {
              purchases[type] = purchases[type].filter(function (purchase) {
                var product = products.filter(function (item) {
                  return purchase.id === item.productId;
                })[0];

                if (product) {
                  extendApplePurchase(purchase, product);
                  return true;
                } else {
                  return false;
                }
              });
            });

            return purchases;
          })
          .catch(function (reason) {
            console.error(gPrefix, reason);
            return purchases;
          });
      } else {
        return $q.resolve(purchases);
      }
    }

    /**
     *
     * @alias ecapp.btSubscriptionService#getSpecificPurchase
     * @param {string} type - purchases type: subscriptions, consumables, plugins
     * @param {string} name - name
     * @param {number} interval - interval in days
     * @return {angular.IPromise<{btPlanObject}>}
     */
    function getSpecificPurchase(type, name, interval) {
      return getPurchases().then(function (purchases) {
        if (purchases[type]) {
          var results = purchases[type].filter(function (purchase) {
            return (purchase.name === name || purchase.alias === name) && purchase.interval_days === interval;
          });

          if (results.length > 1) {
            console.error(
              'getSpecificPurchase(' +
                type +
                ', ' +
                name +
                ', ' +
                interval +
                ') found ' +
                results.length +
                ' purchases.'
            );
            alert(results.length + ' similar purchases were found.');
          }

          return results[0];
        } else {
          return undefined;
        }
      });
    }

    /**
     * This function loads stripe plans and prepares them for display in the application
     *
     * Note: this function use permanent memory caching
     *
     * @return {angular.IPromise<btUserPurchases>}
     */
    function getUserPurchases() {
      if (gCachedUserPurchases) {
        return $q.resolve(gCachedUserPurchases);
      } else {
        var query = {
          email: btShareScopeService.getUserEmail(),
          platform: btSettingsService.platform,
          provider: getProvider(),
        };

        return lbSubscription
          .v2UserPurchases(query)
          .$promise.catch(btErrorService.handleHTTPError)
          .then(function (purchases) {
            gCachedUserPurchases = purchases;
            return purchases;
          });
      }
    }

    // ---- User Methods ----

    /**
     * Checks if user already have payment information.
     *
     * @return {boolean}
     */
    function checkHasPaymentInformation() {
      // FIXME It could be not accurate
      var hasPaidSubscription = false;

      // !!! >
      getUserSubscriptions().forEach(function (value) {
        hasPaidSubscription |= value.recurring;
      });

      getUserPlugins().forEach(function (value) {
        hasPaidSubscription |= value.recurring;
      });

      return hasPaidSubscription;
    }

    // function checkHasSubscription() {
    //
    // }

    // function checkHasPlugin(plugin) {
    //
    // }

    /**
     *
     * @return {btSubscriptionObject[]}
     */
    function getUserSubscriptions() {
      if (gCachedUserPurchases) {
        return gCachedUserPurchases.subscriptions;
      } else {
        console.error('gCachedUserPurchases is undefined.');
        return [];
      }
    }

    /**
     *
     * @return {btSubscriptionObject[]}
     */
    function getUserPlugins() {
      if (gCachedUserPurchases) {
        return gCachedUserPurchases.plugins;
      } else {
        console.error('gCachedUserPurchases is undefined.');
        return [];
      }
    }

    /**
     * This function checks whether user has paid subscription.
     * @name ecapp.btSubscriptionService#checkHasPaidSubscription
     * @return {boolean}
     */
    function checkHasPaidSubscription() {
      var s = getCombinedSubscription();
      if (s) {
        return ((s.status === 'active' || s.status === 'past_due') && !!s.amount) || s.id.indexOf('lifetime') !== -1;
      } else {
        return false;
      }

      // var hasPaidSubscription = false;
      // getUserSubscriptions().forEach(function (value) {
      //   if (value.status === 'active') hasPaidSubscription = true;
      // });
      //
      // return hasPaidSubscription;
    }

    /**
     *
     * @return {*}
     */
    function checkIsBrainLimited() {
      var subscription = getCombinedSubscription();
      return !!subscription && subscription.id === 'bigbrainbank-limited';
    }

    /**
     *
     * @alias ecapp.btSubscriptionService#checkIsSpecial
     * @return {boolean}
     */
    function checkIsSpecial() {
      var subscription = getCombinedSubscription();
      return !!subscription && subscription.id === 'testing';
    }

    /**
     *
     * @alias ecapp.btSubscriptionService#checkIsTrial
     * @return {boolean}
     */
    function checkIsTrial() {
      var subscription = getCombinedSubscription();
      return !!subscription && (subscription.status === 'trialing' || subscription.id === 'demo-trial');
    }

    /**
     *
     * @return {boolean}
     */
    function checkIsPro() {
      var subscription = getCombinedSubscription();
      return !!subscription && subscription.name === 'Pro';
    }

    /**
     *
     * @return {boolean}
     */
    function checkIsYearly() {
      var subscription = getCombinedSubscription();
      return !!subscription && subscription.interval === 'year';
    }

    /**
     * @return {number}
     */
    function getDaysSinceCreation() {
      var subscription = getCombinedSubscription();
      return !!subscription && subscription.created
        ? (Date.now() - subscription.created * 1000) / btDateService.DAY
        : 0;
    }

    /**
     *
     * @param {string} provider -
     * @return {boolean}
     */
    function checkIsProvider(provider) {
      var subscription = getCombinedSubscription();
      return !!subscription && subscription.origin === provider;
    }

    /**
     *
     * @alias ecapp.btSubscriptionService#checkIsBigBrainBankFunded
     * @return {boolean}
     */
    function checkIsBigBrainBankFunded() {
      var subscription = getCombinedSubscription();
      return !!subscription && subscription.bigbrainbank && subscription.bigbrainbank.funded;
    }

    /**
     *
     * @alias ecapp.btSubscriptionService#checkIsOptimusFunded
     * @return {boolean}
     */
    function checkIsOptimusFunded() {
      var subscription = getCombinedSubscription();
      return !!subscription && subscription.optimus && subscription.optimus.funded;
    }

    // ---- Subscription Methods ----

    /**
     * Is subscription canceled, expired or unpaid.
     * @param {*} subscription
     * @return {boolean}
     */
    function isBadSubscription(subscription) {
      var badStatus = ['past_due', 'cancelled', 'canceled', 'unpaid'];
      return badStatus.indexOf(subscription.status) !== -1;
    }

    /**
     * Subscription has expiration. It can be demo-trial or canceled subscription.
     * @param {btSubscriptionObject} subscription - subscription object
     * @return {boolean}
     */
    function hasExpiration(subscription) {
      if (subscription.id.indexOf('lifetime') !== -1) {
        return false;
      }

      if (subscription.plan === 'testing' && subscription.status === 'trialing') {
        return true;
      }

      // noinspection RedundantIfStatementJS
      if (subscription.recurring === false && new Date(subscription.due * 1000) > btDateService.getNowDate()) {
        return true;
      }

      return false;
    }

    /**
     * Get number of days till subscription expired.
     * Return -1 if subscription already expired.
     * @param {Date} due - subscription expiration
     * @return {number}
     */
    function getDaysLeft(due) {
      var now = btDateService.getNowDate();
      if (due > now) {
        return btDateService.daysBetween(due, now);
      } else {
        return -1;
      }
    }

    /**
     * Get subscription expiration
     * @param {object} subscription - subscription object
     * @param {object} user - user object
     * @return {Date|null}
     */
    function getExpiration(subscription, user) {
      var due = null;

      if (subscription.due) {
        due = new Date(subscription.due * 1000);
      } else {
        if (subscription.status === 'trialing') {
          if (subscription.trial) {
            due = new Date(subscription.trial * 1000);
          } else {
            due = new Date(btDateService.addMinutes(new Date(user.created), gDemoTrialPeriod * 24 * 60));
          }
        }
      }

      return due;
    }

    /**
     * Get number of days till subscription expired.
     * Return -1 if subscription already expired.
     *
     * @alias ecapp.btSubscriptionService#getDaysLeftObject
     * @param {object} subscription - subscription object
     * @param {object} user - user object
     * @return {object}
     */
    function getDaysLeftObject(subscription, user) {
      var data = {
        date: new Date(),
        text: 'N/A',
        style: 'bt-regular',
      };

      var due = getExpiration(subscription, user);

      if (due === null) return data;

      data.date = due;
      var days = getDaysLeft(due);
      if (days < 1) {
        data.text = 'few hours';
      } else if (days === 1) {
        data.text = '1 day';
      } else {
        data.text = days + ' days';
      }

      // prepare expiration style: highlight if left less than 7 days
      if (days < 7) {
        data.style = 'bt-highlighted';
      } else {
        data.style = 'bt-regular';
      }

      return data;
    }

    /**
     * Return true if upgrade is suggested for this subscription.
     * @param {object} subscription - subscription object
     * @return {boolean}
     */
    function isUpgradeSuggested(subscription) {
      return subscription.level < 3;
    }

    /**
     * This function returns subscription name.
     * Return 'Free' if subscription is bad.
     *
     * @alias ecapp.btSubscriptionService#getName
     * @param {btSubscriptionObject} subscription - subscription object
     * @return {string}
     */
    function getSubscriptionName(subscription) {
      if (subscription) {
        if (subscription.plan === 'testing' || !isBadSubscription(subscription)) {
          return subscription.name + ' ' + getPlanInterval(subscription, true);
        } else {
          if (subscription.status === 'past_due') {
            return 'Unpaid ' + subscription.name + ' ' + getPlanInterval(subscription, true);
          } else {
            return 'Free';
          }
        }
      } else {
        return 'Unknown';
      }
    }

    /**
     * This function checks if the subscription is available for the current user.
     * Plan is available if user doesn't have this or higher plan.
     *
     * @param {btSubscriptionObject} subscription
     * @return {boolean}
     */
    function checkCanUnsubscribe(subscription) {
      return !!subscription.origin && subscription.recurring;
    }

    // ---- Plan Methods ----

    /**
     *
     * @param {btPlanObject} plan - subscription plan object
     * @return {btSubscriptionObject}
     */
    function getPreviousSubscription(plan) {
      var subscriptions = [];

      if (gCachedUserPurchases) {
        if (checkIsSubscription(plan)) subscriptions = gCachedUserPurchases.subscriptions;
        if (checkIsPlugin(plan)) subscriptions = gCachedUserPurchases.plugins;
      } else {
        console.error('gCachedUserPurchases is undefined.');
      }

      return subscriptions.filter(function (subscription) {
        if (checkIsSubscription(plan)) {
          return subscription.origin === getProvider();
        }

        if (checkIsPlugin(plan)) {
          return subscription.origin === getProvider() && subscription.name === plan.name;
        }

        return false;
      })[0];
    }

    /**
     *
     * @param {btPlanObject} plan
     * @return {boolean}
     */
    function checkIsPlugin(plan) {
      return plan.metadata.category === Category.PLUGIN;
    }

    /**
     *
     * @param {btPlanObject} plan
     * @return {boolean}
     */
    function checkIsSubscription(plan) {
      return plan.metadata.category === Category.GENERAL;
    }

    // /**
    //  *
    //  * @param {btPlanObject} plan
    //  * @return {string}
    //  */
    // function getPlanType(plan) {
    //   return plan.metadata.category;
    // }

    /**
     * This function checks if the subscription is available for the current user.
     * Plan is available if user doesn't have this or higher plan.
     *
     * @param {btPlanObject} plan
     * @return {boolean}
     */
    function checkCanSubscribe(plan) {
      if (checkIsPlugin(plan)) return checkCanSubscribePlugin(plan);
      if (checkIsSubscription(plan)) return checkCanSubscribeSubscription(plan);
      return false;
    }

    /**
     * This function checks if the subscription is available for the current user.
     * Plan is available if user doesn't have this or higher plan.
     *
     * @param {btPlanObject} plan
     * @return {boolean}
     */
    function checkCanSubscribeSubscription(plan) {
      return (
        getUserSubscriptions().filter(function (subscription) {
          return (
            subscription.level > parseInt(plan.metadata.level) ||
            subscription.plan === plan.id ||
            (subscription.name === plan.name && subscription.interval_days > plan.interval_days)
          );
        }).length === 0
      );
    }

    /**
     * This function checks if the subscription is available for the current user.
     * Plan is available if user doesn't have this or higher plan.
     *
     * @param {btPlanObject} plan
     * @return {boolean}
     */
    function checkCanSubscribePlugin(plan) {
      return (
        getUserPlugins().filter(function (plugin) {
          return plugin.plan === plan.id || (plugin.name === plan.name && plugin.interval_days > plan.interval_days);
        }).length === 0
      );
    }

    // ---- Misc ----

    /**
     *
     * @param {string} price
     * @return {number}
     */
    function getDecimalPrice(price) {
      price = price.replace(/[^0-9.,]/g, '');
      var decimal = [];
      price
        .split('')
        .reverse()
        .forEach(function (value, i) {
          if (value === '.' || value === ',') {
            if (i < 3) {
              decimal.unshift('.');
            } else {
              // skip
            }
          } else {
            decimal.unshift(value);
          }
        });
      decimal = decimal.join('');

      return parseFloat(decimal);
    }

    /**
     * This function returns payment provider for given environment.
     *
     * @return {string}
     */
    function getProvider() {
      return gStatus.provider;
    }

    /**
     * This function returns plan interval text.
     *
     * @param {btSubscriptionObject|btPlanObject} plan - plan object
     * @param {boolean} capitalized - whether to capitalized text
     * @return {string} - plan interval as a text
     */
    function getPlanInterval(plan, capitalized) {
      var text = '';
      if (plan.interval_text) {
        text = plan.interval_text;
      } else {
        text = getIntervalText(plan.interval, plan.interval_count);
      }

      if (capitalized && text[0] && text[0] !== '(') {
        return text[0].toUpperCase() + text.slice(1);
      } else {
        return text;
      }
    }

    /**
     * This function returns interval as a text.
     *
     * @param {string} interval - interval unit: day, week, month or year
     * @param {number} count - number of units
     * @return {string} interval as a text
     */
    function getIntervalText(interval, count) {
      if (!(!!count && !!interval)) {
        return '';
      }

      switch (interval) {
        case 'day':
        case 'week':
        case 'month':
        case 'year':
          if (count === 1) {
            return IntervalText[interval].single;
          } else {
            return '(' + count + ' ' + IntervalText[interval].multiple + ')';
          }
        default:
          return '(' + count + ' ' + interval + ')';
      }
    }

    /**
     * @alias ecapp.btSubscriptionService#getPaymentSettings
     * @return {{annualDiscount: number, isAnnual: boolean}}
     */
    function getPaymentSettings() {
      return gPaymentSettings;
    }

    /**
     * @alias ecapp.btSubscriptionService#togglePaymentInterval
     */
    function togglePaymentInterval() {
      gPaymentSettings.interval = gPaymentSettings.isAnnual ? 'year' : 'month';
    }
  }
})();
