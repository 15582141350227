/* eslint-disable jsdoc/require-returns */
/* eslint-disable jsdoc/require-param */
/* eslint-disable jsdoc/tag-lines */

/**
 * @typedef {Object} ngResourceInstance
 * @property {angular.IPromise<*>} $promise - ?
 * @property {Boolean} $resolved - ?
 * @property {function} $cancelRequest - ?
 * @property {function} toJSON - ?
 */

// CommonJS package manager support
if (typeof module !== 'undefined' && typeof exports !== 'undefined' && module.exports === exports) {
  // Export the *name* of this Angular module
  // Sample usage:
  //
  //   import lbServices from './lb-services';
  //   angular.module('app', [lbServices]);
  //
  module.exports = 'lbServices';
}

// eslint-disable-next-line no-shadow-restricted-names
(function (window, angular, undefined) {
  'use strict';

  var urlBase = '/api';
  var authHeader = 'authorization';

  /**
   * Returns URL
   *
   * @param {string} url - url
   * @return {string|null}
   */
  function getHost(url) {
    // eslint-disable-next-line no-useless-escape
    var m = url.match(/^(?:https?:)?\/\/([^\/]+)/);
    return m ? m[1] : null;
  }

  // need to use the urlBase as the base to handle multiple
  // loopback servers behind a proxy/gateway where the host
  // would be the same.
  var urlBaseHost = getHost(urlBase) ? urlBase : location.host;

  /**
   * @ngdoc overview
   * @name lbServices
   * @module
   * @description
   * The `lbServices` module provides services for interacting with
   * the models exposed by the LoopBack server via the REST API.
   */
  var module = angular.module('lbServices', ['ngResource']);

  /**
   * @ngdoc object
   * @name lbServices.Rows
   * @header lbServices.Rows
   * @object
   * @description
   * A $resource object for interacting with the `Rows` model.
   * @example
   * See {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   */
  module.factory('Rows', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(
        urlBase + '/rows/:id',
        { id: '@id' },
        {
          /**
           * @ngdoc method
           * @name lbServices.Rows#find
           * @methodOf lbServices.Rows
           *
           * @description
           *
           * Find all instances of the model matched by filter from the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `Rows` object.)
           * </em>
           */
          /** - */
          find: {
            isArray: true,
            url: urlBase + '/rows',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Rows#getRowsOfEC
           * @methodOf lbServices.Rows
           *
           * @description
           *
           * Get rows from economical calendar
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           *  - `data` – `{Object=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `getRowsOfEC` – `{string=}` -
           */
          /** - */
          getRowsOfEC: {
            url: urlBase + '/rows/getRowsOfEC',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Rows#getInstrumentCalendar
           * @methodOf lbServices.Rows
           *
           * @description
           *
           * Get economical calendar for selected instrument
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `symbol` – `{String=}` -
           *
           *  - `from` – `{Number=}` -
           *
           *  - `till` – `{Number=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `calendar` – `{Array=}` -
           */
          /** - */
          getInstrumentCalendar: {
            url: urlBase + '/rows/instrument-calendar',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Rows#getInstrumentTradeIdeas
           * @methodOf lbServices.Rows
           *
           * @description
           *
           * Get trade ideas for selected instrument
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `symbol` – `{String=}` -
           *
           *  - `brokerSymbol` – `{String=}` -
           *
           *  - `from` – `{Number=}` -
           *
           *  - `till` – `{Number=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `ideas` – `{Array=}` -
           */
          /** - */
          getInstrumentTradeIdeas: {
            url: urlBase + '/rows/instrument-trade-ideas',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Rows#getInstrumentsTradeIdeas
           * @methodOf lbServices.Rows
           *
           * @description
           *
           * Get trade ideas for selected instruments
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `symbols` – `{String=}` -
           *
           *  - `brokerSymbols` – `{String=}` -
           *
           *  - `from` – `{Number=}` -
           *
           *  - `till` – `{Number=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `ideas` – `{Array=}` -
           */
          /** - */
          getInstrumentsTradeIdeas: {
            url: urlBase + '/rows/instruments-trade-ideas',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Rows#getTradeIdeasHistory
           * @methodOf lbServices.Rows
           *
           * @description
           *
           * This method return trade ideas for specific moments
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `type` – `{String=}` -
           *
           *  - `id` – `{String=}` -
           *
           *  - `symbols` – `{String=}` -
           *
           *  - `brokerSymbols` – `{String=}` -
           *
           *  - `dates` – `{Number=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `ideas` – `{Array=}` -
           */
          /** - */
          getTradeIdeasHistory: {
            url: urlBase + '/rows/trade-ideas-history',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Rows#getRow
           * @methodOf lbServices.Rows
           *
           * @description
           *
           * Get rows at specified time
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           *  - `data` – `{Object=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `getRow` – `{string=}` -
           */
          /** - */
          getRow: {
            url: urlBase + '/rows/getRow',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Rows#getNotificationData
           * @methodOf lbServices.Rows
           *
           * @description
           *
           * Get events that publish today
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           *  - `data` – `{Object=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `notificationData` – `{string=}` -
           */
          /** - */
          getNotificationData: {
            url: urlBase + '/rows/getNotificationData',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Rows#getInsightsAndRowsByStrength
           * @methodOf lbServices.Rows
           *
           * @description
           *
           * Get insights and rows by their strength
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           *  - `data` – `{Object=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `getInsightsAndRowsByStrength` – `{string=}` -
           */
          /** - */
          getInsightsAndRowsByStrength: {
            url: urlBase + '/rows/getInsightsAndRowsByStrength',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Rows#getGraphsOfRows
           * @methodOf lbServices.Rows
           *
           * @description
           *
           * Get charts' data using Snapshot API for set of selected releases
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           *  - `data` – `{Object=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `getGraphsOfRows` – `{string=}` -
           */
          /** - */
          getGraphsOfRows: {
            url: urlBase + '/rows/getGraphsOfRows',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Rows#search
           * @methodOf lbServices.Rows
           *
           * @description
           *
           * Search for releases with selected magnitude of specified event in some time frame
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `eventId` – `{Number=}` -
           *
           *  - `from` – `{Number=}` -
           *
           *  - `till` – `{Number=}` -
           *
           *  - `min` – `{Number=}` -
           *
           *  - `max` – `{Number=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `results` – `{*=}` -
           */
          /** - */
          search: {
            url: urlBase + '/rows/search',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Rows#getGraphsOfMoments
           * @methodOf lbServices.Rows
           *
           * @description
           *
           * Get charts' data using Snapshot API for set of moments
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           *  - `data` – `{Object=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `getGraphsOfMoments` – `{Object=}` -
           */
          /** - */
          getGraphsOfMoments: {
            url: urlBase + '/rows/getGraphsOfMoments',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Rows#backtestingV1
           * @methodOf lbServices.Rows
           *
           * @description
           *
           * Run backtesting for selected instrument at specified moments
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           *  - `type` – `{String=}` -
           *
           *  - `moments` – `{Number=}` -
           *
           *  - `instruments` – `{String=}` -
           *
           *  - `params` – `{Object=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `result` – `{Object=}` -
           */
          /** - */
          backtestingV1: {
            url: urlBase + '/rows/v1/backtesting',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Rows#getPrevRows
           * @methodOf lbServices.Rows
           *
           * @description
           *
           * Get rows published before the given time (in data)
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           *  - `data` – `{Object=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `getPrevRows` – `{string=}` -
           */
          /** - */
          getPrevRows: {
            url: urlBase + '/rows/getPrevRows',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Rows#tradeTomorrow
           * @methodOf lbServices.Rows
           *
           * @description
           *
           * Get tomorrow trade event
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `data` – `{Object=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `event` – `{string=}` -
           */
          /** - */
          tradeTomorrow: {
            url: urlBase + '/rows/trade-tomorrow',
            method: 'GET',
          },
        }
      );

      /**
       * @ngdoc property
       * @name lbServices.Rows#modelName
       * @propertyOf lbServices.Rows
       * @description
       * The name of the model represented by this $resource,
       * i.e. `Rows`.
       */
      R.modelName = 'Rows';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.Insights
   * @header lbServices.Insights
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `Insights` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('Insights', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(
        urlBase + '/insights/:id',
        { id: '@id' },
        {
          /**
           * @ngdoc method
           * @name lbServices.Insights#getInsightsOfRowId
           * @methodOf lbServices.Insights
           *
           * @description
           *
           * Get insights of a given row id
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           *  - `data` – `{Object=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `getInsightsOfRowId` – `{string=}` -
           */
          /** - */
          getInsightsOfRowId: {
            url: urlBase + '/insights/getInsightsOfRowId',
            method: 'POST',
          },
        }
      );

      /**
       * @ngdoc property
       * @name lbServices.Insights#modelName
       * @propertyOf lbServices.Insights
       * @description
       * The name of the model represented by this $resource,
       * i.e. `Insights`.
       */
      R.modelName = 'Insights';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.EventsInfo
   * @header lbServices.EventsInfo
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `EventsInfo` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('EventsInfo', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(
        urlBase + '/eventsInfos/:id',
        { id: '@id' },
        {
          /**
           * @ngdoc method
           * @name lbServices.EventsInfo#find
           * @methodOf lbServices.EventsInfo
           *
           * @description
           *
           * Find all instances of the model matched by filter from the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `EventsInfo` object.)
           * </em>
           */
          /** - */
          find: {
            isArray: true,
            url: urlBase + '/eventsInfos',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.EventsInfo#findOne
           * @methodOf lbServices.EventsInfo
           *
           * @description
           *
           * Find first instance of the model matched by filter from the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `EventsInfo` object.)
           * </em>
           */
          /** - */
          findOne: {
            url: urlBase + '/eventsInfos/findOne',
            method: 'GET',
          },
        }
      );

      /**
       * @ngdoc property
       * @name lbServices.EventsInfo#modelName
       * @propertyOf lbServices.EventsInfo
       * @description
       * The name of the model represented by this $resource,
       * i.e. `EventsInfo`.
       */
      R.modelName = 'EventsInfo';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.Email
   * @header lbServices.Email
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `Email` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('Email', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(urlBase + '/Emails/:id', { id: '@id' }, {});

      /**
       * @ngdoc property
       * @name lbServices.Email#modelName
       * @propertyOf lbServices.Email
       * @description
       * The name of the model represented by this $resource,
       * i.e. `Email`.
       */
      R.modelName = 'Email';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.Reviewer
   * @header lbServices.Reviewer
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `Reviewer` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('Reviewer', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      var R = LoopBackResource(
        urlBase + '/Reviewers/:id',
        { id: '@id' },
        {
          // INTERNAL. Use Reviewer.subscription() instead.
          prototype$__get__subscription: {
            url: urlBase + '/Reviewers/:id/subscription',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Reviewer#create
           * @methodOf lbServices.Reviewer
           *
           * @description
           *
           * Create a new instance of the model and persist it into the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           * This method expects a subset of model properties as request parameters.
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `Reviewer` object.)
           * </em>
           */
          /** - */
          create: {
            url: urlBase + '/Reviewers',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Reviewer#upsert
           * @methodOf lbServices.Reviewer
           *
           * @description
           *
           * Patch an existing model instance or insert a new one into the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           * This method expects a subset of model properties as request parameters.
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `Reviewer` object.)
           * </em>
           */
          /** - */
          upsert: {
            url: urlBase + '/Reviewers',
            method: 'PUT',
          },

          /**
           * @ngdoc method
           * @name lbServices.Reviewer#findById
           * @methodOf lbServices.Reviewer
           *
           * @description
           *
           * Find a model instance by {{id}} from the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `id` – `{*}` - Model id
           *
           *  - `filter` – `{object=}` - Filter defining fields and include - must be a JSON-encoded string ({"something":"value"})
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `Reviewer` object.)
           * </em>
           */
          /** - */
          findById: {
            url: urlBase + '/Reviewers/:id',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Reviewer#find
           * @methodOf lbServices.Reviewer
           *
           * @description
           *
           * Find all instances of the model matched by filter from the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `Reviewer` object.)
           * </em>
           */
          /** - */
          find: {
            isArray: true,
            url: urlBase + '/Reviewers',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Reviewer#prototype$updateAttributes
           * @methodOf lbServices.Reviewer
           *
           * @description
           *
           * Patch attributes for a model instance and persist it into the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `id` – `{*}` - Reviewer id
           *
           * @param {Object} postData Request data.
           *
           * This method expects a subset of model properties as request parameters.
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `Reviewer` object.)
           * </em>
           */
          /** - */
          prototype$updateAttributes: {
            url: urlBase + '/Reviewers/:id',
            method: 'PUT',
          },

          /**
           * @ngdoc method
           * @name lbServices.Reviewer#login
           * @methodOf lbServices.Reviewer
           *
           * @description
           *
           * Login a user with username/email and password.
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `include` – `{string=}` - Related objects to include in the response. See the description of return value for more details.
           *   Default value: `user`.
           *
           *  - `rememberMe` - `boolean` - Whether the authentication credentials
           *     should be remembered in localStorage across app/browser restarts.
           *     Default: `true`.
           *
           * @param {Object} postData Request data.
           *
           * This method expects a subset of model properties as request parameters.
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * The response body contains properties of the AccessToken created on login.
           * Depending on the value of `include` parameter, the body may contain additional properties:
           *   - `user` - `U+007BUserU+007D` - Data of the currently logged in user. (`include=user`)
           *
           */
          /** - */
          login: {
            params: {
              include: 'user',
            },
            interceptor: {
              response: function (response) {
                var accessToken = response.data;
                LoopBackAuth.setUser(accessToken.id, accessToken.userId, accessToken.user);
                LoopBackAuth.rememberMe = response.config.params.rememberMe !== false;
                LoopBackAuth.save();
                return response.resource;
              },
            },
            url: urlBase + '/Reviewers/login',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Reviewer#logout
           * @methodOf lbServices.Reviewer
           *
           * @description
           *
           * Logout a user with access token.
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           *  - `access_token` – `{string=}` - Do not supply this argument, it is automatically extracted from request headers.
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * This method returns no data.
           */
          /** - */
          logout: {
            interceptor: {
              response: function (response) {
                LoopBackAuth.clearUser();
                LoopBackAuth.clearStorage();
                return response.resource;
              },
              responseError: function (responseError) {
                LoopBackAuth.clearUser();
                LoopBackAuth.clearStorage();
                return responseError.resource;
              },
            },
            url: urlBase + '/Reviewers/logout',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Reviewer#confirm
           * @methodOf lbServices.Reviewer
           *
           * @description
           *
           * Confirm a user registration with email verification token.
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `uid` – `{string}` -
           *
           *  - `token` – `{string}` -
           *
           *  - `redirect` – `{string=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * This method returns no data.
           */
          /** - */
          confirm: {
            url: urlBase + '/Reviewers/confirm',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Reviewer#resetPassword
           * @methodOf lbServices.Reviewer
           *
           * @description
           *
           * Reset password for a user with email.
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           * This method expects a subset of model properties as request parameters.
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * This method returns no data.
           */
          /** - */
          resetPassword: {
            url: urlBase + '/Reviewers/reset',
            method: 'POST',
          },

          delete: {
            url: urlBase + '/Reviewers/delete',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Reviewer#getAccessData
           * @methodOf lbServices.Reviewer
           *
           * @description
           *
           * Get access data
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `result` – `{Object=}` -
           */
          /** - */
          getAccessData: {
            url: urlBase + '/Reviewers/access',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Reviewer#getChatLink
           * @methodOf lbServices.Reviewer
           *
           * @description
           *
           * Get chat link
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `result` – `{Object=}` -
           */
          /** - */
          getChatLink: {
            url: urlBase + '/Reviewers/chat-link',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Reviewer#deleteTester
           * @methodOf lbServices.Reviewer
           *
           * @description
           *
           * Remove test user from database
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `data` – `{Object=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `tester` – `{string=}` -
           */
          /** - */
          deleteTester: {
            url: urlBase + '/Reviewers/tester',
            method: 'DELETE',
          },

          /**
           * @ngdoc method
           * @name lbServices.Reviewer#prototype$getIntercomAttributes
           * @methodOf lbServices.Reviewer
           *
           * @description
           *
           * Get intercom attributes
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `id` – `{*}` - Reviewer id
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `attributes` – `{object=}` -
           */
          /** - */
          prototype$getIntercomAttributes: {
            url: urlBase + '/Reviewers/:id/intercom',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Reviewer#createMany
           * @methodOf lbServices.Reviewer
           *
           * @description
           *
           * Create a new instance of the model and persist it into the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           * This method expects a subset of model properties as request parameters.
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `Reviewer` object.)
           * </em>
           */
          /** - */
          createMany: {
            isArray: true,
            url: urlBase + '/Reviewers',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Reviewer#getCurrent
           * @methodOf lbServices.Reviewer
           *
           * @description
           *
           * Get data of the currently logged user. Fail with HTTP result 401
           * when there is no user logged in.
           *
           * @param {function(Object,Object)=} successCb
           *    Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *    `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           */
          /** - */
          getCurrent: {
            url: urlBase + '/Reviewers' + '/:id',
            method: 'GET',
            params: {
              id: function () {
                var id = LoopBackAuth.currentUserId;
                if (id == null) id = '__anonymous__';
                return id;
              },
            },
            interceptor: {
              response: function (response) {
                LoopBackAuth.currentUserData = response.data;
                return response.resource;
              },
              responseError: function (responseError) {
                LoopBackAuth.clearUser();
                LoopBackAuth.clearStorage();
                return $q.reject(responseError);
              },
            },
            __isGetCurrentUser__: true,
          },
        }
      );

      /**
       * @ngdoc method
       * @name lbServices.Reviewer#patchOrCreate
       * @methodOf lbServices.Reviewer
       *
       * @description
       *
       * Patch an existing model instance or insert a new one into the data source.
       *
       * @param {Object=} parameters Request parameters.
       *
       *   This method does not accept any parameters.
       *   Supply an empty object or omit this argument altogether.
       *
       * @param {Object} postData Request data.
       *
       * This method expects a subset of model properties as request parameters.
       *
       * @param {function(Object,Object)=} successCb
       *   Success callback with two arguments: `value`, `responseHeaders`.
       *
       * @param {function(Object)=} errorCb Error callback with one argument:
       *   `httpResponse`.
       *
       * @return {ngResourceInstance} An empty reference that will be
       *   populated with the actual data once the response is returned
       *   from the server.
       *
       * <em>
       * (The remote method definition does not provide any description.
       * This usually means the response is a `Reviewer` object.)
       * </em>
       */
      R['patchOrCreate'] = R['upsert'];

      /**
       * @ngdoc method
       * @name lbServices.Reviewer#updateOrCreate
       * @methodOf lbServices.Reviewer
       *
       * @description
       *
       * Patch an existing model instance or insert a new one into the data source.
       *
       * @param {Object=} parameters Request parameters.
       *
       *   This method does not accept any parameters.
       *   Supply an empty object or omit this argument altogether.
       *
       * @param {Object} postData Request data.
       *
       * This method expects a subset of model properties as request parameters.
       *
       * @param {function(Object,Object)=} successCb
       *   Success callback with two arguments: `value`, `responseHeaders`.
       *
       * @param {function(Object)=} errorCb Error callback with one argument:
       *   `httpResponse`.
       *
       * @return {ngResourceInstance} An empty reference that will be
       *   populated with the actual data once the response is returned
       *   from the server.
       *
       * <em>
       * (The remote method definition does not provide any description.
       * This usually means the response is a `Reviewer` object.)
       * </em>
       */
      R['updateOrCreate'] = R['upsert'];

      /**
       * @ngdoc method
       * @name lbServices.Reviewer#prototype$patchAttributes
       * @methodOf lbServices.Reviewer
       *
       * @description
       *
       * Patch attributes for a model instance and persist it into the data source.
       *
       * @param {Object=} parameters Request parameters.
       *
       *  - `id` – `{*}` - Reviewer id
       *
       * @param {Object} postData Request data.
       *
       * This method expects a subset of model properties as request parameters.
       *
       * @param {function(Object,Object)=} successCb
       *   Success callback with two arguments: `value`, `responseHeaders`.
       *
       * @param {function(Object)=} errorCb Error callback with one argument:
       *   `httpResponse`.
       *
       * @return {ngResourceInstance} An empty reference that will be
       *   populated with the actual data once the response is returned
       *   from the server.
       *
       * <em>
       * (The remote method definition does not provide any description.
       * This usually means the response is a `Reviewer` object.)
       * </em>
       */
      R['prototype$patchAttributes'] = R['prototype$updateAttributes'];

      /**
       * @ngdoc method
       * @name lbServices.Reviewer#getCachedCurrent
       * @methodOf lbServices.Reviewer
       *
       * @description
       *
       * Get data of the currently logged user that was returned by the last
       * call to {@link lbServices.Reviewer#login} or
       * {@link lbServices.Reviewer#getCurrent}. Return null when there
       * is no user logged in or the data of the current user were not fetched
       * yet.
       *
       * @return {Object} A Reviewer instance.
       */
      R.getCachedCurrent = function () {
        var data = LoopBackAuth.currentUserData;
        return data ? new R(data) : null;
      };

      /**
       * @ngdoc method
       * @name lbServices.Reviewer#isAuthenticated
       * @methodOf lbServices.Reviewer
       *
       * @return {boolean} True if the current user is authenticated (logged in).
       */
      R.isAuthenticated = function () {
        return this.getCurrentId() != null;
      };

      /**
       * @ngdoc method
       * @name lbServices.Reviewer#getCurrentId
       * @methodOf lbServices.Reviewer
       *
       * @return {Object} Id of the currently logged-in user or null.
       */
      R.getCurrentId = function () {
        return LoopBackAuth.currentUserId;
      };

      /**
       * @ngdoc property
       * @name lbServices.Reviewer#modelName
       * @propertyOf lbServices.Reviewer
       * @description
       * The name of the model represented by this $resource,
       * i.e. `Reviewer`.
       */
      R.modelName = 'Reviewer';

      /**
       * @ngdoc method
       * @name lbServices.Reviewer#subscription
       * @methodOf lbServices.Reviewer
       *
       * @description
       *
       * Fetches belongsTo relation subscription.
       *
       * @return {ngResourceInstance} An empty reference that will be
       *   populated with the actual data once the response is returned
       *   from the server.
       *
       * <em>
       * (The remote method definition does not provide any description.
       * This usually means the response is a `Whitelist` object.)
       * </em>
       */
      R.subscription = function () {
        var TargetResource = $injector.get('Whitelist');
        var action = TargetResource['::get::Reviewer::subscription'];
        return action.apply(R, arguments);
      };

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.Currencies
   * @header lbServices.Currencies
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `Currencies` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('Currencies', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(
        urlBase + '/currencies/:id',
        { id: '@id' },
        {
          /**
           * @ngdoc method
           * @name lbServices.Currencies#find
           * @methodOf lbServices.Currencies
           *
           * @description
           *
           * Find all instances of the model matched by filter from the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `Currencies` object.)
           * </em>
           */
          /** - */
          find: {
            isArray: true,
            url: urlBase + '/currencies',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Currencies#getFilteredCurrencies
           * @methodOf lbServices.Currencies
           *
           * @description
           *
           * Get currencies objects of a given currencies ids.
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           *  - `data` – `{Object=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `getFilteredCurrencies` – `{string=}` -
           */
          /** - */
          getFilteredCurrencies: {
            url: urlBase + '/currencies/getFilteredCurrencies',
            method: 'POST',
          },
        }
      );

      /**
       * @ngdoc property
       * @name lbServices.Currencies#modelName
       * @propertyOf lbServices.Currencies
       * @description
       * The name of the model represented by this $resource,
       * i.e. `Currencies`.
       */
      R.modelName = 'Currencies';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.Instruments
   * @header lbServices.Instruments
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `Instruments` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('Instruments', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(
        urlBase + '/instruments/:id',
        { id: '@id' },
        {
          /**
           * @ngdoc method
           * @name lbServices.Instruments#find
           * @methodOf lbServices.Instruments
           *
           * @description
           *
           * Find all instances of the model matched by filter from the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `Instruments` object.)
           * </em>
           */
          /** - */
          find: {
            isArray: true,
            url: urlBase + '/instruments',
            method: 'GET',
          },
        }
      );

      /**
       * @ngdoc property
       * @name lbServices.Instruments#modelName
       * @propertyOf lbServices.Instruments
       * @description
       * The name of the model represented by this $resource,
       * i.e. `Instruments`.
       */
      R.modelName = 'Instruments';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.Notifications
   * @header lbServices.Notifications
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `Notifications` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('Notifications', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(
        urlBase + '/notifications/:id',
        { id: '@id' },
        {
          /**
           * @ngdoc method
           * @name lbServices.Notifications#createNotifications
           * @methodOf lbServices.Notifications
           *
           * @description
           *
           * Save notification for chrome
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           *  - `data` – `{Object=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `createNotifications` – `{string=}` -
           */
          /** - */
          createNotifications: {
            url: urlBase + '/notifications/createNotifications',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Notifications#getLastNotificationInChannel
           * @methodOf lbServices.Notifications
           *
           * @description
           *
           * Get last notification
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           *  - `data` – `{Object=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `getLastNotificationInChannel` – `{string=}` -
           */
          /** - */
          getLastNotificationInChannel: {
            url: urlBase + '/notifications/getLastNotificationInChannel',
            method: 'POST',
          },
        }
      );

      /**
       * @ngdoc property
       * @name lbServices.Notifications#modelName
       * @propertyOf lbServices.Notifications
       * @description
       * The name of the model represented by this $resource,
       * i.e. `Notifications`.
       */
      R.modelName = 'Notifications';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.Whitelist
   * @header lbServices.Whitelist
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `Whitelist` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('Whitelist', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(
        urlBase + '/subscriptions/:id',
        { id: '@id' },
        {
          /**
           * @ngdoc method
           * @name lbServices.Whitelist#verifyCoupon
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Verify coupon for later use
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           *  - `type` – `{string=}` - Subscription origin
           *
           *  - `coupon` – `{string}` - Coupon id
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `result` – `{Object=}` -
           */
          /** - */
          verifyCoupon: {
            url: urlBase + '/subscriptions/coupon',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#plans
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Get list of all plans from stripe
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `type` – `{string=}` - Subscription origin
           *
           *  - `version` – `{number=}` - Version of subscription API
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `result` – `{Array=}` -
           *
           *  - `result2` – `{Array=}` -
           */
          /** - */
          plans: {
            url: urlBase + '/subscriptions',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#products
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Get list of all products from stripe
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `type` – `{string=}` - Subscription origin
           *
           *  - `kind` – `{string=}` - Type of product: service or good
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `internal` – `{Array=}` -
           *
           *  - `external` – `{Array=}` -
           */
          /** - */
          products: {
            url: urlBase + '/subscriptions/products',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#getUser
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Get user subscription information
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `email` – `{string}` - User email
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `result` – `{Object=}` -
           */
          /** - */
          getUser: {
            url: urlBase + '/subscriptions/user',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#fixUser
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Fix user subscription information
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `email` – `{string}` - User email
           *
           *  - `secret` – `{string}` - Secret
           *
           *  - `version` – `{number=}` - Version of subscription API
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `result` – `{Object=}` -
           */
          /** - */
          fixUser: {
            url: urlBase + '/subscriptions/user/fix',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#subscribe
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Create new stripe user and subscribe her to selected plan
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           *  - `email` – `{string}` - User email
           *
           *  - `token` – `{string}` - Payment token
           *
           *  - `plan` – `{string}` - Subscription plan
           *
           *  - `coupon` – `{string=}` - Coupon id
           *
           *  - `type` – `{string=}` - Subscription origin
           *
           *  - `version` – `{number=}` - Version of subscription API
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `result` – `{Object=}` -
           */
          /** - */
          subscribe: {
            url: urlBase + '/subscriptions/user',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#unsubscribe
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Unsubscribe user
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `email` – `{string}` - User email
           *
           *  - `version` – `{number=}` - Version of subscription API
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `result` – `{Object=}` -
           */
          /** - */
          unsubscribe: {
            url: urlBase + '/subscriptions/user',
            method: 'DELETE',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#upgradeSub
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Upgrade existing subscription
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           *  - `email` – `{string}` - User email
           *
           *  - `plan` – `{string}` - Subscription plan
           *
           *  - `version` – `{number=}` - Version of subscription API
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `result` – `{Object=}` -
           */
          /** - */
          upgradeSub: {
            url: urlBase + '/subscriptions/user',
            method: 'PUT',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#settings
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Get subscriptions settings
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `type` – `{string=}` - Working environment: test or live
           *
           *  - `version` – `{number=}` - Version of subscription API
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `result` – `{Object=}` -
           */
          /** - */
          settings: {
            url: urlBase + '/subscriptions/settings',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#v2Purchases
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Get list of application purchases
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `platform` – `{string}` - Platform settings
           *
           *  - `provider` – `{string}` - Purchases provider
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `subscriptions` – `{*=}` -
           *
           *  - `consumables` – `{*=}` -
           *
           *  - `plugins` – `{*=}` -
           */
          /** - */
          v2Purchases: {
            url: urlBase + '/subscriptions/v2/purchases',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#v2Offers
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Get information about user
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `email` – `{string}` - User email
           *
           *  - `platform` – `{string}` - Platform settings
           *
           *  - `provider` – `{string}` - Purchases provider
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `offers` – `{array=}` -
           */
          /** - */
          v2Offers: {
            url: urlBase + '/subscriptions/v2/offers',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#v2Modify
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Get information about user
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `email` – `{string}` - User email
           *
           *  - `platform` – `{string}` - Platform settings
           *
           *  - `provider` – `{string}` - Purchases provider
           *
           *  - `modification` – `{string}` - modification identifier
           *
           *  - `params` – `{string}` - modification parameters
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `subscription` – `{object=}` -
           */
          /** - */
          v2Modify: {
            url: urlBase + '/subscriptions/v2/modify',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#v2User
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Get information about user
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `email` – `{string}` - User email
           *
           *  - `platform` – `{string}` - Platform settings
           *
           *  - `provider` – `{string}` - Purchases provider
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `subscription` – `{object=}` -
           */
          /** - */
          v2User: {
            url: urlBase + '/subscriptions/v2/user',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#v2UserFixApple
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Get information about user
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `email` – `{string}` - User email
           *
           *  - `platform` – `{string}` - Platform settings
           *
           *  - `provider` – `{string}` - Purchases provider
           *
           *  - `receipt` – `{string}` - Purchases provider
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `subscription` – `{object=}` -
           */
          /** - */
          v2UserFixApple: {
            url: urlBase + '/subscriptions/v2/user/fix-apple',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#v2UserRestore
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Get information about user
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `email` – `{string}` - User email
           *
           *  - `platform` – `{string}` - Platform settings
           *
           *  - `provider` – `{string}` - Purchases provider
           *
           *  - `receipt` – `{string}` - Purchases provider
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `status` – `{object=}` -
           */
          /** - */
          v2UserRestore: {
            url: urlBase + '/subscriptions/v2/user/restore',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#v2UserPurchases
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Get information about user
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `email` – `{string}` - User email
           *
           *  - `platform` – `{string}` - Platform settings
           *
           *  - `provider` – `{string}` - Purchases provider
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `subscriptions` – `{*=}` -
           *
           *  - `consumables` – `{*=}` -
           *
           *  - `plugins` – `{*=}` -
           */
          /** - */
          v2UserPurchases: {
            url: urlBase + '/subscriptions/v2/user/purchases',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#v2Coupon
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Verify coupon for later use
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `coupon` – `{string}` - Coupon id
           *
           *  - `platform` – `{string}` - Platform settings
           *
           *  - `provider` – `{string}` - Purchases provider
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `coupon` – `{object=}` -
           */
          /** - */
          v2Coupon: {
            url: urlBase + '/subscriptions/v2/coupon',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#v2Subscribe
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Create new stripe user and subscribe her to selected plan
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           *  - `email` – `{string}` - User email
           *
           *  - `platform` – `{string}` - Platform settings
           *
           *  - `provider` – `{string}` - Subscription provider
           *
           *  - `plan` – `{string}` - Plan identifier
           *
           *  - `token` – `{string=}` - Payment token
           *
           *  - `coupon` – `{string=}` - Coupon identifier
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `subscription` – `{object=}` -
           */
          /** - */
          v2Subscribe: {
            url: urlBase + '/subscriptions/v2/user/subscription',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#v2Unsubscribe
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Unsubscribe user
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `email` – `{string}` - User email
           *
           *  - `platform` – `{string}` - Platform settings
           *
           *  - `provider` – `{string}` - Subscription provider
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `subscription` – `{object=}` -
           */
          /** - */
          v2ManageBilling: {
            url: urlBase + '/subscriptions/v2/user/billing',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#v2Unsubscribe
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Unsubscribe user
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `email` – `{string}` - User email
           *
           *  - `platform` – `{string}` - Platform settings
           *
           *  - `provider` – `{string}` - Subscription provider
           *
           *  - `subscription` – `{string}` - Subscription identifier
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `subscription` – `{object=}` -
           */
          /** - */
          v2Unsubscribe: {
            url: urlBase + '/subscriptions/v2/user/subscription',
            method: 'DELETE',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#v2Upgrade
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Upgrade existing subscription
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `email` – `{string}` - User email
           *
           *  - `platform` – `{string}` - Platform settings
           *
           *  - `provider` – `{string}` - Subscription provider
           *
           *  - `subscription` – `{string}` - Subscription identifier
           *
           *  - `plan` – `{string}` - Plan identifier
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `subscription` – `{object=}` -
           */
          /** - */
          v2Upgrade: {
            url: urlBase + '/subscriptions/v2/user/subscription',
            method: 'PATCH',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#v2Purchase
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Buy purchase
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           *  - `email` – `{string}` - User email
           *
           *  - `platform` – `{string}` - Platform settings
           *
           *  - `provider` – `{string}` - Subscription provider
           *
           *  - `purchase` – `{string}` - Purchase identifier
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `Whitelist` object.)
           * </em>
           */
          /** - */
          v2Purchase: {
            url: urlBase + '/subscriptions/v2/user/purchase',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#v2Consume
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Consume purchased product
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `email` – `{string}` - User email
           *
           *  - `platform` – `{string}` - Platform settings
           *
           *  - `provider` – `{string}` - Subscription provider
           *
           *  - `purchase` – `{string}` - Purchase identifier
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `Whitelist` object.)
           * </em>
           */
          /** - */
          v2Consume: {
            url: urlBase + '/subscriptions/v2/user/purchase',
            method: 'PATCH',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#v2ConsumeCredit
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Consume credits
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `email` – `{string}` - User email
           *
           *  - `platform` – `{string}` - Platform settings
           *
           *  - `amount` – `{number}` - Number of consumed credits
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `Whitelist` object.)
           * </em>
           */
          /** - */
          v2ConsumeCredit: {
            url: urlBase + '/subscriptions/v2/user/credits',
            method: 'PATCH',
          },

          /**
           * @ngdoc method
           * @name lbServices.Whitelist#v2Settings
           * @methodOf lbServices.Whitelist
           *
           * @description
           *
           * Get subscriptions settings
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `platform` – `{string}` - Platform settings
           *
           *  - `provider` – `{string}` - Purchases provider
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `Whitelist` object.)
           * </em>
           */
          /** - */
          v2Settings: {
            url: urlBase + '/subscriptions/v2/settings',
            method: 'GET',
          },

          // INTERNAL. Use Reviewer.subscription() instead.
          '::get::Reviewer::subscription': {
            url: urlBase + '/Reviewers/:id/subscription',
            method: 'GET',
          },
        }
      );

      /**
       * @ngdoc property
       * @name lbServices.Whitelist#modelName
       * @propertyOf lbServices.Whitelist
       * @description
       * The name of the model represented by this $resource,
       * i.e. `Whitelist`.
       */
      R.modelName = 'Whitelist';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.Settings
   * @header lbServices.Settings
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `Settings` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('Settings', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(
        urlBase + '/settings/:id',
        { id: '@id' },
        {
          /**
           * @ngdoc method
           * @name lbServices.Settings#find
           * @methodOf lbServices.Settings
           *
           * @description
           *
           * Find all instances of the model matched by filter from the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `Settings` object.)
           * </em>
           */
          /** - */
          find: {
            isArray: true,
            url: urlBase + '/settings',
            method: 'GET',
          },
        }
      );

      /**
       * @ngdoc property
       * @name lbServices.Settings#modelName
       * @propertyOf lbServices.Settings
       * @description
       * The name of the model represented by this $resource,
       * i.e. `Settings`.
       */
      R.modelName = 'Settings';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.MomentInsightTemplate
   * @header lbServices.MomentInsightTemplate
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `MomentInsightTemplate` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('MomentInsightTemplate', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(urlBase + '/moment-insights-templates/:id', { id: '@id' }, {});

      /**
       * @ngdoc property
       * @name lbServices.MomentInsightTemplate#modelName
       * @propertyOf lbServices.MomentInsightTemplate
       * @description
       * The name of the model represented by this $resource,
       * i.e. `MomentInsightTemplate`.
       */
      R.modelName = 'MomentInsightTemplate';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.Situation
   * @header lbServices.Situation
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `Situation` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('Situation', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(
        urlBase + '/situations/:id',
        { id: '@id' },
        {
          /**
           * @ngdoc method
           * @name lbServices.Situation#find
           * @methodOf lbServices.Situation
           *
           * @description
           *
           * Find all instances of the model matched by filter from the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `Situation` object.)
           * </em>
           */
          /** - */
          find: {
            isArray: true,
            url: urlBase + '/situations',
            method: 'GET',
          },
        }
      );

      /**
       * @ngdoc property
       * @name lbServices.Situation#modelName
       * @propertyOf lbServices.Situation
       * @description
       * The name of the model represented by this $resource,
       * i.e. `Situation`.
       */
      R.modelName = 'Situation';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.Moment
   * @header lbServices.Moment
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `Moment` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('Moment', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(
        urlBase + '/moments/:id',
        { id: '@id' },
        {
          /**
           * @ngdoc method
           * @name lbServices.Moment#findOne
           * @methodOf lbServices.Moment
           *
           * @description
           *
           * Find first instance of the model matched by filter from the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `Moment` object.)
           * </em>
           */
          /** - */
          findOne: {
            url: urlBase + '/moments/findOne',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Moment#getMomentsByDay
           * @methodOf lbServices.Moment
           *
           * @description
           *
           * Get moments of selected day
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `day` – `{date}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `data` – `{*=}` -
           *
           *  - `status` – `{string=}` -
           *
           *  - `version` – `{string=}` -
           */
          /** - */
          getMomentsByDay: {
            url: urlBase + '/moments/:day',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Moment#getMomentsTimeBack
           * @methodOf lbServices.Moment
           *
           * @description
           *
           * Get moments some time back
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `interval` – `{string}` -
           *
           *  - `number` – `{string}` -
           *
           *  - `offset` – `{string=}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `data` – `{*=}` -
           *
           *  - `status` – `{string=}` -
           *
           *  - `version` – `{string=}` -
           */
          /** - */
          getMomentsTimeBack: {
            url: urlBase + '/moments/:interval/:number',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.Moment#getHistory
           * @methodOf lbServices.Moment
           *
           * @description
           *
           * Get history of moment
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `id` – `{string}` -
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `data` – `{*=}` -
           *
           *  - `count` – `{number=}` -
           *
           *  - `status` – `{string=}` -
           *
           *  - `version` – `{string=}` -
           */
          /** - */
          getHistory: {
            url: urlBase + '/moments/:id/history',
            method: 'GET',
          },
        }
      );

      /**
       * @ngdoc property
       * @name lbServices.Moment#modelName
       * @propertyOf lbServices.Moment
       * @description
       * The name of the model represented by this $resource,
       * i.e. `Moment`.
       */
      R.modelName = 'Moment';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.MomentInsight
   * @header lbServices.MomentInsight
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `MomentInsight` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('MomentInsight', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(urlBase + '/moment-insights/:id', { id: '@id' }, {});

      /**
       * @ngdoc property
       * @name lbServices.MomentInsight#modelName
       * @propertyOf lbServices.MomentInsight
       * @description
       * The name of the model represented by this $resource,
       * i.e. `MomentInsight`.
       */
      R.modelName = 'MomentInsight';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.LogTradeOrder
   * @header lbServices.LogTradeOrder
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `LogTradeOrder` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('LogTradeOrder', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(
        urlBase + '/log-trading-orders/:id',
        { id: '@id' },
        {
          /**
           * @ngdoc method
           * @name lbServices.LogTradeOrder#create
           * @methodOf lbServices.LogTradeOrder
           *
           * @description
           *
           * Create a new instance of the model and persist it into the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           * This method expects a subset of model properties as request parameters.
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {ngResourceInstance} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `LogTradeOrder` object.)
           * </em>
           */
          /** - */
          create: {
            url: urlBase + '/log-trading-orders',
            method: 'POST',
          },

          /**
           * @ngdoc method
           * @name lbServices.LogTradeOrder#createMany
           * @methodOf lbServices.LogTradeOrder
           *
           * @description
           *
           * Create a new instance of the model and persist it into the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {Object} postData Request data.
           *
           * This method expects a subset of model properties as request parameters.
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `LogTradeOrder` object.)
           * </em>
           */
          /** - */
          createMany: {
            isArray: true,
            url: urlBase + '/log-trading-orders',
            method: 'POST',
          },
        }
      );

      /**
       * @ngdoc property
       * @name lbServices.LogTradeOrder#modelName
       * @propertyOf lbServices.LogTradeOrder
       * @description
       * The name of the model represented by this $resource,
       * i.e. `LogTradeOrder`.
       */
      R.modelName = 'LogTradeOrder';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.Level
   * @header lbServices.Level
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `Level` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('Level', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(
        urlBase + '/levels/:id',
        { id: '@id' },
        {
          /**
           * @ngdoc method
           * @name lbServices.Level#find
           * @methodOf lbServices.Level
           *
           * @description
           *
           * Find all instances of the model matched by filter from the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `Level` object.)
           * </em>
           */
          /** - */
          find: {
            isArray: true,
            url: urlBase + '/levels',
            method: 'GET',
          },
        }
      );

      /**
       * @ngdoc property
       * @name lbServices.Level#modelName
       * @propertyOf lbServices.Level
       * @description
       * The name of the model represented by this $resource,
       * i.e. `Level`.
       */
      R.modelName = 'Level';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.Risk
   * @header lbServices.Risk
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `Risk` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('Risk', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(
        urlBase + '/risks/:id',
        { id: '@id' },
        {
          /**
           * @ngdoc method
           * @name lbServices.Risk#find
           * @methodOf lbServices.Risk
           *
           * @description
           *
           * Find all instances of the model matched by filter from the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `Risk` object.)
           * </em>
           */
          /** - */
          find: {
            isArray: true,
            url: urlBase + '/risks',
            method: 'GET',
          },
        }
      );

      /**
       * @ngdoc property
       * @name lbServices.Risk#modelName
       * @propertyOf lbServices.Risk
       * @description
       * The name of the model represented by this $resource,
       * i.e. `Risk`.
       */
      R.modelName = 'Risk';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.RiskCache
   * @header lbServices.RiskCache
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `RiskCache` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('RiskCache', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(
        urlBase + '/risk-cache/:id',
        { id: '@id' },
        {
          /**
           * @ngdoc method
           * @name lbServices.RiskCache#find
           * @methodOf lbServices.RiskCache
           *
           * @description
           *
           * Find all instances of the model matched by filter from the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `RiskCache` object.)
           * </em>
           */
          /** - */
          find: {
            isArray: true,
            url: urlBase + '/risk-cache',
            method: 'GET',
          },
        }
      );

      /**
       * @ngdoc property
       * @name lbServices.RiskCache#modelName
       * @propertyOf lbServices.RiskCache
       * @description
       * The name of the model represented by this $resource,
       * i.e. `RiskCache`.
       */
      R.modelName = 'RiskCache';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.MarketCharacteristic
   * @header lbServices.MarketCharacteristic
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `MarketCharacteristic` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('MarketCharacteristic', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(
        urlBase + '/market-characteristic/:id',
        { id: '@id' },
        {
          /**
           * @ngdoc method
           * @name lbServices.MarketCharacteristic#find
           * @methodOf lbServices.MarketCharacteristic
           *
           * @description
           *
           * Find all instances of the model matched by filter from the data source.
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `filter` – `{object=}` - Filter defining fields, where, include, order, offset, and limit - must be a JSON-encoded string ({"something":"value"})
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `MarketCharacteristic` object.)
           * </em>
           */
          /** - */
          find: {
            isArray: true,
            url: urlBase + '/market-characteristics',
            method: 'GET',
          },
        }
      );

      /**
       * @ngdoc property
       * @name lbServices.MarketCharacteristic#modelName
       * @propertyOf lbServices.MarketCharacteristic
       * @description
       * The name of the model represented by this $resource,
       * i.e. `MarketCharacteristic`.
       */
      R.modelName = 'MarketCharacteristic';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.UDFSymbol
   * @header lbServices.UDFSymbol
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `UDFSymbol` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('UDFSymbol', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(
        urlBase + '/udf-symbols/:id',
        { id: '@id' },
        {
          /**
           * @ngdoc method
           * @name lbServices.UDFSymbol#config
           * @methodOf lbServices.UDFSymbol
           *
           * @description
           *
           * Data feed configuration data
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Object} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `UDFSymbol` object.)
           * </em>
           */
          config: {
            url: urlBase + '/udf-symbols/config',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.UDFSymbol#symbols
           * @methodOf lbServices.UDFSymbol
           *
           * @description
           *
           * Symbol resolve
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `symbol` – `{string}` - Symbol name or ticker
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Object} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `UDFSymbol` object.)
           * </em>
           */
          symbols: {
            url: urlBase + '/udf-symbols/symbols',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.UDFSymbol#search
           * @methodOf lbServices.UDFSymbol
           *
           * @description
           *
           * Symbol search
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `query` – `{string=}` - Text typed by the user in the search
           *
           *  - `type` – `{string=}` - One of the symbol types supported by your back-end
           *
           *  - `exchange` – `{string=}` - One of the exchanges supported by your back-end
           *
           *  - `limit` – `{number=}` - The maximum number of symbols in a response
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `UDFSymbol` object.)
           * </em>
           */
          search: {
            isArray: true,
            url: urlBase + '/udf-symbols/search',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.UDFSymbol#history
           * @methodOf lbServices.UDFSymbol
           *
           * @description
           *
           * Bars
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `symbol` – `{string}` - Symbol name or ticker
           *
           *  - `from` – `{number}` - Unix timestamp (UTC) of leftmost required bar
           *
           *  - `to` – `{number}` - Unix timestamp (UTC) of rightmost required bar
           *
           *  - `resolution` – `{string}` - Resolution
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Object} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `UDFSymbol` object.)
           * </em>
           */
          history: {
            url: urlBase + '/udf-symbols/history',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.UDFSymbol#time
           * @methodOf lbServices.UDFSymbol
           *
           * @description
           *
           * Server time
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Object} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `UDFSymbol` object.)
           * </em>
           */
          time: {
            url: urlBase + '/udf-symbols/time',
            method: 'GET',
          },
        }
      );

      /**
       * @ngdoc property
       * @name lbServices.UDFSymbol#modelName
       * @propertyOf lbServices.UDFSymbol
       * @description
       * The name of the model represented by this $resource,
       * i.e. `UDFSymbol`.
       */
      R.modelName = 'UDFSymbol';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.LdsApiWrapper
   * @header lbServices.LdsApiWrapper
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `LdsApiWrapper` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('LdsApiWrapper', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(
        urlBase + '/lds/:id',
        { id: '@id' },
        {
          /**
           * @ngdoc method
           * @name lbServices.LdsApiWrapper#getGrades
           * @methodOf lbServices.LdsApiWrapper
           *
           * @description
           *
           * All grades
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Object} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `LdsApiWrapper` object.)
           * </em>
           */
          getGrades: {
            isArray: true,
            url: urlBase + '/lds/grades',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.LdsApiWrapper#getSpotData
           * @methodOf lbServices.LdsApiWrapper
           *
           * @description
           *
           * Spot data
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `grades` – `{number}` - Grades
           *
           *  - `start` – `{string=}` - Start date (seconds)
           *
           *  - `end` – `{string=}` - End date (seconds)
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `LdsApiWrapper` object.)
           * </em>
           */
          getSpotData: {
            isArray: true,
            url: urlBase + '/lds/data/spot',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.LdsApiWrapper#getForwardData
           * @methodOf lbServices.LdsApiWrapper
           *
           * @description
           *
           * Forward data
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `grades` – `{number}` - Grades
           *
           *  - `start` – `{string=}` - Start date (seconds)
           *
           *  - `end` – `{string=}` - End date (seconds)
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `LdsApiWrapper` object.)
           * </em>
           */
          getForwardData: {
            isArray: true,
            url: urlBase + '/lds/data/forward',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.LdsApiWrapper#getPeriodData
           * @methodOf lbServices.LdsApiWrapper
           *
           * @description
           *
           * Period data
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `grades` – `{number}` - Grades
           *
           *  - `start` – `{string=}` - Start date (seconds)
           *
           *  - `end` – `{string=}` - End date (seconds)
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `LdsApiWrapper` object.)
           * </em>
           */
          getPeriodData: {
            isArray: true,
            url: urlBase + '/lds/data/period',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.LdsApiWrapper#getSpecificMonth
           * @methodOf lbServices.LdsApiWrapper
           *
           * @description
           *
           * Specific Month
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `grades` – `{number}` - Grades
           *
           *  - `month` – `{number}` - Grades
           *
           *  - `start` – `{string=}` - Start date (seconds)
           *
           *  - `end` – `{string=}` - End date (seconds)
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `LdsApiWrapper` object.)
           * </em>
           */
          getSpecificMonth: {
            isArray: true,
            url: urlBase + '/lds/data/specific',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.LdsApiWrapper#getTransactions
           * @methodOf lbServices.LdsApiWrapper
           *
           * @description
           *
           * Transactions
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `start` – `{string=}` - Start date (seconds)
           *
           *  - `end` – `{string=}` - End date (seconds)
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `LdsApiWrapper` object.)
           * </em>
           */
          getTransactions: {
            isArray: true,
            url: urlBase + '/lds/transactions',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.LdsApiWrapper#getVolumeWeightedAveragePrices
           * @methodOf lbServices.LdsApiWrapper
           *
           * @description
           *
           * Volume Weighted Average Prices
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `start` – `{string=}` - Start date (seconds)
           *
           *  - `end` – `{string=}` - End date (seconds)
           *
           * @param {function(Array.<Object>,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Array.<Object>} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * <em>
           * (The remote method definition does not provide any description.
           * This usually means the response is a `LdsApiWrapper` object.)
           * </em>
           */
          getVolumeWeightedAveragePrices: {
            isArray: true,
            url: urlBase + '/lds/vwap',
            method: 'GET',
          },
          getDailyPrint: {
            isArray: true,
            url: urlBase + '/lds/analysis/daily-print',
            method: 'GET',
          },
          getMonthToDate: {
            isArray: true,
            url: urlBase + '/lds/analysis/mtd',
            method: 'GET',
          },
          getDailyTable: {
            url: urlBase + '/lds/daily-table',
            method: 'GET',
          },
        }
      );

      /**
       * @ngdoc property
       * @name lbServices.LdsApiWrapper#modelName
       * @propertyOf lbServices.LdsApiWrapper
       * @description
       * The name of the model represented by this $resource,
       * i.e. `LdsApiWrapper`.
       */
      R.modelName = 'LdsApiWrapper';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.MarketData
   * @header lbServices.MarketData
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `MarketData` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('MarketData', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;

      var R = LoopBackResource(
        urlBase + '/market-data/:id',
        { id: '@id' },
        {
          /**
           * @ngdoc method
           * @name lbServices.MarketData#getProviders
           * @methodOf lbServices.MarketData
           *
           * @description
           *
           * List of providers
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Object} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `providers` – `{object=}` -
           */
          getProviders: {
            url: urlBase + '/market-data/providers',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.MarketData#getInstruments
           * @methodOf lbServices.MarketData
           *
           * @description
           *
           * List of instruments
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `provider` – `{string}` - Market data provider
           *
           *  - `text` – `{string=}` - Text
           *
           *  - `skip` – `{number=}` - Skip
           *
           *  - `limit` – `{number=}` - Limit
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Object} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `instruments` – `{object=}` -
           */
          getInstruments: {
            url: urlBase + '/market-data/providers/:provider/instruments',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.MarketData#getInstrumentDetails
           * @methodOf lbServices.MarketData
           *
           * @description
           *
           * Instrument details
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `provider` – `{string}` - Market data provider
           *
           *  - `symbol` – `{string=}` - Instrument symbol
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Object} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `instrument` – `{object=}` -
           */
          getInstrumentDetails: {
            url: urlBase + '/market-data/providers/:provider/instruments/:instrument',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.MarketData#getInstrumentCandles
           * @methodOf lbServices.MarketData
           *
           * @description
           *
           * Instrument candles
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `provider` – `{string}` - Market data provider
           *
           *  - `symbol` – `{string}` - Instrument symbol
           *
           *  - `granularity` – `{string}` - Granularity
           *
           *  - `start` – `{number=}` - Start date (seconds)
           *
           *  - `end` – `{number=}` - End date (seconds)
           *
           *  - `price` – `{string=}` - Price
           *
           *  - `count` – `{number=}` - Count
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Object} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `candles` – `{object=}` -
           */
          getInstrumentCandles: {
            url: urlBase + '/market-data/providers/:provider/instruments/:instrument/candles',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.MarketData#getLatestCandles
           * @methodOf lbServices.MarketData
           *
           * @description
           *
           * Latest candles
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `provider` – `{string}` - Market data provider
           *
           *  - `instruments` – `{string}` - Instruments
           *
           *  - `granularity` – `{string}` - Granularity
           *
           *  - `price` – `{string=}` - Price
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Object} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `instruments` – `{object=}` -
           */
          getLatestCandles: {
            url: urlBase + '/market-data/providers/:provider/latest-candles',
            method: 'GET',
          },

          /**
           * @ngdoc method
           * @name lbServices.MarketData#getQuotes
           * @methodOf lbServices.MarketData
           *
           * @description
           *
           * Quotes
           *
           * @param {Object=} parameters Request parameters.
           *
           *  - `provider` – `{string}` - Market data provider
           *
           *  - `instruments` – `{string}` - Instruments
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Object} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `quotes` – `{object=}` -
           */
          getQuotes: {
            url: urlBase + '/market-data/providers/:provider/quotes',
            method: 'GET',
          },
        }
      );

      /**
       * @ngdoc property
       * @name lbServices.MarketData#modelName
       * @propertyOf lbServices.MarketData
       * @description
       * The name of the model represented by this $resource,
       * i.e. `MarketData`.
       */
      R.modelName = 'MarketData';

      return R;
    },
  ]);

  /**
   * @ngdoc object
   * @name lbServices.Articles
   * @header lbServices.Articles
   * @object
   *
   * @description
   *
   * A $resource object for interacting with the `Articles` model.
   *
   * ## Example
   *
   * See
   * {@link http://docs.angularjs.org/api/ngResource.$resource#example $resource}
   * for an example of using this object.
   *
   */
  module.factory('Articles', [
    'LoopBackResource',
    'LoopBackAuth',
    '$injector',
    '$q',
    function (LoopBackResource, LoopBackAuth, $injector, $q) {
      void LoopBackAuth;
      void $injector;
      void $q;
      console.log('Initializing Articles model');
      var R = LoopBackResource(
        urlBase + '/news-crawler/:id',
        { id: '@id' },
        {
          /**
           * @ngdoc method
           * @name lbServices.Articles#getLatest
           * @methodOf lbServices.Articles
           *
           * @description
           *
           * Get list of latest articles
           *
           * @param {Object=} parameters Request parameters.
           *
           *   This method does not accept any parameters.
           *   Supply an empty object or omit this argument altogether.
           *
           * @param {function(Object,Object)=} successCb
           *   Success callback with two arguments: `value`, `responseHeaders`.
           *
           * @param {function(Object)=} errorCb Error callback with one argument:
           *   `httpResponse`.
           *
           * @return {Object} An empty reference that will be
           *   populated with the actual data once the response is returned
           *   from the server.
           *
           * Data properties:
           *
           *  - `providers` – `{object=}` -
           */
          getLatest: {
            url: urlBase + '/news-crawler/latest',
            method: 'GET',
          },
        }
      );

      /**
       * @ngdoc property
       * @name lbServices.Articles#modelName
       * @propertyOf lbServices.Articles
       * @description
       * The name of the model represented by this $resource,
       * i.e. `Articles`.
       */
      R.modelName = 'Articles';

      return R;
    },
  ]);

  module
    .factory('LoopBackAuth', function () {
      var props = ['accessTokenId', 'currentUserId', 'rememberMe'];
      var propsPrefix = '$LoopBack$';

      /**
       * @class
       */
      function LoopBackAuth() {
        var self = this;
        props.forEach(function (name) {
          self[name] = load(name);
        });
        this.currentUserData = null;
      }

      /**
       *
       */
      LoopBackAuth.prototype.save = function () {
        var self = this;
        var storage = this.rememberMe ? localStorage : sessionStorage;
        props.forEach(function (name) {
          save(storage, name, self[name]);
        });
      };

      /**
       *
       * @param {string} accessTokenId - access token
       * @param {string} userId - user aid
       * @param {string} userData - user data
       */
      LoopBackAuth.prototype.setUser = function (accessTokenId, userId, userData) {
        this.accessTokenId = accessTokenId;
        this.currentUserId = userId;
        this.currentUserData = userData;
      };

      /**
       *
       */
      LoopBackAuth.prototype.clearUser = function () {
        this.accessTokenId = null;
        this.currentUserId = null;
        this.currentUserData = null;
      };

      /**
       *
       */
      LoopBackAuth.prototype.clearStorage = function () {
        props.forEach(function (name) {
          save(sessionStorage, name, null);
          save(localStorage, name, null);
        });
      };

      return new LoopBackAuth();

      /**
       * Note: LocalStorage converts the value to string
       * We are using empty string as a marker for null/undefined values.
       *
       * @param {*} storage - storage
       * @param {string} name - name
       * @param {*} value - value
       */
      function save(storage, name, value) {
        try {
          var key = propsPrefix + name;
          if (value == null) value = '';
          storage[key] = value;
        } catch (err) {
          console.log('Cannot access local/session storage:', err);
        }
      }

      /**
       *
       * @param {string} name - name
       * @return {*} value
       */
      function load(name) {
        var key = propsPrefix + name;
        return localStorage[key] || sessionStorage[key] || null;
      }
    })
    .config([
      '$httpProvider',
      function ($httpProvider) {
        $httpProvider.interceptors.push('LoopBackAuthRequestInterceptor');
      },
    ])
    .factory('LoopBackAuthRequestInterceptor', [
      '$q',
      'LoopBackAuth',
      function ($q, LoopBackAuth) {
        return {
          request: function (config) {
            // filter out external requests
            var host = getHost(config.url);
            if (host && config.url.indexOf(urlBaseHost) === -1) {
              return config;
            }

            if (LoopBackAuth.accessTokenId) {
              config.headers[authHeader] = LoopBackAuth.accessTokenId;
            } else if (config.__isGetCurrentUser__) {
              // Return a stub 401 error for User.getCurrent() when
              // there is no user logged in
              var res = {
                body: { error: { status: 401 } },
                status: 401,
                config: config,
                headers: function () {
                  return undefined;
                },
              };
              return $q.reject(res);
            }
            return config || $q.when(config);
          },
        };
      },
    ])

    /**
     * @ngdoc object
     * @name lbServices.LoopBackResourceProvider
     * @header lbServices.LoopBackResourceProvider
     * @description
     * Use `LoopBackResourceProvider` to change the global configuration
     * settings used by all models. Note that the provider is available
     * to Configuration Blocks only, see
     * {@link https://docs.angularjs.org/guide/module#module-loading-dependencies Module Loading & Dependencies}
     * for more details.
     *
     * ## Example
     *
     * ```js
     * angular.module('app')
     *  .config(function(LoopBackResourceProvider) {
     *     LoopBackResourceProvider.setAuthHeader('X-Access-Token');
     *  });
     * ```
     */
    .provider('LoopBackResource', function LoopBackResourceProvider() {
      /**
       * @ngdoc method
       * @name lbServices.LoopBackResourceProvider#setAuthHeader
       * @methodOf lbServices.LoopBackResourceProvider
       * @param {string} header The header name to use, e.g. `X-Access-Token`
       * @description
       * Configure the REST transport to use a different header for sending
       * the authentication token. It is sent in the `Authorization` header
       * by default.
       */
      this.setAuthHeader = function (header) {
        authHeader = header;
      };

      /**
       * @ngdoc method
       * @name lbServices.LoopBackResourceProvider#getAuthHeader
       * @methodOf lbServices.LoopBackResourceProvider
       * @description
       * Get the header name that is used for sending the authentication token.
       */
      this.getAuthHeader = function () {
        return authHeader;
      };

      /**
       * @ngdoc method
       * @name lbServices.LoopBackResourceProvider#setUrlBase
       * @methodOf lbServices.LoopBackResourceProvider
       * @param {string} url The URL to use, e.g. `/api` or `//example.com/api`.
       * @description
       * Change the URL of the REST API server. By default, the URL provided
       * to the code generator (`lb-ng` or `grunt-loopback-sdk-angular`) is used.
       */
      this.setUrlBase = function (url) {
        urlBase = url;
        urlBaseHost = getHost(urlBase) || location.host;
      };

      /**
       * @ngdoc method
       * @name lbServices.LoopBackResourceProvider#getUrlBase
       * @methodOf lbServices.LoopBackResourceProvider
       * @description
       * Get the URL of the REST API server. The URL provided
       * to the code generator (`lb-ng` or `grunt-loopback-sdk-angular`) is used.
       */
      this.getUrlBase = function () {
        return urlBase;
      };

      this.$get = [
        '$resource',
        function ($resource) {
          var LoopBackResource = function (url, params, actions) {
            var resource = $resource(url, params, actions);

            // Angular always calls POST on $save()
            // This hack is based on
            // http://kirkbushell.me/angular-js-using-ng-resource-in-a-more-restful-manner/
            resource.prototype.$save = function (success, error) {
              // Fortunately, LoopBack provides a convenient `upsert` method
              // that exactly fits our needs.
              var result = resource.upsert.call(this, {}, this, success, error);
              return result.$promise || result;
            };
            return resource;
          };

          LoopBackResource.getUrlBase = function () {
            return urlBase;
          };

          LoopBackResource.getAuthHeader = function () {
            return authHeader;
          };

          return LoopBackResource;
        },
      ];
    });
})(window, window.angular);
