/**
 * Created by Sergey Panpurin on 6/7/2017.
 */

// @ts-check
(function btWatchListServiceClosure() {
  'use strict';

  var gDebug = false;
  var gPrefix = 'btWatchListService:';

  angular.module('ecapp').factory('btWatchListService', btWatchListService);

  btWatchListService.$inject = [
    '$q',
    '$rootScope',
    '$interval',
    '$timeout',
    '$ionicScrollDelegate',
    'btShareScopeService',
    'btInstrumentsService',
    'btTradingService',
    'btRestrictionService',
    'btDevService',
    'btSettingsService',
    'btEventEmitterService',
    'btLevelsService',
    'btOandaApiService',
    'btSymbolPrefix',
  ];

  /**
   * This service helps to interact with user watchlist.
   *
   * @ngdoc service
   * @name btWatchListService
   * @memberOf ecapp
   * @param {angular.IQService} $q - promise interface
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {angular.IIntervalService} $interval
   * @param {angular.ITimeoutService} $timeout
   * @param {ionic.IScrollDelegateService} $ionicScrollDelegate - ionic scroll delegate service
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IInstrumentsService} btInstrumentsService
   * @param {ecapp.ITradingService} btTradingService
   * @param {ecapp.IRestrictionService} btRestrictionService
   * @param {ecapp.IDevService} btDevService
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IEventEmitterService} btEventEmitterService
   * @param {ecapp.ILevelsService} btLevelsService
   * @param {ecapp.IOandaApiService} btOandaApiService
   * @param {ecapp.ISymbolPrefix} btSymbolPrefix
   * @return {ecapp.IWatchListService}
   */
  function btWatchListService(
    $q,
    $rootScope,
    $interval,
    $timeout,
    $ionicScrollDelegate,
    btShareScopeService,
    btInstrumentsService,
    btTradingService,
    btRestrictionService,
    btDevService,
    btSettingsService,
    btEventEmitterService,
    btLevelsService,
    btOandaApiService,
    btSymbolPrefix
  ) {
    if (gDebug) console.log('Running btWatchListService');

    // information about pages for menu
    /** @type {ecapp.IWatchlistPage[]} */
    var gPages;

    if (btSettingsService.isLinkDataService()) {
      gPages = [
        { selected: false, enable: true, name: 'Positions', icon: 'ion-ios-briefcase' },
        { selected: true, enable: true, name: 'WatchList', icon: 'ion-android-star' },
        // { selected: false, enable: true, name: 'Physical', icon: null },
        { selected: false, enable: true, name: 'USDomestic', icon: null },
        { selected: false, enable: true, name: 'Canadian', icon: null },
        { selected: false, enable: true, name: 'Rockies', icon: null },
        // {selected: false, enable: true, name: 'Financial', icon: null},
        // {selected: false, enable: true, name: 'Other', icon: null},
        { selected: false, enable: false, name: 'Popular', icon: null },
        { selected: false, enable: true, name: 'Forex', icon: null },
        { selected: false, enable: true, name: 'Commodities', icon: null },
        { selected: false, enable: true, name: 'Indices', icon: null },
        { selected: false, enable: true, name: 'Bonds', icon: null },
      ];
    } else {
      gPages = [
        { selected: false, enable: true, name: 'Positions', icon: 'ion-ios-briefcase' },
        { selected: true, enable: true, name: 'WatchList', icon: 'ion-android-star' },
        { selected: false, enable: false, name: 'Popular', icon: null },
        // { selected: false, enable: true, name: 'Stocks', icon: null },
        { selected: false, enable: true, name: 'Crypto', icon: null },
        { selected: false, enable: true, name: 'Forex', icon: null },
        { selected: false, enable: true, name: 'Commodities', icon: null },
        { selected: false, enable: true, name: 'Indices', icon: null },
        { selected: false, enable: true, name: 'Bonds', icon: null },
      ];
    }

    /** @type {string} */
    var gSelectedPage = 'WatchList';

    var gBrokerObject = btTradingService.getBrokerObject();
    var gDataFeedInterval = null;
    var gAutoRefreshing = false;
    var gRestrictions = { size: 0, capacity: 0 };

    /**
     * Object to save instruments data for different pages.
     * @type {ecapp.IPageInstruments}
     */
    var gInstrumentArray = {
      Current: [],
      Stocks: [],
      Crypto: [],
      Forex: [],
      Commodities: [],
      Indices: [],
      Bonds: [],
      Popular: [],
      Positions: [],
      USDomestic: [],
      Canadian: [],
      Rockies: [],
    };

    // gInstrumentArray.ForexInstruments = [];
    // gInstrumentArray.CommoditiesInstruments = [];
    // gInstrumentArray.IndicesInstruments = [];
    // gInstrumentArray.BondsInstruments = [];
    // gInstrumentArray.PopularInstruments = [];
    // gInstrumentArray.Positions = [];

    // gInstrumentArray.Physical = [];
    // gInstrumentArray.Financial = [];
    // gInstrumentArray.Other = [];

    var gWatchListInstruments = [];

    /**
     * Names of instruments to watch
     * @type {string[]}
     */
    var gWatchedInstruments = [];

    /**
     * List of watched instruments
     * @type {ecapp.ITradingInstrument[]}
     */
    var gUserWatchlist = null;

    /**
     * Positions
     * @type {ecapp.ITradingPosition[]}
     */
    var gPositions = [];

    /**
     * Names of instruments with positions
     * @type {string[]}
     */
    var gPositionNames = [];

    var gWatchListObject = {
      isLoading: {
        watchlist: true,
        positions: true,
      },
      pageInstruments: [],
    };

    /**
     * List of user account id (connected to selected broker)
     * @type {string[]}
     */
    var accountKeys = [];

    //TODO: remove default watchlist from here, just predefined pages

    /**
     * @enum {string}
     */
    var Category = {
      WatchList: 'WatchList',
      Stocks: 'Stocks',
      Crypto: 'Crypto',
      Forex: 'Forex',
      Indices: 'Indices',
      Bonds: 'Bonds',
      Commodities: 'Commodities',
      Popular: 'Popular',
      USDomestic: 'USDomestic',
      Canadian: 'Canadian',
      Rockies: 'Rockies',
    };

    /**
     * Default symbols in common format.
     * @type {ecapp.IPageSymbols}
     */
    var gDefaultSymbols = {
      WatchList: ['S&P500', 'EURUSD', 'USDCAD', 'GBPUSD', 'OIL', 'Gold'],
      Stocks: ['F', 'AMZN', 'AAPL', 'MSFT', 'DIS', 'TSLA', 'NVDA', 'PFE', 'JPM'],
      Crypto: [
        'BTCUSD',
        'ETHUSD',
        // 'USDTUSD',
        // 'XRPUSD',
        // 'USDCUSD',
        // 'LTCUSD',
        // 'UNIUSD',
        // 'LINKUSD',
        // 'MATICUSD',
        // 'ALGOUSD',
      ],
      Forex: ['EURUSD', 'GBPUSD', 'USDJPY', 'USDCHF', 'AUDUSD', 'USDCAD', 'USDCNH'],
      Indices: ['S&P500', 'Nasdaq 100', 'Dow Jones', 'Nikkei 225', 'DAX', 'Europe 50'],
      Bonds: ['US 2Y T-Note', 'US 5Y T-Note', 'US10Y', 'US T-Bond', 'EURO BUND', 'UK GILT'],
      Commodities: ['Gold', 'Silver', 'Copper', 'West Texas Oil', 'OIL', 'Natural Gas'],
      Popular: ['AAPL', 'AMZN', 'GOOG', 'NFLX', 'TSLA', 'FB', 'TWTR'],
      USDomestic: [
        /*26*/ 'CMA',
        /* 5*/ 'MEH',
        /* 3*/ 'MIDSWT',
        /* 4*/ 'WTS',
        /* 1*/ 'LLS',
        /*12*/ 'HLS',
        /* 2*/ 'MARS',
        /*13*/ 'LOOP SOUR',
        /*14*/ 'POSEIDON',
        /*15*/ 'BONITO',
        /*16*/ 'T-HORSE',
        /*17*/ 'SGC',
      ],
      Canadian: [
        /*24*/ 'WCS CUSHING',
        /*25*/ 'WCS USGC',
        /*45*/ 'CDB Cushing',
        /*31*/ 'AWB US GULF',
        /*32*/ 'CLK @ PATOKA',
      ],
      Rockies: [
        /* 43 */ 'SHL Light',
        /* 35 */ 'WHITECLIFFS',
        /* 34 */ 'NIO CUSHING',
        /* 36 */ 'GRAND MESA LT',
        /* 33 */ 'BLS CUSHING',
        /* 37 */ 'BAKKEN US GULF',
        /* 67 */ 'Bakken Patoka',
        /* 68 */ 'Lt Sweet GNSY',
        /* 69 */ 'UHC Clearbrook',
      ],
    };

    if (btSettingsService.isLinkDataService()) {
      gDefaultSymbols.Commodities = ['Gold', 'Silver', 'Copper', 'Natural Gas'];
      gDefaultSymbols.WatchList = ['S&P500', 'EURUSD', 'USDCAD', 'GBPUSD', 'Gold'];
    } else {
      gDefaultSymbols.USDomestic = [];
      gDefaultSymbols.Canadian = [];
      gDefaultSymbols.Rockies = [];
    }

    var gTradeStationCrypto = [
      // 'SHIBUSDC',
      'LTCUSD',
      // 'XTZUSDC',
      'ETHUSDC',
      'BTCUSD',
      // 'DOTUSDC',
      // 'MATICUSDC',
      // 'USDUSDC',
      // 'LTCBTC',
      // 'LINKUSDC',
      // 'AAVEUSDC',
      // 'XRPUSD',
      // 'ETHBTC',
      'ETHUSD',
      // 'MKRUSDC',
      'BCHUSD',
      'BTCUSDC',
      'USDCUSD',
      // 'COMPUSDC',
    ];

    // default symbols in broker format (will be defined after broker initialization)
    /** @type {ecapp.IPageSymbols} */
    var gDefaultBrokerSymbols = {
      WatchList: [],
      Stocks: gDefaultSymbols.Stocks,
      Crypto: gDefaultSymbols.Crypto,
      Forex: [],
      Indices: [],
      Bonds: [],
      Commodities: [],
      Popular: gDefaultSymbols.Popular,
      USDomestic: [],
      Canadian: [],
      Rockies: [],
    };

    // var defaultForexInstrumentsBroker = [];
    // var defaultIndicesInstrumentsBroker = [];
    // var defaultBondsInstrumentsBroker = [];
    // var defaultCommoditiesInstrumentsBroker = [];
    // var defaultWatchListInstrumentsBroker = [];
    // var defaultPopularInstrumentsBroker = defaultPopularInstruments;

    // var defaultPhysicalBroker = defaultPhysical;
    // var defaultFinancialBroker = defaultFinancial;
    // var defaultOtherBroker = defaultOther;

    var gLevelsChecking = false;

    var initPromise = initInstruments();

    $rootScope.$on('broker:connected', onBrokerConnected);
    $rootScope.$on('broker:disconnected', onBrokerDisconnected);
    $rootScope.$on('market:follow', onMarketFollow);
    $rootScope.$on('market:unfollow', onMarketUnfollow);

    btEventEmitterService.addListener('login:success', onLoginSuccess);
    btEventEmitterService.addListener('logout:success', onLogoutSuccess);

    return {
      initialize: initialize,
      getPages: getPages,
      addInstrumentByName: addInstrumentByName,
      switchPage: switchPage,
      getSelectedPageName: getSelectedPageName,
      refreshPage: refreshPage,
      pauseAutoRefreshing: pauseAutoRefreshing,
      resumeAutoRefreshing: resumeAutoRefreshing,
      stopAutoRefreshing: stopAutoRefreshing,
      getWatchListObject: getWatchListObject,
      getWatchedSymbols: getWatchedSymbols,
      getUserWatchlist: getUserWatchlist,
      getBrokerWatchList: getBrokerWatchList,
      filterWatchedSymbols: filterWatchedSymbols,
      getWatchlistRestrictions: getWatchlistRestrictions,
      getDefaultWatchListInstruments: getDefaultWatchListInstruments,
    };

    /**
     *
     */
    function onLoginSuccess() {
      try {
        //
      } catch (e) {
        console.error(e);
      }
    }

    /**
     *
     */
    function onLogoutSuccess() {
      try {
        clearUserWatchlist();
      } catch (e) {
        console.error(e);
      }
    }

    /**
     *
     */
    function onBrokerConnected() {
      btDevService.alert('btWatchlist: on broker:connected');

      if (btTradingService.getBrokerName() === 'tradier') {
        _turnOnTradierPages();
      } else {
        _turnOffTradierPages();
      }

      gWatchListInstruments = [];

      if (gUserWatchlist && gUserWatchlist.length === 0) {
        getWatchlistInstruments().forEach(function (value) {
          gUserWatchlist.push(value);
        });
      }

      if (gAutoRefreshing) {
        initPromise = initInstruments();
        btDevService.alert('btWatchlist: refresh page on broker:connected');
        initPromise.then(function () {
          switchPage(gSelectedPage);
          initPromise = null;
          gWatchListObject.isLoading.watchlist = false;
        });
      } else {
        initPromise = initInstruments();
        initPromise.then(function () {
          initPromise = null;
          gWatchListObject.isLoading.watchlist = false;
        });
      }
    }

    /**
     *
     */
    function onBrokerDisconnected() {
      // initPromise = initInstruments();
      // initPromise.then(function () {
      //   switchPage(gSelectedPage);
      //   initPromise = null;
      // })

      gWatchListObject.isLoading.watchlist = true;
      clearUserWatchlist();

      Object.keys(gInstrumentArray).forEach(function (key) {
        gInstrumentArray[key] = [];
      });
    }

    /**
     *
     * @param {*} event
     * @param {*} data
     */
    function onMarketFollow(event, data) {
      void event;

      getWatchlistRestrictions();
      if (gWatchedInstruments.indexOf(data.symbol) === -1) {
        gWatchedInstruments.push(data.symbol);
      }

      if (gSelectedPage === 'WatchList') {
        refreshWatchList();
      }

      if (gUserWatchlist) {
        /** @type {ecapp.ITradingInstrument} */
        var instrument = btTradingService.getInstrumentByBrokerSymbol(data.symbol);

        // console.log('>>> A', data.symbol, instrument);

        if (gUserWatchlist.indexOf(instrument) === -1) {
          btTradingService.enablePriceUpdates(instrument);
          gUserWatchlist.push(instrument);
        }
      }
    }

    /**
     *
     * @param {*} event
     * @param {*} data
     */
    function onMarketUnfollow(event, data) {
      void event;

      getWatchlistRestrictions();
      var i = gWatchedInstruments.indexOf(data.symbol);

      if (i !== -1) {
        gWatchedInstruments.splice(i, 1);
        gWatchListInstruments.splice(i, 1);
      }

      var j = -1;
      gWatchListInstruments.forEach(function (item, i) {
        if (item.instrument.brokerSymbol === data.symbol) j = i;
      });

      if (j !== -1) gWatchListInstruments.splice(j, 1);

      if (gUserWatchlist) {
        var instrument = btTradingService.getInstrumentByBrokerSymbol(data.symbol);
        var k = gUserWatchlist.indexOf(instrument);
        if (k !== -1) {
          gUserWatchlist.splice(k, 1);
          btTradingService.disablePriceUpdates(instrument);
        }
      }
    }

    /**
     *
     */
    function clearUserWatchlist() {
      if (gUserWatchlist) {
        var n = gUserWatchlist.length;
        for (var i = 0; i < n; i++) {
          var instrument = gUserWatchlist.pop();
          btTradingService.disablePriceUpdates(instrument);
        }
      }
    }

    /**
     *
     * @param {string} symbol
     * @param {ecapp.IMarketObject[]} list
     * @return {number}
     */
    function getInstrumentIndex(symbol, list) {
      var insIdx = -1;
      for (var i = 0; i < list.length; i++) {
        var insObj = list[i];
        if (insObj && insObj.instrument.brokerSymbol === symbol) {
          insIdx = i;
        }
      }
      return insIdx;
    }

    /**
     * Get all data for an instrument from the web service oanda
     * Store the data in scope array
     *
     * @param {string[]} symbols - instrument symbols
     * @param {ecapp.IMarketObject[]} arrayData - array of some data
     * @return {angular.IPromise<void>}
     */
    function pushInstruments(symbols, arrayData) {
      // var instruments =
      symbols.map(function (symbol) {
        var instrument = btTradingService.getInstrumentByBrokerSymbol(symbol);

        var index = getInstrumentIndex(symbol, arrayData);
        if (index === -1) {
          arrayData.push({
            instrument: instrument,
            priceData: {},
            // hasMarketData: false,
            // loadingMarketData: true,
            hasMarketData: true,
            loadingMarketData: false,
          });
        }

        // check position for this instrument
        if (gSelectedPage === 'Positions') {
          if (gPositionNames.indexOf(instrument.brokerSymbol) !== -1) {
            instrument.hasPositions = true;
            instrument.positionData = gPositions.filter(function (item) {
              return item.symbol === instrument.brokerSymbol;
            });
          }
        }

        return instrument;
      });

      if (btSettingsService.hasFeature('prices')) {
        return $q.resolve();
        // // get oanda data about instrument
        // return btTradingService.getLiveCandlesData(instruments.map(function (instrument) {
        //   return instrument.getSymbol();
        // }))
        //   .then(function (results) {

        //     results.forEach(function (data, i) {
        //       var instrument = instruments[i];
        //       var insIdx = getInstrumentIndex(symbols[i], arrayData);

        //       // save oanda data (if necessary push new object )
        //       if (insIdx !== -1) {
        //         console.log('>>>>', arrayData[insIdx]);

        //         arrayData[insIdx].instrument = instrument;
        //         // arrayData[insIdx].data = data;
        //         arrayData[insIdx].hasMarketData = (data !== undefined);
        //         arrayData[insIdx].loadingMarketData = false;
        //       } else {
        //         arrayData.push({
        //           instrument: instrument,
        //           // data: data,
        //           priceData: {},
        //           hasMarketData: (data !== undefined),
        //           loadingMarketData: false
        //         });
        //       }
        //     });
        //   })
        //   .catch(function (reason) {
        //     console.error(gPrefix, 'error', reason);
        //     // throw reason;
        //   });
      } else {
        return $q.resolve();
      }
    }

    /**
     * Convert BetterTrader's instrument names to broker's instrument names
     *
     * @param {string} key - key
     * @param {string[]} symbols - array of BetterTrader's instrument names
     * @return {string[]} - array of broker's instrument names
     */
    function convertSymbols(key, symbols) {
      var broker = btTradingService.getBrokerName();

      var newSymbols = [];
      symbols.forEach(function (symbol) {
        if (key === Category.USDomestic || key === Category.Canadian || key === Category.Rockies) {
          return newSymbols.push(symbol);
        }

        if (broker === 'tradestation' && (key === Category.Stocks || key === Category.Crypto)) {
          return newSymbols.push(symbol);
        }

        if (broker === 'default') {
          if (key === Category.Stocks) {
            symbol = btSymbolPrefix.STOCK + ':' + symbol;
            return newSymbols.push(symbol);
          }
          if (key === Category.Crypto) {
            symbol = btSymbolPrefix.CRYPT + ':' + symbol;
            return newSymbols.push(symbol);
          }
        }

        var newSymbol = btInstrumentsService.convertBrokerName(symbol, broker);
        if (newSymbol !== '') return newSymbols.push(newSymbol);
      });

      if (gDebug) console.log('>>>TEST', key, symbols, newSymbols);

      return newSymbols;
    }

    /**
     * Converts predefined BetterTrader's instruments names to broker's instrument names.
     * @param {string} broker - broker name
     */
    function convertDefaultSymbols(broker) {
      Object.keys(gDefaultSymbols).forEach(function (key) {
        var symbols = gDefaultSymbols[key];

        if (broker === 'tradestation' && key === 'Crypto') {
          symbols = gTradeStationCrypto;
        }

        gDefaultBrokerSymbols[key] = convertSymbols(key, symbols);
      });
    }

    /**
     * @name ecapp.btWatchListService#initialize
     * @return {angular.IPromise<void>}
     */
    function initialize() {
      return btShareScopeService
        .wait()
        .then(btInstrumentsService.init)
        .then(btTradingService.initialize)
        .then(btTradingService.connect)
        .then(checkLevels);
    }

    /**
     * This function checks levels price shift.
     * @deprecated
     */
    function checkLevels() {
      if (btLevelsService.getProviderById('deprecated') && !btLevelsService.isLevelsShifted() && !gLevelsChecking) {
        gLevelsChecking = true;
        btLevelsService
          .getAllLevelsMessages('deprecated')
          .then(calculatePriceShifts)
          .then(btLevelsService.shiftLevels)
          .catch(function (reason) {
            console.error(reason);
          })
          .finally(function () {
            gLevelsChecking = false;
          });
      }
    }

    /**
     * This function calculate levels price shift.
     *
     * @param {ecapp.IParsedMarketLevelsDocument[]} messages
     * @return {angular.IPromise<ecapp.IMarketShift[]>}
     */
    function calculatePriceShifts(messages) {
      if (messages.length) {
        var time = messages[0].time;
        return $q.all(
          messages.map(function (value) {
            return calculatePriceShift(value, time);
          })
        );
      } else {
        return $q.resolve([]);
      }

      /**
       * This function load market reference market price and calculate price shift.
       *
       * @param {ecapp.IParsedMarketLevelsDocument} message - message object
       * @param {number} time - reference time
       * @return {angular.IPromise<ecapp.IMarketShift>}
       */
      function calculatePriceShift(message, time) {
        return btOandaApiService.getEntryPrice(message.instrument.OandaSymbol, time - 60, 0).then(function (response) {
          if (response.candles) {
            var candle = response.candles[0];
            var candleTime = /** @type {any} */ (candle.time);
            var shift = btLevelsService.calculatePriceShift(message, candle.mid.c, parseInt(candleTime) + 60);
            return { message: message, shift: shift };
          } else {
            return { message: message, shift: null };
          }
        });
      }
    }

    /**
     * Get all instruments data
     * Used during page initialization
     * @return {angular.IPromise<void>}
     */
    function initInstruments() {
      // initialize BetterTrader instrument service
      return initialize()
        .then(preloadInstruments)
        .then(function () {
          if (gDebug) console.log(gPrefix, 'Data has been loaded');

          refreshData(gDefaultBrokerSymbols.Stocks, gInstrumentArray.Stocks);
          refreshData(gDefaultBrokerSymbols.Crypto, gInstrumentArray.Crypto);

          refreshData(gDefaultBrokerSymbols.Forex, gInstrumentArray.Forex);
          refreshData(gDefaultBrokerSymbols.Commodities, gInstrumentArray.Commodities);
          refreshData(gDefaultBrokerSymbols.Indices, gInstrumentArray.Indices);
          refreshData(gDefaultBrokerSymbols.Bonds, gInstrumentArray.Bonds);

          if (btTradingService.getBrokerName() === 'tradier') {
            _turnOnTradierPages();
            refreshData(gDefaultBrokerSymbols.Popular, gInstrumentArray.Popular);
          }

          if (btSettingsService.isLinkDataService()) {
            refreshData(gDefaultBrokerSymbols.USDomestic, gInstrumentArray.USDomestic);
            refreshData(gDefaultBrokerSymbols.Canadian, gInstrumentArray.Canadian);
            refreshData(gDefaultBrokerSymbols.Rockies, gInstrumentArray.Rockies);
          }
        })
        .catch(function (reason) {
          console.error(gPrefix, reason);
        });
    }

    /**
     * Preloads information about instruments.
     *
     * @return {angular.IPromise<void>}
     */
    function preloadInstruments() {
      convertDefaultSymbols(btTradingService.getBrokerName());
      var symbols = [].concat(gDefaultBrokerSymbols.Stocks, gDefaultBrokerSymbols.Crypto);

      var promises = symbols.map(function (symbol) {
        return btTradingService.getSymbolInfo(symbol).catch(function (error) {
          console.error(error);
        });
      });

      return $q
        .all(promises)
        .then(function () {
          return;
        })
        .catch(function (error) {
          console.log(gPrefix, "Can't preload instruments");
          console.error(gPrefix, error);
        });
    }

    /**
     *
     */
    function updateWatchedInstruments() {
      if (gDebug) console.log('Interval was called (updateWatchedInstruments)');

      if (gAutoRefreshing) {
        getWatchedInstruments();
        loadWatchListInstruments();
      }
    }

    /**
     * Get array of watched instruments and load data for this instruments
     * @return {string[]}
     */
    function getWatchedInstruments() {
      gWatchedInstruments = [];
      var brokerName = btTradingService.getBrokerName();

      // ??? this method takes too long to get the user's watchlist instruments because it's waiting to
      // the shared Object, need to make execute faster ???
      gWatchedInstruments = btShareScopeService.getWatchedInstruments(brokerName);

      // console.log("gWatchedInstruments", gWatchedInstruments);
      if (gWatchedInstruments === undefined) {
        // If the watchlist is not defined, reset it to its default value.
        // gWatchedInstruments = defaultWatchListInstrumentsBroker;
        gWatchedInstruments = gDefaultBrokerSymbols.WatchList;
        btShareScopeService.setWatchedInstruments(gWatchedInstruments, brokerName);
        btShareScopeService.updateProfileSettingsFromFieldsList(['watchList']).catch(console.error);
      }

      return gWatchedInstruments;
    }

    /**
     * Switches pages to Tradier view
     */
    function _turnOnTradierPages() {
      getPageByName('Popular').enable = true;
      getPageByName('Forex').enable = false;
      getPageByName('Bonds').enable = false;
    }

    /**
     * Switches pages back to regular view
     */
    function _turnOffTradierPages() {
      getPageByName('Popular').enable = false;
      getPageByName('Forex').enable = true;
      getPageByName('Bonds').enable = true;
    }

    /**
     * Adds new instrument to the watchlist.
     * Adds instrument to the user's watchList in db.
     * Refreshes displayed data with new instrument.
     *
     * @param {string} instrument - instrument name
     * @param {string} [broker] - broker name
     * @return {angular.IPromise<{id: string}>}
     */
    function addWatchedInstrument(instrument, broker) {
      if (!btShareScopeService.isInitialized()) {
        return $q.reject(new Error('Not initialized'));
      }

      if (broker === undefined) {
        broker = btTradingService.getBrokerName();
      }

      var instruments = btShareScopeService.getWatchedInstruments(broker);
      if (instruments === undefined) {
        return $q.reject(new Error("WatchList isn't created"));
      } else {
        if (instruments.indexOf(instrument) !== -1) {
          return $q.reject(new Error('Already in watchlist.'));
        }

        if (instruments.length >= btRestrictionService.getCapacity('watchlist')) {
          return btRestrictionService
            .showUpgradePopup('watchlist')
            .then(function (status) {
              if (status === 'updated') {
                return _addSymbol(instruments, instrument, broker);
              } else {
                return $q.reject(new Error('WatchList is full! Try to upgrade subscription plan.'));
              }
            })
            .catch(function (reason) {
              return $q.reject(reason);
            });
        } else {
          return $q.resolve(_addSymbol(instruments, instrument, broker));
        }
      }
    }

    /**
     * Adds instrument to watchlist.
     *
     * @param {string[]} watchlist - watchlist
     * @param {string} instrument - new instrument
     * @param {string} broker - broker name
     * @return {angular.IPromise<{id: string}>}
     * @private
     */
    function _addSymbol(watchlist, instrument, broker) {
      watchlist.push(instrument);

      btShareScopeService.setWatchedInstruments(watchlist, broker);
      return btShareScopeService.updateProfileSettingsFromFieldsList(['watchList']).then(function () {
        $rootScope.$broadcast('market:follow', { symbol: instrument });
        return $q.resolve({ id: instrument });
      });
    }

    /**
     * @name ecapp.btWatchListService#getWatchlistRestrictions
     * @return {{size: number, capacity: number}}
     */
    function getWatchlistRestrictions() {
      var instruments = btShareScopeService.getWatchedInstruments(btTradingService.getBrokerName());
      if (instruments) gRestrictions.size = instruments.length;
      gRestrictions.capacity = btRestrictionService.getCapacity('watchlist');
      return gRestrictions;
    }

    // /**
    //  * Remove selected instrument from the watchlist
    //  * Remove instrument from the user's watchList in db
    //  * Refresh displayed data
    //  *
    //  * @param {string} instrumentName - instrument name
    //  */
    // function deleteWatchedInstrument(instrumentName) {
    //   var indexOfWatchedInstruments = gWatchedInstruments.indexOf(instrumentName);
    //   gWatchedInstruments.splice(indexOfWatchedInstruments, 1);
    //   gWatchListInstruments.splice(indexOfWatchedInstruments, 1);
    //   btShareScopeService.setWatchedInstruments(gWatchedInstruments, btTradingService.getBrokerName());
    //   btShareScopeService.updateProfileSettingsFromFieldsList(['watchList']);
    // }

    /**
     * Load watched instruments
     * @return {*}
     */
    function loadWatchListInstruments() {
      gWatchListObject.pageInstruments = gWatchListInstruments;
      if (gDebug) console.log('>>> gWatchListObject', gWatchListObject);

      // var loopPromises = [];
      // gWatchedInstruments.forEach(function (instrumentName) {
      //   loopPromises.push(pushInstrument(instrumentName, gWatchListInstruments));
      // });

      return (
        pushInstruments(gWatchedInstruments, gWatchListInstruments)
          // return $q.all(loopPromises)
          .then(function () {
            gWatchListObject.isLoading.watchlist = false;
          })
      );
    }

    /**
     * Refresh watchList data
     * Used to refresh content after add or delete instrument in watchlist
     * @return {*}
     */
    function refreshWatchList() {
      getWatchedInstruments();
      return loadWatchListInstruments();
    }

    /**
     * Just calculate refreshing interval according to API limitations
     * @param {Array} instruments - array of instruments
     * @return {Number} - refreshing interval
     */
    function calcRefreshInterval(instruments) {
      if (btSettingsService.isLinkDataService()) {
        return 1 * 60 * 1000;
      }

      // number of request per minute
      var rateLimit = btTradingService.getLimit('getQuotes');
      if (instruments.length !== 0) {
        var updatesPerMinute = rateLimit / instruments.length;
        var delayTimeMs = Math.floor((60 / updatesPerMinute) * 1000);
        return Math.max(delayTimeMs, 5000);
      } else {
        return 5000;
      }
    }

    /**
     * Refresh instruments data
     * Used to refresh data displayed
     *
     * @param {string[]} values - list of instrument names
     * @param {ecapp.IMarketObject[]} scopeArray - array of instrument data
     * @return {*}
     */
    function refreshData(values, scopeArray) {
      // values.forEach(function (instrumentName) {
      //   // var instrument = btInstrumentsService.getInstrumentByField('OandaSymbol', instrumentName);
      //   pushInstrument(instrumentName, scopeArray);
      // });

      pushInstruments(values, scopeArray);

      // check position for this instrument
      if (gSelectedPage === 'Positions') {
        var bad = [];
        scopeArray.forEach(function (item, i) {
          if (gPositionNames.indexOf(item.instrument.brokerSymbol) === -1) {
            bad.push(i);
          }
        });

        if (bad.length > 0) {
          bad.forEach(function (item) {
            scopeArray.splice(item, 1);
          });
        }
      }

      return calcRefreshInterval(scopeArray);
    }

    /**
     *
     * @param {*} page
     * @return {*}
     */
    function update(page) {
      var deferred = $q.defer();

      var pageInfo = {
        // 'Stocks': { names: defaultStocksInstrumentsBroker, prop: 'ForexInstruments' },
        // 'Crypto': { names: defaultCryptoInstrumentsBroker, prop: 'Crypto' },
        // 'Forex': { names: defaultForexInstrumentsBroker, prop: 'Forex' },
        // 'Commodities': { names: defaultCommoditiesInstrumentsBroker, prop: 'CommoditiesInstruments' },
        // 'Indices': { names: defaultIndicesInstrumentsBroker, prop: 'IndicesInstruments' },
        // 'Bonds': { names: defaultBondsInstrumentsBroker, prop: 'BondsInstruments' },
        // 'Popular': { names: defaultPopularInstrumentsBroker, prop: 'PopularInstruments' },
        // 'Physical': { names: defaultPhysicalBroker, prop: 'Physical' },
        // 'Financial': { names: defaultFinancialBroker, prop: 'Financial' }

        Stocks: { names: gDefaultBrokerSymbols.Stocks, prop: 'Stocks' },
        Crypto: { names: gDefaultBrokerSymbols.Crypto, prop: 'Crypto' },
        Forex: { names: gDefaultBrokerSymbols.Forex, prop: 'Forex' },
        Commodities: {
          names: gDefaultBrokerSymbols.Commodities,
          prop: 'Commodities',
        },
        Indices: { names: gDefaultBrokerSymbols.Indices, prop: 'Indices' },
        Bonds: { names: gDefaultBrokerSymbols.Bonds, prop: 'Bonds' },
        Popular: { names: gDefaultBrokerSymbols.Popular, prop: 'Popular' },
        USDomestic: { names: gDefaultBrokerSymbols.USDomestic, prop: 'USDomestic' },
        Canadian: {
          names: gDefaultBrokerSymbols.Canadian,
          prop: 'Canadian',
        },
        Rockies: { names: gDefaultBrokerSymbols.Rockies, prop: 'Rockies' },
        // 'Other': {names: defaultOtherBroker, prop: 'Other'}
      };

      gInstrumentArray.Current = gInstrumentArray[pageInfo[page].prop];
      gWatchListObject.pageInstruments = gInstrumentArray.Current;

      var refreshInterval = refreshData(pageInfo[page].names, gInstrumentArray.Current);

      if (gDataFeedInterval === null) {
        startAutoRefreshing();
        gDataFeedInterval = $interval(refreshDataFunc, refreshInterval);
      }

      deferred.resolve();

      return deferred.promise;

      /**
       *
       */
      function refreshDataFunc() {
        if (gDebug) console.log('Interval was called (refreshDataFunc)');
        if (gAutoRefreshing) {
          refreshData(pageInfo[page].names, gInstrumentArray.Current);
        }
      }
    }

    /**
     * Tabs navigation between pages
     * Load data for the current tab only
     * Display loader if first visit
     * refreshInterval: interval time to refresh data of the current tab
     *
     * @name ecapp.btWatchListService#switchPage
     * @param {string} pageName - page name
     * @return {angular.IPromise<void>}
     */
    function switchPage(pageName) {
      if (initPromise !== null) {
        return initPromise.then(function () {
          return switchPage(pageName);
        });
      }

      selectPageByName(pageName);

      var refreshInterval = 10000;

      stopAutoRefreshing();
      $timeout(function () {
        // noinspection JSUnresolvedFunction, JSValidateTypes
        $ionicScrollDelegate.$getByHandle('handler').scrollTop();
      });

      switch (pageName) {
        case 'Positions':
          if (gBrokerObject.isTradeable) {
            accountKeys = [btTradingService.getSelectedAccountId()];

            return btTradingService
              .getPositions(accountKeys)
              .then(function (positions) {
                gPositionNames = [];
                gPositions = positions;

                gPositions.forEach(function (item) {
                  if (gPositionNames.indexOf(item.symbol) === -1) gPositionNames.push(item.symbol);
                });

                gInstrumentArray.Positions = [];
                gWatchListObject.pageInstruments = gInstrumentArray.Positions;
                refreshInterval = refreshData(gPositionNames, gInstrumentArray.Positions);

                if (gDataFeedInterval === null) {
                  startAutoRefreshing();
                  gDataFeedInterval = $interval(updateDataFeed, refreshInterval);
                }
              })
              .finally(function () {
                gWatchListObject.isLoading.positions = false;
              });
          }
          break;
        case 'Stocks':
        case 'Crypto':
        case 'Forex':
        case 'Commodities':
        case 'Indices':
        case 'Bonds':
        case 'Popular':
        case 'USDomestic':
        case 'Canadian':
        case 'Rockies':
          // case 'Physical':
          // case 'Financial' :
          // case 'Other' :
          return update(pageName);
        case 'WatchList':
        default:
          gWatchedInstruments = getWatchedInstruments();

          if (btSettingsService.hasFeature('prices')) {
            refreshInterval = calcRefreshInterval(gWatchedInstruments);

            console.log('refresh rate:', refreshInterval);

            if (gDataFeedInterval === null) {
              startAutoRefreshing();
              gDataFeedInterval = $interval(updateWatchedInstruments, refreshInterval);
            }

            return loadWatchListInstruments();
          } else {
            return loadWatchListInstruments();
          }
      }
    }

    /**
     *
     */
    function updateDataFeed() {
      if (gDebug) console.log('Interval was called (updateDataFeed)');
      if (gAutoRefreshing) {
        btTradingService.getPositions(accountKeys).then(function (positions) {
          gPositionNames = [];
          gPositions = positions;

          gPositions.forEach(function (item) {
            if (gPositionNames.indexOf(item.symbol) === -1) gPositionNames.push(item.symbol);
          });

          refreshData(gPositionNames, gInstrumentArray.Positions);
        });
      }
    }

    /**
     * @name ecapp.btWatchListService#getPages
     * @return {ecapp.IWatchlistPage[]}
     */
    function getPages() {
      if (!btSettingsService.hasFeature('trading')) {
        getPageByName('Positions').enable = false;
      }

      return gPages;
    }

    /**
     * @name ecapp.btWatchListService#addInstrumentByName
     * @param {string} instrument - instrument name
     * @param {string} [broker] - broker name
     * @return {angular.IPromise<{id: string}>}
     */
    function addInstrumentByName(instrument, broker) {
      return addWatchedInstrument(instrument, broker);
    }

    /**
     * @name ecapp.btWatchListService#refreshPage
     * @return {*}
     */
    function refreshPage() {
      return switchPage(gSelectedPage);
    }

    /**
     *
     * @param {string} pageName
     */
    function selectPageByName(pageName) {
      gSelectedPage = pageName;

      var curPage = getSelectedPage();
      curPage.selected = false;

      var nextPage = getPageByName(pageName);
      nextPage.selected = true;
    }

    /**
     *
     * @return {*}
     */
    function getSelectedPage() {
      var page = gPages.filter(function (t) {
        return t.selected;
      });

      if (page) {
        return page[0];
      } else {
        return null;
      }
    }

    /**
     * @name ecapp.btWatchListService#getSelectedPageName
     * @return {*}
     */
    function getSelectedPageName() {
      var page = getSelectedPage();

      if (page) {
        return page.name;
      } else {
        return 'WatchList';
      }
    }

    /**
     * Get page object by name
     * @param {string} pageName - page name
     * @return {Object} - page object
     */
    function getPageByName(pageName) {
      var pages = gPages.filter(function (t) {
        return t.name === pageName;
      });

      if (pages && pages.length === 1) {
        return pages[0];
      } else {
        return null;
      }
    }

    /**
     *
     */
    function startAutoRefreshing() {
      // gAutoRefreshing = true;
    }

    /**
     * @name ecapp.btWatchListService#resumeAutoRefreshing
     */
    function resumeAutoRefreshing() {
      // gAutoRefreshing = true;
    }

    /**
     * @alias ecapp.btWatchListService#pauseAutoRefreshing
     */
    function pauseAutoRefreshing() {
      gAutoRefreshing = false;
    }

    /**
     * @name ecapp.btWatchListService#stopAutoRefreshing
     */
    function stopAutoRefreshing() {
      gAutoRefreshing = false;
      if (gDataFeedInterval !== null) {
        $interval.cancel(gDataFeedInterval);
        gDataFeedInterval = null;
      }
    }

    /**
     * @name ecapp.btWatchListService#getWatchListObject
     * @return {{isLoading: {watchlist: boolean, positions: boolean}, pageInstruments: Array}}
     */
    function getWatchListObject() {
      return gWatchListObject;
    }

    /**
     * Get converted watchlist (bt names)
     *
     * @name ecapp.btWatchListService#getWatchedSymbols
     * @return {string[]}
     */
    function getWatchedSymbols() {
      var watchlist = btTradingService.getWatchedInstruments() || [];
      return watchlist
        .map(function (t) {
          return t.btName;
        })
        .filter(function (t) {
          return !!t;
        });
    }

    /**
     * This function returns instruments from user watchlist
     *
     * @alias ecapp.btWatchListService#getUserWatchlist
     * @return {ecapp.ITradingInstrument[]}
     */
    function getUserWatchlist() {
      if (gUserWatchlist === null) gUserWatchlist = getWatchlistInstruments();

      return gUserWatchlist;
    }

    /**
     *
     * @return {ecapp.ITradingInstrument[]}
     */
    function getWatchlistInstruments() {
      var instruments = btTradingService.getWatchedInstruments() || [];
      instruments.forEach(function (instrument) {
        btTradingService.enablePriceUpdates(instrument);
      });
      return instruments;
    }

    /**
     * Get broker watchlist
     *
     * @name ecapp.btWatchListService#getBrokerWatchList
     * @return {string[]} - list of watched instruments (broker names)
     */
    function getBrokerWatchList() {
      return btShareScopeService.getWatchedInstruments(btTradingService.getBrokerName()) || [];
    }

    /**
     * Return just symbols from watchlist
     *
     * @name ecapp.btWatchListService#filterWatchedSymbols
     * @param {string[]} symbols - bt names of symbols
     * @param {string[]} watchlist - bt names of watched symbols
     * @return {string[]}
     */
    function filterWatchedSymbols(symbols, watchlist) {
      return symbols.filter(function (symbol) {
        return watchlist.indexOf(symbol) !== -1 && symbol !== '';
      });
    }

    /**
     * This function get default watchlist instruments.
     *
     * @return {ecapp.ITradingInstrument[]}
     */
    function getDefaultWatchListInstruments() {
      var defaultInstruments = [];

      // defaultWatchListInstruments.forEach(function (instrument) {
      gDefaultSymbols.WatchList.forEach(function (instrument) {
        if (gDebug) console.log('WatchListService', instrument);
        // defaultInstruments.push(btInstrumentsService.getInstrumentByField('btName', instrument));
        defaultInstruments.push(btInstrumentsService.getInstrumentSmart(instrument));
      });
      return defaultInstruments;
    }
  }
})();
