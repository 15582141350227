/**
 * Created by Sergey on 09/11/24.
 */

(function btAIChatControllerCtrlClosure() {
  'use strict';

  angular.module('ecapp').controller('btAIChatController', btAIChatController);

  btAIChatController.$inject = [
    '$scope',
    '$sce',
    '$rootScope',
    'btSettingsService',
    'btShareScopeService',
    'btRestrictionService',
  ];

  /**
   * This is AI chat page controller.
   *
   * @ngdoc controller
   * @name btAIChatController
   * @memberOf ecapp
   * @param {ecapp.ICustomScope} $scope - common angular scope service
   * @param {angular.ISCEService} $sce
   * @param {ecapp.ICustomRootScope} $rootScope
   * @param {ecapp.ISettingsService} btSettingsService
   * @param {ecapp.IShareScopeService} btShareScopeService
   * @param {ecapp.IRestrictionService} btRestrictionService
   */
  function btAIChatController($scope, $sce, $rootScope, btSettingsService, btShareScopeService, btRestrictionService) {
    console.log('Running btAIChatController');

    $scope.isLoading = true;
    $scope.isLifetime = $rootScope.isLifetime;
    $scope.hasChat = $rootScope.isBTD;
    $scope.aiChatUrl = '';
    $scope.hasAiChatFeature = btRestrictionService.hasFeatureFunc('ai-chat');
    $scope.upgradeAiChatFeature = btRestrictionService.showUpgradePopupFunc('ai-chat');

    if (!$rootScope.isBTD) return;

    btShareScopeService.getChatLink().then(function (aiChatUrl) {
      $scope.isLoading = false;

      if (!aiChatUrl) {
        $scope.hasChat = false;
        $scope.aiChatUrl = '';
      } else {
        $scope.hasChat = true;
        $scope.aiChatUrl = $sce.trustAsResourceUrl(aiChatUrl);
      }
    });
  }
})();
