/**
 * Created by Sergey Panpurin on 11/16/2016.
 */

//@ts-check

/* eslint-disable no-useless-escape */
/* eslint complexity: ["error", 40] */
(function initClosure() {
  'use strict';

  // console.io settings
  // document.cookie = "serialNumber=123456789";

  // Fix for TradeStation
  if (typeof console.time !== 'function') {
    console.time = console.log;
  }

  if (typeof console.timeEnd !== 'function') {
    console.timeEnd = console.log;
  }

  // is run in application
  window.isApp = ionic.Platform.isWebView();

  // is mobile platform (application)
  window.isMobile = window.isApp;

  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // is run in browser mobile
  window.isMobileWeb = detectMobile(userAgent);

  var deviceInformation = ionic.Platform.device();

  var isWebView = ionic.Platform.isWebView();
  var isIPad = ionic.Platform.isIPad();
  var isIOS = ionic.Platform.isIOS() || (/Macintosh/i.test(userAgent) && window.isApp);
  var isAndroid = ionic.Platform.isAndroid();
  var isWindowsPhone = ionic.Platform.isWindowsPhone();

  var currentPlatform = ionic.Platform.platform();
  var currentPlatformVersion = ionic.Platform.version();

  window.btHasInAppBrowser = false;
  window.isAndroid = isAndroid;
  window.isIOS = isIOS;

  window.isMobileWeb = !isWebView && (isIOS || isAndroid || isWindowsPhone);

  window.btPlatform = !window.isMobile ? (window.isMobileWeb ? 'mobileWeb' : 'web') : window.isIOS ? 'ios' : 'android';

  console.log(
    'Device: ' +
      JSON.stringify(deviceInformation) +
      ';' +
      ' Platform: ' +
      currentPlatform +
      ' (' +
      currentPlatformVersion +
      ');' +
      ' isWebView: ' +
      isWebView +
      '; isIPad: ' +
      isIPad +
      '; isIOS: ' +
      isIOS +
      ';' +
      ' isAndroid: ' +
      isAndroid +
      '; isWindowsPhone: ' +
      isWindowsPhone +
      ';'
  );

  // is desktop platform
  window.isDesktop = !(window.isMobileWeb || window.isApp);

  window.isChromeLike = detectChromeLike();
  window.isChrome = detectChrome();

  window.isLarge = window.matchMedia('(min-width:760px)').matches;

  window.isSmallView = window.isMobile || !window.isLarge;
  window.isBigView = !window.isMobile && window.isLarge;

  // local version
  window.isDevelopment = window.location.hostname === 'localhost' && !window.isApp;

  // bt-client-server-test.herokuapp.com
  window.isTestStage =
    window.location.hostname === 'test.bettertrader.co' ||
    window.location.hostname === 'bt-client-server-test.herokuapp.com' ||
    window.location.hostname === 'bt-client-server-optimus-test.herokuapp.com' ||
    window.location.hostname === 'bt-client-bigbrainbank-test.herokuapp.com';

  // bt-client-server-test.herokuapp.com
  window.isFeatureStage = window.location.hostname === 'bt-client-server-features.herokuapp.com';

  window.isTesting = window.isTestStage || window.isFeatureStage || window.btSettings.BT_TESTING === 'true';

  var hosts = [
    'app.bettertrader.co',
    'news.optimusfutures.co',
    'thebrain.bigbrainbank.org',
    'bt-client-server-app.herokuapp.com',
    'bt-client-server-optimus.herokuapp.com',
    'bt-client-bigbrainbank.herokuapp.com',
  ];

  // eu.bettertrader.co or app.bettertrader.co or application
  window.isProduction =
    window.location.hostname === '' ||
    (hosts.indexOf(window.location.hostname) !== -1 && window.btSettings.BT_TESTING !== 'true');

  if (!window.isApp) {
    if (window.isDevelopment) {
      document.title = '[Develop] ' + document.title;
    }

    if (window.isTestStage) {
      document.title = '[Testing] ' + document.title;
    }

    if (window.isFeatureStage) {
      document.title = '[Features] ' + document.title;
    }
  }

  // Mobile app connected to test server
  window.isTestApp =
    !(
      window.btSettings.BT_LOOPBACK_URL === 'https://app.bettertrader.co/api' ||
      window.btSettings.BT_LOOPBACK_URL === 'https://thebrain.bigbrainbank.org/api'
    ) &&
    (window.location.hostname === '' || window.location.hostname === 'localhost');

  // developer mode can be activated on dev tab
  window.isDevMode = window.localStorage.getItem('isDevMode') === 'true';
  window.isDebugMode = window.localStorage.getItem('isDebugMode') === 'true';

  // trading ability can be activated on dev tab
  window.hasTradeAbility = (window.localStorage.getItem('hasTradeAbility') || 'true') === 'true';

  // disable to send analytics and to track error from localhost
  window.hasAnalytics = window.location.hostname !== 'localhost';
  window.hasErrorTracking = window.btSettings.BT_SENTRY_DNS && window.Sentry;

  // check for TradeStation mode
  window.isTradeStation = false;

  if (window.location.search.indexOf('tradestation=') !== -1) {
    var flag = window.location.search.split('tradestation=')[1];
    flag = flag.split('&')[0];
    flag = decodeURIComponent(flag);
    window.isTradeStation = flag === 'true';
  }

  window.btRemoveUnnecessaryWatchers = false;

  window.btStore = {};

  /**
   *
   * @param {string} a - user agent
   * @return {boolean}
   */
  function detectMobile(a) {
    /* cspell:disable-next-line */
    return (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    );
  }

  /**
   *
   * @return {boolean}
   */
  function detectChromeLike() {
    return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  }

  /**
   *
   * @return {boolean}
   */
  function detectChrome() {
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    // noinspection JSUnresolvedVariable
    var isOpera = typeof window.opr !== 'undefined';
    var isEdge = winNav.userAgent.indexOf('Edge') > -1;
    var isIOSChrome = winNav.userAgent.match('CriOS');

    if (isIOSChrome) {
      return true;
    } else
      return (
        isChromium !== null &&
        typeof isChromium !== 'undefined' &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isEdge === false
      );
  }

  // Debug Features
  if (window.location.hostname === 'localhost') {
    // Stop market data updates
    window.BT_STOP_MARKET_UPDATES = localStorage.getItem('BT_STOP_MARKET_UPDATES') == 'TRUE';
  }
})();

(function cleanStorage() {
  localStorage.removeItem('angular-cache.caches.btInstrumentsCache.data.InstrumentsCache');
  localStorage.removeItem('angular-cache.caches.btInstrumentsCache.data.InstrumentsList');
  localStorage.removeItem('angular-cache.caches.btInstrumentsCache.data.btInstrumentsCache');
  localStorage.removeItem('angular-cache.caches.btInstrumentsCache.keys');
  localStorage.removeItem('angular-cache.caches.currencies.data.Flags');
  localStorage.removeItem('angular-cache.caches.currencies.data.List');
  localStorage.removeItem('angular-cache.caches.currencies.keys');
  localStorage.removeItem('angular-cache.caches.currenciesCache.data.Flags');
  localStorage.removeItem('angular-cache.caches.currenciesCache.data.List');
})();
